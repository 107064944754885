import React, { Component } from 'react';

export default class CallTimeLabel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            day: 0,
            hour: 0,
            min: 0,
            sec: 0,
        }
        this.timer = null;
        this.duringTime = this.props.duringTime;
        this.count = this.count.bind(this);
    }

    componentDidMount() {
        this.count();
        this.timer = setInterval(this.count, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    count() {
        if(this.props.play === false){
            return;
        }
        var days = Math.floor(this.duringTime / 60 / 60 / 24);
        var hours = Math.floor((this.duringTime / 60 / 60) % 24);
        var minutes = Math.floor((this.duringTime / 60) % 60);
        var seconds = this.duringTime % 60;
        this.duringTime = this.duringTime + 1;

        this.setState({ days, hours, minutes, seconds });
    }

    render() {
        let style = {
            fontFamily: 'poppins',
            fontSize: `max(${this.props.fontSize}vw, 14px)`,
            margin: '0px'
        }

        const { hours, minutes, seconds } = this.state;
        return (
            <label style={style}>
                {/* {days === 0 || days === undefined ? "00" : days  }: */}
                {hours === 0 || hours === undefined ? "00" : ((hours<10)?'0'+hours:hours) }:
                {minutes === 0 || minutes === undefined ? "00" : ((minutes<10)?'0'+minutes:minutes) }:
                {(hours > 0) || seconds === 0 || seconds === undefined ? "00" : ((seconds<10)?'0'+seconds:seconds) }
            </label>
        );
    }
}