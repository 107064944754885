const constant = {
    LOGIN_FIRST: 0,
    LOGIN_IN_72: 1,
    LOGIN_CHECKED: 2,
    LOGOUT: 0,
    LOGIN: 1,
    SLEEP:1.5,
    AWAY: 2,
    JOIN: 3,
    INCOMING: 4,
    CALL: 5,
    HOLD: 6,
    LOCATION: 0,
    RECEPTION: 1,
    ZERO: 0,
    SLEEP_INTERVAL: 2000,
    FIVE_SECONDS: 3000,
    FIVE_MINUTES: 300000,
    TEN_SEC_INTERVAL: 10000,
    DUTY_START_TIME: 6,
    DUTY_END_TIME: 18,
    DUTY_CLOSED: 0,
    DEFAULT_VOLUME_VALUE: 100,
    MAX_VOLUME_VALUE: 100,
    CALL_CONNECT_DELAY: 2000,
    RAISE_CALL: 'raise_call',
    END_CALL: 'end_call',
    NONE_VALUE: "none",
    CALL_INCOMING_VALUE: "call_incoming",
    CALL_ACCEPT_VALUE: "call_accept",
    CALL_END_VALUE: "call_end",
    CALL_PAUSE_VALUE: "call_pause",
    CALL_PLAY_VALUE: "call_play",
}

module.exports = constant