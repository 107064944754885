import React from 'react';
import Modal from 'react-bootstrap/Modal';
import './modal-style.css';

export const AlertModal = ({ showModal, handleClose, title, content }) => {
    
    return (
        <Modal show={showModal} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{content}</p>
            </Modal.Body>
        </Modal>
    )
}