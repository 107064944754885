import { createSlice } from '@reduxjs/toolkit';

export const RemoteStates=createSlice({
    name:"remoteStates",
    initialState:{},
    reducers:{
        setRemoteStates:(state, action)=>{
            let {id,remoteStats}=action.payload
            if(!id) return state
            state[id]=remoteStats
            return state
        },
        clearRemotesID:(state,action)=>{
            var remotesId=action.payload
            var newStates={}
            for(let id of remotesId) {
                newStates[id]=state[id]
            }
            state=newStates
            return state
        }
    }
})

export const { setRemoteStates , clearRemotesID} = RemoteStates.actions

export default RemoteStates.reducer