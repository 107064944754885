import React, { useState, useEffect,useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import {Button, Checkbox, IconButton, Tooltip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import './modal-style.css';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FilerobotImageEditor, {
    TABS,
    TOOLS,
} from 'react-filerobot-image-editor';
import AdminService from '../../services/api'; 
import Storages from '../../constants/storages';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import FolderIcon from '@material-ui/icons/Folder';
import HomeIcon from '@material-ui/icons/Home';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { PDFDocument } from 'pdf-lib'
import  Download  from '@material-ui/icons/GetApp';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Confirm } from './ConfirmModal';
const API_URL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles(({palette,mode}) => ({
    root: {
      '& .modal-content':{
        backgroundColor:palette.background[mode] + " !important",
        color:palette.color[mode] + " !important"
      },
    },
    item: {
        width: '24%',
        height:"200px",
        margin:"0.5%",
        backgroundPosition:"center",
        borderRadius:"5px",
        display:"inline-grid",
        backgroundSize:"cover",
        '&:hover':{
          cursor: 'pointer',
          boxShadow: "inset 0px 0px 0px 2px #1C1D1E",
          // border: '2px #1C1D1E solid',
        },
        '&:hover $removeIcon':{
            display:"inline-flex", 
        },
        '&:hover $editIcon':{
            display:"inline-flex", 
        },
        '&:hover $downloadIcon':{
          display:"inline-flex", 
        },
    },
    selectedItem: {
      width: '80%',
      height:"150px",
      margin:"5px",
      backgroundPosition:"center",
      borderRadius:"5px",
      display:"inline-grid",
      backgroundSize:"cover",
      '&:hover':{
        cursor: 'pointer',
        boxShadow: "inset 0px 0px 0px 2px #1C1D1E",
        // border: '2px #1C1D1E solid',
      },
      '&:hover $removeIcon':{
          display:"inline-flex", 
      },
      '&:hover $editIcon':{
          display:"inline-flex", 
      },
      '&:hover $downloadIcon':{
        display:"inline-flex",
    },
    },
    removeIcon:{
      fontSize: "27px",
      padding: "7px 8px 7px 8px",
      display:"none",
      color:"red"
    },
    editIcon:{
      fontSize: "27px",
      padding: "7px 8px 7px 8px",
      display:"none",
      color:"blue",
    },
    downloadIcon:{
      fontSize: "27px",
      padding: "7px 8px 7px 8px",
      display:"none",
      color:"blue",
      fontWeight:"bolder"
    },
    actions:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"end",
    },
    number:{
        color:"white",
        borderRadius:"5px",
        backgroundColor:"#4C4D4D",
        padding:"0px 5px",
        paddingTop: "1px",
        margin: "7px"
    },
    batchesList:{
        width: '25%',
        borderRight: "1px solid #dee2e6;",
        overflowY: "auto",
        maxHeight: "85vh",
        color:palette.color[mode]
    },
    treeItem:{
        color:palette.color[mode],
        // padding:"5px 0px",
        '& .MuiTreeItem-label ':{
            color:palette.color[mode]
        }
    },
    home:{
      margin:"0px 0.5rem",
      fontSize:"30px",
      '&:hover':{
        cursor: 'pointer',
        boxShadow: "inset 0px 0px 0px 1px blue",
        borderRadius:"5px"

      },
      '&:active':{
        padding:'2px'
      }
    },
    roadMap:{
      marginLeft:"10px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    },
    textBtn:{
      cursor: 'none',
      color:"#3f51b5",
      margin:"0px 0.5rem",
      '&:hover':{
        cursor: 'pointer',
      },
      '&:active':{
        padding:'2px'
      },
      textTransform: 'capitalize',
    },
    header:{
      display:'flex',
      justifyContent:"space-between",
    }
}));
var token = sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
if(!token) token=localStorage.getItem(Storages.LOCAL_TOKEN_USER)
var dateTimeFormat = new Intl.DateTimeFormat('en-US', {
  year: "numeric", 
  month: "numeric", 
  day: "numeric",
  hour: "numeric", 
  minute: "numeric", 
  second: "numeric", 
  hour12: false
})
export const GalleryModal = ({showModal, handleClose, defaultBatch}) => {
    const classes = useStyles();
    const [content,setContent]=useState([])
    const [images,setImages]=useState([])
    const [selectedImages, setSelectedImages]=useState([])
    const [renderSelectedImages, setRenderSelectedImages]=useState([])
    const [selectedBatch, setSelectedBatch]=useState(null)
    const selecetedImageRef=useRef()
    selecetedImageRef.current=selectedImages
    const [editorImage,setEditorImage]=useState('')
    const [isImgEditorShown, setIsImgEditorShown] = useState(false);
    const [batchesList,setBatchesList]=useState({})
    const batchesListRef=useRef()
    batchesListRef.current=batchesList
    const [bigImage, setBigImage] = useState(null);
    const [currentlyAllSelected, setCurrentlyAllSelected] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const [roadMap,setRoadMap]=useState('')
    const [showBtn,setShowBtn]=useState(false)
    const [loadingPdf,setLoadingPdf]=useState(false)
    const confirmRef=useRef()
    useEffect(()=>{
        if(showModal) {
          getBatchesList()
          setBigImage(null)
          if(defaultBatch && defaultBatch == selectedBatch) 
            handleSelectedBatch(defaultBatch)
          else if( defaultBatch)handleSelectedBatch(defaultBatch)
          else if(!selectedBatch) onClickHome()
        }
        if(!token) {
          token= sessionStorage.getItem(Storages.LOCAL_TOKEN_USER) || localStorage.getItem(Storages.LOCAL_TOKEN_USER)
        }
    },[showModal])

    
    useEffect(()=>{
      if(defaultBatch && defaultBatch != selectedBatch) {
        getImagesOfBatch(defaultBatch)
        setSelectedBatch(defaultBatch)
      }
    },[defaultBatch])

    useEffect(() => {
      setSaveDisabled(selectedImages.length === 0)
      var list=[]
      if(!selectedImages || !selectedImages[0]) return setRenderSelectedImages([])
      selectedImages.forEach((img,index)=>{
        const url=`${API_URL}desktop/gallery/${img.filename}?token=${token}`
        if(!img.src)img.src=url
        list.push(
            <Grid className={classes.selectedItem} style={{backgroundImage:`url(${img.src})`}} 
              onClick={() => handleOnclickImg(img)}>
                <div style={{paddingTop: "5px"}}>
                 <b className={classes.number}>{index+1}</b>:
                </div>
                <div className={classes.actions}>
                  <Tooltip title="unselect image">
                    <Checkbox
                      style={{color: "blue", padding: "7px"}}
                      checked={true}
                      onChange={(ev) => {handleSelectedImage(ev,img)}}
                      onClick={(ev) => {ev.stopPropagation()}}
                    />                
                  </Tooltip> 
                  <Tooltip title="Edit image">                       
                    <IconButton className={classes.editIcon} 
                      onClick={(ev) => {ev.stopPropagation(); openImgEditor(img)}}> 
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
            </Grid>
        )
    })
    setRenderSelectedImages(list)
    }, [selectedImages])

    useEffect(() => {
        var list=[]
        var n=0
        for(let img of images){
            let selectedIndex=selectedImages.findIndex((el)=>el.id===img.id)
            if(selectedIndex>=0){
              selectedIndex++;
              n++;
            }
            const url=`${API_URL}desktop/gallery/${img.filename}?token=${token}`
            if(!img.src)img.src=url
            list.push(
                <Grid className={classes.item} style={{backgroundImage:`url(${img.src})`}} 
                  onClick={() => handleOnclickImg(img)}>
                    <div style={{paddingTop: "5px"}}>
                      {(selectedIndex>=0)?
                        <b className={classes.number}>{selectedIndex}</b>:
                        <b>&nbsp;</b>
                      }
                    </div>
                    <div className={classes.actions}>
                      <Tooltip title={(selectedIndex>=0)?"unselect image":"select image"}>
                        <Checkbox
                          style={{color: "blue", padding: "7px"}}
                          checked={(selectedIndex>=0)?true:false}
                          onChange={(ev) => {handleSelectedImage(ev,img)}}
                          onClick={(ev) => {ev.stopPropagation()}}
                        />
                      </Tooltip>
                      <Tooltip title='Download image'>
                        <IconButton  className={classes.downloadIcon} onClick={(ev)=>{ev.stopPropagation(); handleSaveImage(img)}} >
                          <Download/>
                        </IconButton>
                      </Tooltip>
                      {(selectedIndex>=0)?
                        <Tooltip title='Edit Image'>
                          <IconButton className={classes.editIcon} 
                            onClick={(ev) => {ev.stopPropagation(); openImgEditor(img)}}> 
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title='Delete image'>
                          <IconButton centerRipple={true} className={classes.removeIcon} 
                            onClick={(ev) => {ev.stopPropagation(); removeImages([img])}}> 
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      }

                    </div>
                </Grid>
            )
        }
        setShowBtn(true)
        setContent(list)
        setCurrentlyAllSelected(n === images.length)
    }, [selectedImages, images])
    

    const getBatchesList=async ()=>{
      try{
        setBigImage(null)
        let res = await AdminService.getBatchesList()
        if(res.data.code==0){
            const list=res.data.data
            var obj={}
            list.forEach(el => {
              const username = el.batchID.substring(0, el.batchID.lastIndexOf("-"))
              const date = el.batchID.substring(el.batchID.lastIndexOf("-")+1)
              if(!obj[username]) {
                obj[username]={dates: [], isPrimary: el.isPrimary}
              }
              obj[username].dates.push(date)
            });
            setBatchesList(obj)
        }
      } catch (err) {
        console.error("1501:  ",err)
        toast.error(err.message, {autoClose:10000})
      }
    }
    const onClickHome=async()=>{
      setShowBtn(false)
      await getBatchesList()
      contentListLocations()
    }

    const contentListLocations=()=>{
      var obj=batchesListRef.current
      var locations=Object.keys(obj)
      var locationsContent=[]

      for(let loc of locations){
        const isSec = !obj[loc].isPrimary;
        if(isSec && !showSecondaryLocations) continue;
        locationsContent.push(<Folder name={loc} handleOnclick={()=>onClickLocation(loc)} />)
      }
      setContent(locationsContent)
      setRoadMap(
        <div className={classes.roadMap}>
          <HomeIcon className={classes.home}  color="primary" onClick={onClickHome} /> 
        </div>
      )
    }


    const getImagesOfBatch = async(batchID)=>{
      try {
        let res=await AdminService.galleryList({batchID})
        if(res.data.code==0){
            const data=res.data.data
            setImages(data)
        }
      } catch (err) {
        console.error("1502   ",err)
        toast.error(err.message, {autoClose:10000})

      }
    }

    const handleSelectedBatch=(batchID)=>{
      if(!batchID) return
      setSelectedBatch(batchID)
      setBigImage(null)
        // if(selecetedImageRef.current[0]){
        //     if(window.confirm('This action will reset any changes you have made. Are you sure?')){
        //         setSelectedImages([])
        //         setIsImgEditorShown(false)
        //         setEditorImage(null)
        //         getImagesOfBatch(batchID)
        //     }
        // }
         
        getImagesOfBatch(batchID)
        const batches=batchesListRef.current
        const loc = batchID.split('-')[0]
        const date=new Date(Number(batchID.split('-')[1])).toLocaleString("en-ca", { hour12: false })

        setRoadMap(
          <div className={classes.roadMap}>
            <HomeIcon className={classes.home}  color="primary" onClick={onClickHome} />
            <ArrowForwardIosIcon fontSize='small' />
            <b className={classes.textBtn}  onClick={()=>onClickLocation(loc)}>{loc}</b>
            <ArrowForwardIosIcon fontSize='small' />
            <b className={classes.textBtn} onClick={()=>handleSelectedBatch(batchID)}>{date}</b>
          </div>
        )
    }   

    const handleSelectedImage=(event,img)=>{
        if(event.target.checked){
            setSelectedImages([...selectedImages,img])
        }
        else {
            let imgs=[...selectedImages]
            imgs=imgs.filter((x)=>x.id!=img.id)
            setSelectedImages(imgs)
        }
        
    }

    const removeImages = async (imgs) => {
      confirmRef.current.show('Delete',`Delete ${imgs.length} files permanently, Are you sure?`,
        [
          {
            close:true,
            text:"Yes",
            value:true,
            color:"secondary"

          },
          {
            close:true,
            text:"No",
            value:false,
            color:"secondary"

          }
        ]
      ).then(async (val)=>{
        console.log(val)
        if(!val) return
        try{
          const filenames = imgs.map(item => item.filename)
          let res = await AdminService.galleryRemove({filenames})
          if(res.data.code==0){
            let allImages = [...images]
            for(let img of imgs) {
              allImages = allImages.filter((x) => x.id != img.id)
            }
            setImages(allImages)

            let allSelected = [...selectedImages]
            for(let img of imgs) {
              allSelected = allSelected.filter((x) => x.id != img.id)
            }
            setSelectedImages(allSelected)

            const batchStillExists = allImages.find(item => item.batchID === imgs[0].batchID)
            if(!batchStillExists) {
              getBatchesList()
            }
          }
        } catch (err) {
          console.error("1503   ",err)
          toast.error(err.message, {autoClose:10000})
        }
      })
    }

    const onSaveFilerobotImageEditor=(editedImageObject, designState)=>{
        const base64=editedImageObject.imageBase64
        var all=[...images]
        var imgs=[...selectedImages]
        imgs=imgs.map((img)=>{
            if(img.id===editorImage.id){
                img.src=base64
            }
            return img
        })
        setSelectedImages(imgs)
        all=all.map((img)=>{
            if(img.id===editorImage.id){
                img.src=base64
            }
            return img
        })
        setImages(all)
        setIsImgEditorShown(false);
        setEditorImage(null)
    }
    const closeImgEditor = () => {
        setIsImgEditorShown(false);
        setEditorImage(null)
    };
    const openImgEditor = (img) => {
        setEditorImage(img)
        setIsImgEditorShown(true);
    };

    const handleSaveFiles=async()=>{
      try {
        setLoadingPdf(true)
        setSaveDisabled(true)
        const files=[]
        for(let f of selectedImages){
          var data=f.src
          if(data.startsWith(API_URL)){

            const res = await fetch(data).then((res) => res.arrayBuffer())
            files.push(res)
          } else {
            files.push(data)
          }
        }
        const pdfDoc = await PDFDocument.create()
        for(var index in files){
          try {
            var type=getMimeTypeFromArrayBuffer(files[index])
            var image;

            if(type==='image/jpeg') image= await pdfDoc.embedJpg(files[index])
            else if(type==='image/png') image= await pdfDoc.embedPng(files[index])            
            if(!image) continue;

            // Get the width/height of the JPG image scaled down to 25% of its original size
            // Get the width/height of the PNG image scaled down to 50% of its original size
            const imgDims = image.scale((type==='image/jpeg')?0.25:0.5)
            // Add a blank page to the document
            const page = pdfDoc.addPage()
            // Draw the image in the center of the page
            page.drawImage(image, {
              x: page.getWidth() / 2 - imgDims.width / 2,
              y: page.getHeight() / 2 - imgDims.height / 2,
              width: imgDims.width,
              height: imgDims.height,
            })
          } catch (error) {
              console.log(error)
          }
          
        }
        const pdfBytes = await pdfDoc.save()
        var blob = new Blob([pdfBytes], {type: "application/pdf"});
        var objectUrl = URL.createObjectURL(blob);
        await downloadFile(objectUrl,'new-file.pdf')
        setLoadingPdf(false)
        setSaveDisabled(false)
        toast.success("Download pdf file successfully.", {autoClose:5000})
      } catch (error) {
        setLoadingPdf(false)
        setSaveDisabled(false)
        console.log(error)
        toast.error("Download failed, ",error.message, {autoClose:10000})
      }
    }

    const handleSaveImage=async (img)=>{
      try{
        setShowLoading(true)
          var data=img.src
          var source;
          if(data.startsWith(API_URL)){
            const res = await getBase64Image(data);
            source=res
          } else {
            source=img.src
          }
          await downloadFile(source,'new-image.jpg')
          setShowLoading(false)
          toast.success("Download image successfully.", {autoClose:5000})
      } catch (err) {
        setShowLoading(false)
        console.log("1506   ",err)
        toast.error(err.message,{autoClose:10000})
      }
    }
    const downloadFile=async(base64,name)=>{
      try {
        const link = document.createElement('a');
        link.href = base64;
        link.setAttribute(
          'download',
            name,
          '_blank'
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error(1508 ,error)
      }
    }

    const getMimeTypeFromArrayBuffer=(arrayBuffer)=> {
      const uint8arr = new Uint8Array(arrayBuffer)
    
      const len = 4
      if (uint8arr.length >= len) {
        let signatureArr = new Array(len)
        for (let i = 0; i < len; i++)
          signatureArr[i] = (new Uint8Array(arrayBuffer))[i].toString(16)
        const signature = signatureArr.join('').toUpperCase()
    
        switch (signature) {
          case '89504E47':
            return 'image/png'
          case '47494638':
            return 'image/gif'
          case '25504446':
            return 'application/pdf'
          case 'FFD8FFDB':
          case 'FFD8FFE0':
            return 'image/jpeg'
          case '504B0304':
            return 'application/zip'
          default:
            return null
        }
      }
      return null
    }

    const getBase64Image=async(url) => {
      try {
          const response = await fetch(url);
          const blob = await response.blob();
          const reader = new FileReader();
          await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
          return reader.result//.replace(/^data:.+;base64,/, '')
        } catch (error) {
          console.log("1507   ",error)
          toast.error(error.message,{autoClose:10000})
          return []
        }
    }

    const selectAll = () => {
      var allImages=images
      allImages=allImages.filter((a)=>!selectedImages.find((b)=>a.id ==b.id))
        
      setSelectedImages([...selectedImages , ...allImages])
    }

    const deSelectAll = () => {
      var allSelected=selectedImages
      allSelected=allSelected.filter((a)=>!images.find((b)=>a.id==b.id))
      setSelectedImages(allSelected)
    }

    const Folder=({name,handleOnclick})=>{
      return (
          <Button className='m-2' style={{width:'20%'}}    color="primary" onClick={handleOnclick} startIcon={<FolderIcon/>} >
            {name}
          </Button>

      )
    }
    const contentListBatches=(loc)=>{
      const folders=[]
      var batches =batchesListRef.current
      const list=batches[loc]?.dates
      setBigImage(null)
      if(list && Array.isArray(list) && list[0]){
        list.forEach((d)=>{
          let batchID=loc+'-'+d
          let name=new Date(Number(d)).toLocaleString("en-ca", { hour12: false })
          folders.push(
            <Folder name={name} handleOnclick={()=>handleSelectedBatch(batchID)} />
          )
        })
      }
      setContent(folders)
      setRoadMap(
          <div className={classes.roadMap}>
            <HomeIcon className={classes.home}  color="primary" onClick={onClickHome} />
            <ArrowForwardIosIcon fontSize='small' />
            <b className={classes.textBtn}  onClick={()=>onClickLocation(loc)}>{loc}</b>
          </div>
      )
    }
    const onClickLocation=async(loc)=>{
      setShowBtn(false)
      await getBatchesList()
      contentListBatches(loc)
    }

    const handleOnclickImg  =(img)=>{
      setBigImage(img)
      const batchID=img.batchID
      const batches=batchesListRef.current
      const loc = batchID.split('-')[0]
      const date=new Date(Number(batchID.split('-')[1])).toLocaleString("en-ca", { hour12: false })

      setRoadMap(
        <div className={classes.roadMap}>
          <HomeIcon className={classes.home}  color="primary" onClick={onClickHome} />
          <ArrowForwardIosIcon fontSize='small' />
          <b className={classes.textBtn}  onClick={()=>onClickLocation(loc)}>{loc}</b>
          <ArrowForwardIosIcon fontSize='small' />
          <b className={classes.textBtn} onClick={()=>handleSelectedBatch(batchID)}>{date}</b>
          <ArrowForwardIosIcon fontSize='small' />
          <b className={classes.textBtn} >{img.id}</b>
        </div>
      )
    }
    const handleClosebigImg=()=>{
      const batchID=bigImage.batchID
      const batches=batchesListRef.current
      const loc = batchID.split('-')[0]
      const date=new Date(Number(batchID.split('-')[1])).toLocaleString("en-ca", { hour12: false })
      setBigImage(null)
      setRoadMap(
        <div className={classes.roadMap}>
          <HomeIcon className={classes.home}  color="primary" onClick={onClickHome} />
          <ArrowForwardIosIcon fontSize='small' />
          <b className={classes.textBtn}  onClick={()=>onClickLocation(loc)}>{loc}</b>
          <ArrowForwardIosIcon fontSize='small' />
          <b className={classes.textBtn} onClick={()=>handleSelectedBatch(batchID)}>{date}</b>
        </div>
      )
    }
        
    return (
      <>
      {showLoading ? 
        <div>
          <div style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "#000",
            opacity: 0.75,
            zIndex: 2000,
          }}></div>
          <div style={{
            position: "absolute",
            width: "50px",
            height: "50px",
            zIndex: 2001,
            top: "50%",
            left: "50%",
            margin: "-25px 0 0 -25px",
          }}>
            <ReactLoading type={"spin"} color={"#0085d2"} />
          </div>
        </div>
      : ""}
      { bigImage ? 
        <Modal className={classes.root} show={true} size="xl" onHide={handleClosebigImg} >
          <Modal.Body style={{position: "relative", padding: "0px"}}>
              <div className={classes.header}>
                {roadMap}
                  <CloseIcon className={classes.home}  color="primary" onClick={handleClosebigImg} />
              </div>
            <div style={{width: "100%", height: "85vh", overflow: "auto"}}>
              <img className='img-fluid' src={bigImage.src}/>
            </div>
          </Modal.Body>
        </Modal> 
        :
        <Modal className={classes.root} show={showModal} size="xl" onHide={handleClose} >
          <Confirm ref={confirmRef}/>
          <Modal.Body style={{padding: "0.5rem"}}>
            {
              isImgEditorShown ||
              <div className={classes.header}>
                {roadMap}
                <CloseIcon className={classes.home}  color="primary" onClick={()=>handleClose()}/>
              </div>
            }

              <Grid  color='secondary' container  direction='row' 
                style={{height:"85vh", overflowY: "hide", paddingLeft: "10px", paddingRight: "5px"}}>
              {(isImgEditorShown)?
                <FilerobotImageEditor
                    source={editorImage?.src}
                    onSave={onSaveFilerobotImageEditor}
                    onClose={closeImgEditor}
                    annotationsCommon={{
                        fill: '#ff0000',
                        shadowColor:"#ff0000"
                    }}
                    
                    Text={{ text: 'Filerobot...' }}
                    Rotate={{ angle: 90, componentType: 'buttons' }}
                    Crop={{
                        presetsItems: [
                        {
                            titleKey: 'classicTv',
                            descriptionKey: '4:3',
                            ratio: 4 / 3,
                            // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
                        },
                        {
                            titleKey: 'cinemascope',
                            descriptionKey: '21:9',
                            ratio: 21 / 9,
                            // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
                        },
                        ]
                    }}
                    tabsIds={[TABS.ADJUST,TABS.FILTERS]} // or {['Adjust', 'Annotate', 'Watermark']}
                    defaultTabId={TABS.ADJUST} // or 'Annotate'
                    defaultToolId={TOOLS.ROTATE} // or 'Text'
                    defaultSavedImageType={'jpeg'}
                    onBeforeSave={(imageFileInfo)=>{return false}}
                    />:
                    <>
                      <div className={(selectedImages && selectedImages[0])?'row':""}  style={{width: "100%", height: "94%", overflowY:"auto",margin:'0'}} >
                        {(selectedImages && selectedImages[0]?
                          <>
                            <div style={{width: "85%", height: "94%", overflowY:"auto"}}>
                              {content}
                            </div>
                            <div style={{width:"15%",height: "94%", overflowY:"auto",overflowX:'hidden',borderLeft:"1px solid gray"}}>
                              {renderSelectedImages}
                            </div>
                          </>:content
                        )}
                      </div>
                      <div className='d-flex justify-content-between align-items-center  pb-3' style={{width: "100%"}}>
                        <div>
                          {selectedImages.length > 0 ? 
                            <Button 
                              variant="contained" 
                              color="secondary" 
                              startIcon={<DeleteIcon />}
                              className='mx-2' onClick={() => removeImages(selectedImages)}>
                              Delete
                            </Button> 
                            : ""
                          }
                          {showBtn ?
                            (currentlyAllSelected ? 
                            <Button variant="contained" color="secondary" startIcon={<CheckBoxIcon/>} onClick={deSelectAll}>Deselect All</Button> :
                            <Button variant="contained" color="secondary" startIcon={<CheckBoxOutlineBlankIcon/>} onClick={selectAll}>Select All</Button>)
                            :''
                          }
                        </div>
                        <div>
                          <Button 
                            variant="contained" color="secondary"
                            disabled={saveDisabled} 
                            onClick={handleSaveFiles}
                            startIcon={(loadingPdf)?<ReactLoading type='spokes'  color={"black"}  style={{width:"24px",height:"24px",display:"flex"}}/> :<Download/>}
                            >
                            Download PDF
                          </Button>
                        </div>
                      </div>                               
                    </>
                  }
              </Grid>
          </Modal.Body>
        </Modal>
      }
      </>
    )
} 