import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import LucasLogo from '../../assets/images/virtualsally_logo.png';
import TopTapImage from '../../assets/images/top_tap.png';
import BottomTapImage from '../../assets/images/bottom_tap.png';

const Success = (props) => {
    const { history } = props;

    const handleClickSend = () => {
        history.push('/login');
    }
    return (
        <div className='root'>
            <img className='lucas_logo' src={LucasLogo} alt="lucas_logo"/>
            <img className='top_tap' src={TopTapImage} alt="top_tap" />
            <img className='bottom_tap' src={BottomTapImage} alt="bottom_tap" />
            <div className='center_container'>
                <div className='w-100 d-flex justify-content-center mb-3'>
                    <div className='col-10 col-md-7 col-xl-4 p-0 '>
                        <h3>Forgot your password</h3>
                        <h6>An email has just been sent to you to reset your password. Please check your email.</h6>
                    </div>
                </div>
                <div className='w-100 d-flex justify-content-center mb-1'>
                    <Button className="col-10 col-md-7 col-xl-4" variant="contained" style={{ backgroundColor: "#0085d2", color: "#FFFFFF" }}
                        onClick={handleClickSend} id="send">
                        To log in
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Success);