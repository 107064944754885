
import React, { useEffect , useState, memo, useContext} from 'react';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { yellow, red } from '@material-ui/core/colors';
import Draggable from 'react-draggable';
import { SocketContext } from '../../context/socket'
import ScannerIcon from '@material-ui/icons/Scanner';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import apiService from "../../services/api"
import { toast } from "react-toastify"

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#ffff00',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  },
}))(Button);
const useStyle = makeStyles(({palette,mode}) => ({
  dialogTitle: {
    cursor:"move",
    backgroundColor:palette.background[mode],
    color:palette.color[mode],
    '& .MuiTypography-h6':{
      color:palette.color[mode],
    }
  }
}))
const ScanRequestModal = memo((props)=>{
    const {allLocations, locationEngaged, open, handleClose, reception}=props
    const [showModal, setShowModal] = useState(false)
    const [location, setLocation] = useState("");
    const [paperSource, setPaperSource] = useState("glass");
    const [disableInputs, setDisableInputs] = useState(false);
    const [scanLog, setScanLog] = useState("");
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const socket = useContext(SocketContext)
    const classes=useStyle()
    const [disableScan,setDisableScan]=useState(false)
    const [batchID,setBatchID]=useState('')

    useEffect(() => {
      socket.on('msg',({event, msg})=>{
        if(event === "scan-res") {
          if(msg.code !== 0) {
            setDisableInputs(false);
            setDisableScan(false);
          } 
          setScanLog(msg.msg)
        }else if(event ==='scan-result-is-ready'){
          setDisableScan(false)
          setBatchID(msg.batchID)
          setScanLog("Last scan completed, Would you like to continue scanning?")

        }
      })

      return () => {
        socket.off("msg")
      }
    }, [])

    useEffect(() => {
      setDisableInputs(false);
      setDisableScan(false);
      setScanLog("")
      setBatchID('')
      setShowModal(open)
    }, [open])

    useEffect(() => {
      setLocation(locationEngaged)
      setBatchID('')
    },[locationEngaged])

    function onLocationChanged(event) {
      setLocation(event.target.value);
    }

    function onPaperSourceChanged(event) {
      setPaperSource(event.target.value);
    }

    async function submit() {
      try{
        if(!location) {
          toast.error(`'location' is required.`, {autoClose: 5000})
          return;
        }

        setDisableInputs(true);
        setDisableScan(true);
        
        const scanRes = await apiService.sendScanRequest({
          location, 
          paperSource, 
          batchID, 
          status: "start"
        })
        if(scanRes.data.code !== 0) {
          setScanLog("Error while sending scan request. message: " + scanRes.data.msg)
        } else {
          setScanLog("Scan request has been sent. Waiting for scan to start.")
        }
      } catch (err) {
        console.error(err)
        setScanLog("Error while sending scan request. message: " + err.message)
      }
    }

    async function cancel() {
      try{
        await apiService.sendScanRequest({
          location, 
          status: "cancel"
        })
        handleClose()
      } catch (err) {
        console.error(err)
        setScanLog("Error while sending cancel request. message: " + err.message)
      }
    }

    return (
      <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Dialog
          open={showModal}
          onClose={handleClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
          disableBackdropClick="false"
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle className={classes.dialogTitle} disableTypography={true}>
            <h5>Scan Request</h5>
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" className='mt-1'>
              <InputLabel id="location-label">To Location</InputLabel>
              <Select
                labelId="location-label"
                onChange={onLocationChanged}
                labelWidth={80}
                value={location}
                disabled={disableInputs}
              >
                <MenuItem value="">Please choose ...</MenuItem>
                {allLocations.map((item, index) => {
                  const isSec = !item.isPrimary;
                  if(isSec && !showSecondaryLocations) return;
                  return (
                    <MenuItem key={index} value={item.username}>
                      {item.locationname}
                      {isSec && 
                        <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                      }
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" className='mt-3'>
              <InputLabel id="scan-type">Paper Source</InputLabel>
              <Select
                labelId="scan-type"
                onChange={onPaperSourceChanged}
                labelWidth={80}
                value={paperSource}
                disabled={disableInputs}
              >
                <MenuItem key="1" value="glass">Glass</MenuItem>
                <MenuItem key="2" value="duplex">Duplex</MenuItem>
              </Select>
            </FormControl>
            <div style={{marginTop: "10px", marginBottom: "5px"}}>{scanLog}</div>
          </DialogContent>
          <DialogActions className='px-4 pb-4'>
          <RedButton className="dialog_btn" variant="contained" color="secondary" 
            startIcon={<CloseIcon />} onClick={cancel}>Cancel</RedButton>
          <YellowButton className="dialog_btn" variant="contained" color="secondary" 
            startIcon={<ScannerIcon />} onClick={submit} disabled={disableScan}>Scan</YellowButton>
          </DialogActions>
        </Dialog>
      </Draggable>
    )
})
export default ScanRequestModal