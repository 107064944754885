import React, { useEffect, useState } from "react";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./style.css";
import "./remotelocationvideoview.css";
import { makeStyles } from '@material-ui/styles';
import RemoteLocationVideoView from './RemoteLocationVideoView';
import UnloginImage1 from '../../assets/images/unlogin_1.png';
import UnloginImage2 from '../../assets/images/unlogin_2.png';
import UnloginImage3 from '../../assets/images/unlogin_3.png';
import UnloginImage4 from '../../assets/images/unlogin_4.png';
import PreviewIcon from '../../assets/images/before_enable_btn.png';
import NextIcon from '../../assets/images/next_enable_btn.png';
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import {sendReloadLocationToSocket} from '../../context/socket'
import {Box , ButtonBase, Tooltip} from '@material-ui/core'
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import PowerOffOutlinedIcon from '@material-ui/icons/PowerOffOutlined';
import { useSelector } from 'react-redux';

const useStyle = makeStyles((theme) => ({

    root:{
        position:"relative",
        margin:"5px",
        '&:hover $reload':{
            display:'block'
        },
        '&hover .MuiButtonBase-root':{
            display:"block !important"
        },
        minHeight:"130px",
        minWidth:"130px",
        backgroundColor: "#b0b0b0",
        borderRadius: "5px"
    },
      img:{
        width:"100%",
        height:"100%",
        borderRadius:"5px"
      },
      reload:{
          backgroundColor:"#1C1D1E",
          color:"white",
          display:"none",
          position:"absolute",
          top:0,
          right:0,
          borderRadius:"5px",
          padding:"3px",
      },
      statusIcon:{
    
      },
      name:{
        backgroundColor:"#1C1D1E",
        color:"white",
        position:"absolute",
        bottom:0,
        left:0,
        borderRadius:"5px",
        padding:"3px",
        display:"flex",
        alignItems:"center",
      },

    unloged_area: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center'
    },
    unloged_img: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    previewArrowClass: {
        '&::before': {
            content: `url(${PreviewIcon})`
        }
    },
    nextArrowClass: {
        '&::before': {
            content: `url(${NextIcon})`
        }
    },
    secondaryLocationBox: {
      border: "2.5px solid #f3c200"
    }
}))

const ListLocations = React.memo((props) => {
    const { remoteLocations, allLocationData, localUserData, width, height } = props;
    const LoginedImageList = [UnloginImage1, UnloginImage2, UnloginImage3, UnloginImage4];
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const classes = useStyle();

    const reloadLocation=(e,username)=>{
      e.stopPropagation();
      sendReloadLocationToSocket(username)
    }

    // function fixSlickFirstRenderSmallScreenBug() {
    //   setTimeout(() => {
    //     document.getElementsByClassName("slick-track")[0].style.transform = "translate3d(0px, 0px, 0px)"
    //     document.getElementsByClassName("slick-track")[0].style.transition = "transform 1000ms ease 0s"
    //   }, 200);
    // }

    // useEffect(() => {
    //   if(allLocationData && allLocationData[0]) {
    //     // fixSlickFirstRenderSmallScreenBug();
    //   }
    // }, [allLocationData])
    
    const addRemoteLocationVideo = (data, index, loc) => {
      const isSec = !loc.isPrimary;
      return (
        <div className={classes.root + (isSec ? " " + classes.secondaryLocationBox : "")}>
          <RemoteLocationVideoView
              handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
              handleItemCall={() => props.handleItemCall()}
              track={data.videotrack}
              othertrack={data.othertrack}
              video_tag_id={data.videotrack.getParticipantId() + 'location' + data.videotrack.getType()}
              locationName={data.locationname}
              index={index}
              calltag={data.calltag}
              status={data.status}
              localUserData={localUserData}
              reloadLocation={reloadLocation}
              username={data.username}
              width={width}
              height={height}
              id={data.id}
          />
        </div>
      )
    }

    const addUnloginedLocationVideo = (cell, index) => {
      const isSec = !cell.isPrimary;
      return (
        <Box className={classes.root + (isSec ? " " + classes.secondaryLocationBox : "")} style={{width, height}}>
          <div className={classes.img} 
            style={{
              backgroundImage:`url(${getUnLogedImage(cell.logo, index)})`,
              backgroundPosition:"center",backgroundSize:"cover"
            }} 
            alt={`unloged_img_${index}`}>
          </div>
          <div className={classes.name}>
            <PowerOffOutlinedIcon/>
            <label style={{margin:'0 5px'}}>
              {cell.locationname}
              {isSec && 
                <sup style={{color: "#f3c200"}} title='Secondary location'>&nbsp;2nd</sup>
              }
            </label>
          </div>
        </Box>
      )
    }
    const addSleepLocationVideo = (cell, index)=>{
      const isSec = !cell.isPrimary;
      return (
        <Box className={classes.root + (isSec ? " " + classes.secondaryLocationBox : "")} style={{ cursor: 'pointer', width, height}} 
          onClick={()=>{props.handleItemSleep(index)}}>
            <div className={classes.img} 
                style={{display:"flex",
                        backgroundImage:`url(${getUnLogedImage(cell.logo, index)})`,
                        backgroundPosition:"center",backgroundSize:"cover"
                    }} 
                alt={`unloged_img_${index}`} >
            </div>
            <Tooltip title={`Reload ${cell.locationname}`}>
                <ButtonBase className={classes.reload} onClick={event=>reloadLocation(event,cell.username)}>
                    <ReplayOutlinedIcon/>
                </ButtonBase>
            </Tooltip>
            <Tooltip title={`Sleep`}>
                <div className={classes.name}>
                <NightsStayOutlinedIcon style={{marginLeft: "1px"}}/>
                <label style={{margin:'0 5px'}}>
                  {cell.locationname}
                  {isSec && 
                    <sup style={{color: "#f3c200"}} title='Secondary location'>&nbsp;2nd</sup>
                  }
                </label>
                </div>
            </Tooltip>
        </Box>
      )
    }

    function getUnLogedImage(logo, index) {
        let ret = "";

        if (logo === "" || logo === null) {
          ret = LoginedImageList[index % 4];
        } else if (logo.startsWith("http://") || logo.startsWith("https://")) {
          ret = logo;
        } else {
          ret = process.env.REACT_APP_UPLOAD_URL + logo
        }

        return ret;
    }
    function separateLocations(loc, index){
      const isSec = !loc.isPrimary;
      if(isSec && !showSecondaryLocations) return;

      var onlineIndex=remoteLocations.findIndex((rl)=>rl.username==loc.username)
      if(onlineIndex!=-1 && loc.isSleep != 1 && loc.isOnline != 0){
        var online =remoteLocations[onlineIndex]
        if(online.videotrack===null){
          return (
            <Box className={classes.root + (isSec ? " " + classes.secondaryLocationBox : "")} style={{ cursor: 'pointer', width, height }} 
              onClick={()=>{props.handleItemSleep(index)}}>
                <div className={classes.img}  
                    style={{
                          display:"flex",
                        backgroundImage:`url(${getUnLogedImage(loc.logo, index)})`,
                        backgroundPosition:"center", backgroundSize:"cover"
                    }} 
                    alt={`unloged_img_${index}`} >

                </div>
                
                <Tooltip title={`Reload ${loc.locationname}`}>
                    <ButtonBase className={classes.reload} onClick={event=>reloadLocation(event,loc.username)}>
                        <ReplayOutlinedIcon/>
                    </ButtonBase>
                </Tooltip>
                <Tooltip title='No video track'>
                    <div className={classes.name}>
                        <VideocamOffOutlinedIcon/>
                        <label style={{margin:'0 5px'}}>
                            {loc.locationname}
                            {isSec && 
                              <sup style={{color: "#f3c200"}} title='Secondary location'>&nbsp;2nd</sup>
                            }
                        </label>
                    </div>
                </Tooltip>
            </Box>
          )
        } else {
          return addRemoteLocationVideo(online, onlineIndex, loc)
        }
      } else if(loc.isSleep == 1 && loc.isOnline==1){
        return addSleepLocationVideo(loc, index)
      } else if(loc.isOnline==0) {
        return addUnloginedLocationVideo(loc, index)
      } else {
        return (
          <Box className={classes.root + (isSec ? " " + classes.secondaryLocationBox : "")} style={{ cursor: 'pointer',width, height }}>
              <div className={classes.img}  
                  style={{
                      display:"flex",
                      backgroundImage:`url(${getUnLogedImage(loc.logo, index)})`,
                      backgroundPosition:"center",backgroundSize:"cover"
                  }} 
                  alt={`unloged_img_${index}`}>
              </div>
              <Tooltip title={`Reload ${loc.locationname}`}>
                  <ButtonBase className={classes.reload} onClick={event=>reloadLocation(event,loc.username)}>
                      <ReplayOutlinedIcon/>
                  </ButtonBase>
              </Tooltip>
              <Tooltip title='Waiting'>
                  <div className={classes.name}>
                      <HourglassEmptyOutlinedIcon/>
                      <label style={{margin:'0 5px'}}>
                          {loc.locationname}<small> (waiting...)</small>
                          {isSec && 
                            <sup style={{color: "#f3c200"}} title='Secondary location'>&nbsp;2nd</sup>
                          }
                      </label>
                  </div>
              </Tooltip>
          </Box>
        )
      }
    }

    return (
      <>
        {allLocationData.map((loc,index)=>separateLocations(loc,index))}
      </>
    )
});

export default ListLocations;
