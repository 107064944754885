import { createSlice } from '@reduxjs/toolkit';


export const Messages=createSlice({
    name:"messages",
    initialState:{
        all:{},
        unRead:{}
    }, 
    // {
    //    username:[
    //      {'msg'}
    //     ]
    // }
    reducers:{
        addMessage:(state, action)=>{
            const obj=action.payload
            var messages=state.all[obj.reception]
            if(!messages || !messages[0]) messages=[]
            const message=obj.message
            messages.push(message)
            state.all[obj.reception]=messages
            return state
        },
        setMessages:(state,action)=>{
            const obj=action.payload
            const messages=obj.messages
            state.all[obj.reception]=messages
            return state
        },
        addUnReadMessage:(state,action)=>{
            const obj=action.payload
            var number=state.unRead[obj.reception] || 0
            state.unRead[obj.reception]=number+1
            return state
        },
        setUnReadMessages:(state,action)=>{
            const arr=action.payload
            if(!Array.isArray(arr))return
            for(var obj of arr){
                state.unRead[obj.username]=obj.number
            }
            return state
        }

    }
})

export const { addMessage,setMessages, addUnReadMessage,setUnReadMessages} = Messages.actions

export default Messages.reducer