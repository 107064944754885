import React from 'react';
import TopTapImage from '../assets/images/top_tap.png';
import BottomTapImage from '../assets/images/bottom_tap.png';
import VersionComponent from '../Version'
import LucasLogo from '../assets/images/virtualsally_logo.png';


const NotFoundPage=()=>{
    return(
        <div className='root'>
        <img className='lucas_logo' src={LucasLogo} alt="lucas_logo"/>
        <img className='top_tap' src={TopTapImage} alt="top_tap" />
        <img className='bottom_tap' src={BottomTapImage} alt="bottom_tap" />
        <div className='center_container'>
          <div className='d-flex flex-column justify-content-center' style={{width:"65%"}}>
            <h1 className='mx-auto mt-4 text-center'>404</h1>
            <h2 className="mx-auto mt-4 text-center" >Page Not Found</h2>
          </div>
        </div>
        <VersionComponent/>
      </div>
    )
}
export default NotFoundPage