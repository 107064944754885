import React, { Component } from 'react';
import { Switch, Redirect, Route, BrowserRouter } from 'react-router-dom';
import Storages from './constants/storages';

import {
    Login,
    ForgotPassword,
    Success,
    Doctor,
    NotFoundPage
} from './Pages'

class Routes extends Component {
    constructor(props) {
        super(props);
    }

    navigate() {
        var username=localStorage.getItem(Storages.LOCAL_LOGINED_USER);
        var token=sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
        if(!token)token= localStorage.getItem(Storages.LOCAL_TOKEN_USER)
        var clientId=localStorage.getItem(Storages.LOCAL_CLIENT_ID);
        if(! username || !token || !clientId) {
            sessionStorage.clear()
            localStorage.removeItem(Storages.LOCAL_TOKEN_USER);
            localStorage.removeItem(Storages.LOCAL_ROOM_NAME);
            localStorage.removeItem(Storages.LOCAL_LOGINED_USER);
            localStorage.removeItem(Storages.LOCAL_COMPANY_NAME);
            localStorage.removeItem(Storages.LOCAL_CLIENT_ID);
            localStorage.removeItem("groupnum");
            localStorage.removeItem("showToggleSecondary");
            localStorage.removeItem("logo")

            window.location.href='/login'
            return
        }
        window.location.href=`/${username}`
        return
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={this.navigate} />
                    <Route exact path="/login" component={() => <Login {...this.props} />} />
                    <Route exact path="/forgotpassword" component={ForgotPassword} />
                    <Route exact path="/success" component={Success} />
                    <Route exact path="/:username/" component={() => <Doctor {...this.props} />} />
                    <Route path="*" component={NotFoundPage}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default Routes;