import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import { Tooltip } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  drop_btn: {
    display: 'flex',
    padding: '0px',
    minWidth: 'auto',
    outline: '0px!important',
    color:"white",
    '&:hover': {
        cursor: 'pointer',
        backgroundColor:"white",
        color:"#1C1D1E"
    },
  },
  optionDevices: {
    display: 'flex',
    position: 'absolute',
    right: '-5px',
    top: '-5px',
    borderRadius:'5px',
    color:"white",
    
},
  menuList:{
    backgroundColor:"#1C1D1E",
    color:"white",
    padding:"5px",
    borderRadius:"5px",
  },
  menuItem:{
    color:"white",
    '&:hover':{
      backgroundColor:"#404040"
    },
    display: "flex",
    justifyContent: "space-between"
  },
  menuItemSelected:{
    color:"white",
    backgroundColor:"#404040 !important",
    display: "flex",
    justifyContent: "space-between"
  }
}));

const MenuListComposition = React.memo((props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, index) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if (index === undefined) {
      return;
    }
    props.onClickItemSelector(index);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.optionDevices}>
        <Tooltip title={props.title} placement='top'>
          <Button
            className={classes.drop_btn}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <ArrowDropUpOutlinedIcon/>
          </Button>
        </Tooltip>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='top-start'>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className={classes.menuList}  autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {props.menus && props.menus.map((menu, index) => (
                      <MenuItem
                        className={(menu.selected)?classes.menuItemSelected:classes.menuItem}
                        key={index}
                        selected={menu.selected}
                        onClick={(event) => handleClose(event, index)}>
                        {menu.label}
                        {menu.bar !== undefined ? 
                        <div style={{width: "50px", height: "10px", backgroundColor: "gray", 
                          marginLeft: "5px", borderRadius: "2px"}}>
                          <div style={{width: menu.bar + "%", height: "10px", 
                            backgroundColor: "green", borderRadius: "2px"}}>&nbsp;</div>
                        </div>
                        : ""}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </div>
  );
})

export default MenuListComposition;