import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useState } from "react";
import String from '../../constants/string';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
const useStyles = makeStyles({
    main:{
        backgroundColor:"#404040",
        position:"absolute",
        bottom:0,
        borderRadius:"5px",
        padding:"5px 10px 5px 10px",
        marginBottom:"6rem",
        color:"white",
        minWidth:"5vw",
        justifyContent:"center",
        display:"flex",
        alignItems:"center"
    },
});

export default function LocationNameBox ({name,id}){
    const classes = useStyles();
    const tracksMuted = useSelector((state) => state.tracksMuted)
    const [tracksMutedContent,setTracksMutedContent]=useState([])
    useEffect(()=>{
        if(id){
            var content=[]
            let devices=tracksMuted[id]
            console.log('1201122 ',devices)
            if(devices && devices[String.STR_VIDEO] ) {
                content.push(<VideocamOffOutlinedIcon />)
            }
            if(devices && devices[String.STR_AUDIO]) {
                content.push(<MicOffOutlinedIcon/>)
            }
            console.log('120154' , content)
            setTracksMutedContent(content)
        }
    },[tracksMuted,id])


    return (
        <>
            {name?
                <div className={classes.main}>
                    <h5 className="mb-0">{tracksMutedContent} {name}</h5>
                </div>
                :null
            }

        </>

    );
}
 
