import { configureStore } from '@reduxjs/toolkit';
import micReducer from './micSlice';
import cameraReducer from './cameraSlice';
import locationsReducer from './locations';
import apiCommitHash from './apiCommitHash';
import showSecondaryLocationsReducer from './showSecondaryLocations';
import messages from './messages';
import connections from './connections';
import tracksMuted from './tracksMuted';
import remoteStates from './remoteStates';
const store = configureStore({
	reducer: {
		mics: micReducer,
    	cameras: cameraReducer,
		locations:locationsReducer,
		apiCommitHash:apiCommitHash,
		messages:messages,
		showSecondaryLocations: showSecondaryLocationsReducer,
		connections: connections,
		tracksMuted:tracksMuted,
		remoteStates: remoteStates
	},
});

export default store
