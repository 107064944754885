import { createSlice } from '@reduxjs/toolkit';


export const apiCommitHashSlice=createSlice({
    name:"commitHash",
    initialState:0,
    reducers:{
        setCommitHash:(state, action)=>{
            state=action.payload
            return state
        }
    }
})

export const { setCommitHash} = apiCommitHashSlice.actions

export default apiCommitHashSlice.reducer