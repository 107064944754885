

import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState, useRef } from 'react';
import React  from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import apiService from "../../services/api"
import {useSelector, useDispatch } from 'react-redux';
import { addMessage, setMessages, setUnReadMessages } from '../../redux/messages';
import { toast } from 'react-toastify';

const useStyles = makeStyles(({palette,mode}) => ({
    root:{
        borderRadius:"10px",
        height:"45%",
        width:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        position:'absolute',
        bottom:'0',
        zIndex:1,
    },
    header:{
        backgroundColor:palette.bgMessanger[mode],
        '& .MuiCardHeader-title':{
            color:palette.colorMessanger[mode]
        },
        padding:'5px',
        '& .MuiCardHeader-action':{
            margin:0
        }
    },
    btnClose:{
        border:0,
        borderRadius:0,
        padding:0,
        color:palette.colorMessanger[mode],
    },
    content:{
        height:"-webkit-fill-available",
        padding:'5px',
        overflowY:"auto",
        overflowWrap:"anywhere",
        backgroundColor:"#B0B0B0",
        '&::-webkit-scrollbar':{
            width:'0.5rem'
        },
        '&::-webkit-scrollbar-thumb':{
            backgroundColor:'#fafafa',
            borderRadius: '5px'
        },
                /* Track */
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        display:'flex',
        flexDirection:"column"
    },
    actions:{
        backgroundColor:"#B0B0B0",
        
    },
    input:{
        flex: 1,
    },
    iconButton: {
        padding: 5,
        color:"#303030",
        borderRadius:0,
        border:0
    },
    divider: {
        height: 28,
        margin: 2,
    },
    paper:{
        width:"100%",
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        backgroundColor: 'red',
    },
    receive:{
        backgroundColor:"#F7F7F7",
        padding:"2px 5px",
        margin:'2px 5px 2px 0',
        width:"fit-content",
        borderRadius:"10px 10px 10px 0px",
        justifyContent:"center",
        display:"flex",
        float:"left",
        flexDirection:"column",
        color:"#303030",
    },
    send:{
        backgroundColor:"#EDEDED",
        padding:"2px 5px",
        margin:'2px 0 2px 5px',
        color:"#303030",
        width:"fit-content",
        borderRadius:"10px 10px 0px 10px",
        // alignItems:'center',
        justifyContent:"center",
        display:"flex",
        float:"right",
        flexDirection:"column"
    },
    time:{
        display:'absolute',
        float:"right",
        bottom:0,
        width:"fit-content"
    },
    date:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        margin:'5px'
    },
    spinner:{
        height:"100%",
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    }
  }));
export default ({display,handleCloseChat,reception})=>{
    const classes=useStyles()
    const [msg,setMsg]=useState('')
    const dispatch=useDispatch()
    const messages = useSelector((state) => state.messages.all[reception])
    const [listMessages,setListMessages]=useState([])
    const messagesEndRef=useRef(null)
    const [spinner,setSpinner]=useState(false)
    const [limit,setLimit]=useState(100)
    const [lastMessageID,setLastMessageID]=useState(false) 
    const [firstLoad,setFirstLoad]=useState(false) 
    
    useEffect(()=>{
        if(!messages || !messages[0]) return  setListMessages([])
        var date;
        const list=[]
        var firstUnRead=false
        for(var msg of messages){
            const {send_at,message,id,read_at}=msg
            var msgDate=send_at.slice(0,10)
            var time=send_at.slice(11,16)
            if(date!=msgDate) list.push(lineDate(msgDate))
            if(msg.sender==reception){
                if(!read_at && !firstUnRead && firstLoad) {
                    // scroll to this msg
                    list.push(lineDate('Unread'))
                    setLastMessageID("Unread")
                    firstUnRead=true
                }
                list.push(messageReceive(message,time,id))
            }
            if(msg.receiver==reception) list.push(messageSend(message,time,id))
            date=msgDate
        }
        setListMessages(list)
    },[messages])
    
    useEffect(()=>{
        if(display){
            setLimit(100)
            setSpinner(true)
            getMessages()
            setFirstLoad(true)
        }
    },[reception])
    
    useEffect(()=>{
        setFirstLoad(true)
    },[display])

    useEffect(()=>{
        setSpinner(false)
        setTimeout(() => {
            scrollToBottom()
        }, 20);
    },[listMessages])
    
    const getMessages=async(n)=>{
      try{
        if(!n) n=limit
        if(!reception)return
        var res=await apiService.getMessages({reception,limit:n})
        const all=res.data.data
        dispatch(setMessages({reception,messages:all}))
        dispatch(setUnReadMessages([{username:reception,messages:all}]))
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const handleSubmitInput=async()=>{
      try{
        if(!msg || !msg.trim()) return
        const data={
            to:reception,
            event:"chat",
            msg:msg.trim()
        }
        var res=await apiService.sendMessage(data)
        if(res.data.code===0){
            var message=res.data.data
            // add to redux (all messages)
            setFirstLoad(false)
            dispatch(addMessage({reception,message}))

            if(messagesEndRef && messagesEndRef.current && messagesEndRef.current.lastElementChild){
                messagesEndRef.current.lastElementChild.scrollIntoView({block: 'end'});
            }
        }
        setMsg('')
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      }
    }
    const handleTyping=(e)=>{
        setMsg(e.target.value)
    }
    const handleOnKeyDown=(e)=>{
        if(e.key === 'Enter') handleSubmitInput()
    }
    const messageSend=(msg,time,id)=>{
        return(
            <div id={id} style={{width:'100%'}} key={id}>
                <div className={classes.send}>
                    <span>
                        {msg}
                    </span>
                    <div>
                        <small className={classes.time}>{time}</small>
                    </div>
                </div>
            </div>
        )
    }
    const messageReceive=(msg,time,id)=>{
        return(
            <div id={id} style={{width:'100%'}} key={id}>
                <div className={classes.receive}>
                    <span>{msg}</span>
                    <div>
                        <small className={classes.time}>{time}</small>
                    </div>
                </div>
            </div>
        )
    }
    const lineDate=(date)=>{
        return (
            <div id={date.replaceAll(' ','-')} className={classes.date} key={date.replaceAll(' ','-')}>
                <small><b>{date}</b></small>
            </div>
        )
    }
    
    const scrollToBottom = () => {
        if(lastMessageID) {
            var el=document.getElementById(lastMessageID)
            if(el) el.scrollIntoView()
            setLastMessageID('')
            return
        }
        if(messagesEndRef && messagesEndRef.current){
            const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current;
            if(firstLoad){
                if(messagesEndRef.current.lastElementChild){
                    messagesEndRef.current.lastElementChild.scrollIntoView();
                }
            }
            else if (scrollTop + clientHeight + 100 > scrollHeight) { // bottom of the chat room
                if(messagesEndRef.current.lastElementChild)
                    messagesEndRef.current.lastElementChild.scrollIntoView({block: 'end'});
            }
        }  
    }

    const handleScroll=(e)=>{
        let element = e.target
        if (element.scrollTop == 0) {
            if(messages && messages[0]) setLastMessageID(messages[0].id)
            var n=limit+100
            setLimit(n)
            getMessages(n)
        }
    }

    if(display){
        return(
            <Card className={classes.root}>
                <CardHeader 
                    className={classes.header}  
                    title={reception}
                    action={
                        <IconButton className={classes.btnClose} onClick={handleCloseChat} aria-label="close">
                            <CloseOutlinedIcon />
                        </IconButton>
                    }
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            {reception && reception[0]?.toUpperCase()}
                        </Avatar>
                        }
                />
                <CardContent className={classes.content} onScroll={handleScroll} ref={messagesEndRef}>
                    {spinner?
                    <div className={classes.spinner}>
                        <CircularProgress size={'2rem'} color='#303030'/>
                    </div>
                    :listMessages}
                </CardContent>
                <CardActions className={classes.actions}>
                    <Paper className={classes.paper}>
                        <InputBase
                            className={classes.input}
                            placeholder="Type..."
                            onChange={handleTyping}
                            value={msg}
                            onKeyDown={handleOnKeyDown}
                        />
                        <Divider className={classes.divider} orientation="vertical" />
                        <IconButton  className={classes.iconButton} onClick={handleSubmitInput} aria-label="submit">
                            <SendOutlinedIcon />
                        </IconButton>
                    </Paper>
                </CardActions>
            </Card> 
        )
    }else return null

}