import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    divContainer: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        padding: 0,
        background: 'linear-gradient(180deg, #00000099 10%, #ffffff 90%)',
        flexDirection: 'column',
    },
    settingContianer: {
        position: 'absolute',
        top: '5vh',
        right: '8vh',
    },
    root: {
        [theme.breakpoints.up('xl')]: {
            '& .MuiOutlinedInput-input': {
                padding: '17px 25px',
                fontSize: 22,
            },
        },
        [theme.breakpoints.down('lg')]: {
            '& .MuiOutlinedInput-input': {
                padding: '11px 17px',
                fontSize: 15,
            },
        },
        paddingTop: theme.spacing(0),
        marginTop: 'auto',
        marginBottom: 'auto',
        justifyContent: 'center',
        '& .MuiOutlinedInput-root': {
            borderRadius: 8
        }
    },
    title: {
        [theme.breakpoints.up('xl')]: {
            width: 958,
            paddingBottom: 61,
            fontSize: 20,
            textIndent: -10,
        },
        [theme.breakpoints.down('lg')]: {
            width: 670,
            paddingBottom: 42,
            fontSize: 14,
            textIndent: -7,
        },
        [theme.breakpoints.down('md')]: {
            width: 469,
            paddingBottom: 29,
            fontSize: 14,
            textIndent: -5,
        },
        color: 'white',
        textAlign: 'center'
    },
    input: {
        padding: '15px',
        justifyContent: 'center',
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(5)
        },
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(4)
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3)
        },
    },
    logo: {
        [theme.breakpoints.up('xl')]: {
            width: 438,
            height: 90
        },
        [theme.breakpoints.down('lg')]: {
            width: 306,
            height: 63
        },
        [theme.breakpoints.down('md')]: {
            width: 214,
            height: 44
        },
    },
    checkbox: {
        color: 'black!important',
        padding: '0!important',
        margin: '0!important',
        '& .MuiSvgIcon-root': {
            [theme.breakpoints.up('xl')]: {
                transform: 'scale(1)'
            },
            [theme.breakpoints.down('lg')]: {
                transform: 'scale(0.8)'
            },
            [theme.breakpoints.down('md')]: {
                transform: 'scale(0.5)'
            },
        }
    },
    remember: {
        [theme.breakpoints.up('xl')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
        },
        padding: 0,
        margin: 0,
        marginLeft: '5px',
        fontFamily: "Poppins",
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '-0.04px',
        marginRight: 'auto!important'
    },
    checkboxTitle: {
        [theme.breakpoints.up('xl')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
        },
        padding: 0,
        margin: 0,
        marginLeft: '5px',
        fontFamily: "Poppins",
        fontWeight: '400',
        lineHeight: '18px',
        letterSpacing: '-0.04px',
    },
    forgot: {
        [theme.breakpoints.up('xl')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
        },
        color: 'black',
        textDecoration: 'underline',
    },
    boxTitle: {
        textAlign: 'center',
        [theme.breakpoints.up('xl')]: {
            fontSize: 35
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 24
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 17
        },
        fontWeight: 'normal!important',
        padding: 0,
        margin: 0,
    },
    itemTitle: {
        margin: 0,
        [theme.breakpoints.up('xl')]: {
            fontSize: 20
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 14
        }
    },
    body: {
        [theme.breakpoints.up('xl')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6)
        },
        [theme.breakpoints.down('lg')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4)
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        },
        border: '1px solid #dbdbdb',
        shadow: '6px 6px 25px black',
        backgroundColor: 'white',
        borderRadius: 15,
        zIndex: '1',
    },
    div_indicator: {
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'fixed',
        paddingLeft: '50%',
        alignItems: 'center',
        marginTop: '-60px',
        zIndex: 999,
    },
    indicator: {
        color: 'gray'
    },
    error: {
        color: 'red',
        [theme.breakpoints.up('xl')]: {
            fontSize: 18
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 14
        },
    },
    button1: {
        [theme.breakpoints.up('xl')]: {
            padding: '15px 30px!important',
            borderRadius: '52px!important',
            fontSize: '20px!important'
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 21px!important',
            borderRadius: '36px!important',
            fontSize: '14px!important'
        },
        outline: '0px!important',
        background: '#21b830!important',
        color: 'white!important',
        textTransform: 'none!important',
        border: '1px solid!important',
    },
    button2: {
        [theme.breakpoints.up('xl')]: {
            padding: '15px 30px!important',
            borderRadius: '52px!important',
            fontSize: '20px!important'
        },
        [theme.breakpoints.down('lg')]: {
            padding: '10px 21px!important',
            borderRadius: '36px!important',
            fontSize: '14px!important'
        },
        outline: '0px!important',
        background: '#ff7a00!important',
        color: 'white!important',
        textTransform: 'none!important',
        border: '1px solid!important',
    },
    bottomContainer: {
        width: '100%',
        height: '15vh',
        background: '#0086D2',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        color: 'white'
    },
    pwdContainer: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        borderRadius: '6px',
        padding: '30px',
        background: 'white',
        flexDirection: 'column',
    },
    pwdInput: {
        padding: '5px',
        justifyContent: 'center',
    },
    pwdForgot: {
        [theme.breakpoints.up('xl')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: 14,
        },
        color: 'blue',
        textDecoration: 'underline',
        paddingRight: 4
    },
}));
export default useStyles;