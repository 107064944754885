import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import AdminService from '../../services/api.js';
import Link from '@material-ui/core/Link';
import useStyles from './useStyles';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import LucasLogo from '../../assets/images/virtualsally_logo.png';

import TopTapImage from '../../assets/images/top_tap.png';
import BottomTapImage from '../../assets/images/bottom_tap.png';

const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

const ForgotPassword = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [errorsEmail, setErrorsEmail] = useState('');

    document.onkeydown = function (e) {
        if (e.keyCode === 13) {
            if (document.getElementById('send')) {
                handleClickSend();
            }
        }
    }

    const handleChangeEmail = (event) => {
        event.preventDefault();
        let errorsMail =
            validEmailRegex.test(event.target.value)
                ? ''
                : Str.STR_EMAIL_INVALID;
        setEmail(event.target.value);
        setErrorsEmail(errorsMail);
    }

    const handleClickSend = () => {
        let cnt = 0;
        if (email.length === 0) { setErrorsEmail('please enter your email'); cnt++; }
        if (cnt === 0) {
            if (validateForm(errorsEmail)) {
                var data = {};
                data['email'] = email;
                data['type'] = Constant.RECEPTION;

                AdminService.forgotPassword(data)
                    .then(
                        response => {
                            if (response.data.code !== 200) {
                                toast.error(response.data.message);
                            } else {
                                toast.success(Str.STR_SEND_MESSAGE);
                                history.push('/success');
                            }
                        },
                        error => {
                            toast.error(Str.STR_NOT_CONNECT_SERVER);
                        }
                    );
            }
            else setErrorsEmail(Str.STR_EMAIL_INVALID);
        }
    }
    return (
        <div className='root'>
            <img className='lucas_logo' src={LucasLogo} alt="lucas_logo" />
            <img className='top_tap' src={TopTapImage} alt="top_tap" />
            <img className='bottom_tap' src={BottomTapImage} alt="bottom_tap" />
            <div className='center_container'>
                <div className='w-100 d-flex justify-content-center mb-3'>
                    <div className='col-10 col-md-7 col-xl-4 p-0 '>
                        <h3>Forgot your password</h3>
                        <h6>Please enter your email address. You will receive a link to create a new password by email.</h6>
                    </div>
                </div>
                <div className='w-100 d-flex justify-content-center mb-1'>
                    <TextField className='col-10 col-md-7 col-xl-4' label="email" error={!!errorsEmail} helperText={errorsEmail || ""}
                        value={email} onChange={handleChangeEmail} />
                </div>
                <div className='w-100 d-flex justify-content-center mb-1'>
                    <Button className="col-10 col-md-7 col-xl-4" variant="contained" style={{ backgroundColor: "#0085d2", color: "#FFFFFF" }}
                        onClick={handleClickSend} id="send">
                        Send
                    </Button>
                </div>
                <div className='w-100 d-flex justify-content-center mb-1'>
                    <div className='col-10 col-md-7 col-xl-4 p-0 text-right'>
                        <Link href="/login">
                            <small className={classes.forgot}>To log in</small>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ForgotPassword);