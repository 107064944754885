import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import { Button, Checkbox } from '@material-ui/core';
import AdminService from '../../services/api.js';
import Link from '@material-ui/core/Link';
import useStyles from './useStyles';
import Str from '../../constants/string';

const ResetPassword = (props) => {
    const classes = useStyles();
    const [currentPwd, setCurrentPwd] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [confirmedPwd, setConfirmedPwd] = useState('');
    const [isMakeMe, setIsMakeMe] = useState(false);
    const [errorsNewPwd, setErrorsNewPwd] = useState('');
    const [errorsConfirmedPwd, setErrorsConfirmedPwd] = useState('');

    const handleChangeCurrentPwd = (event) => {
        event.preventDefault();
        setCurrentPwd(event.target.value);
    }

    const handleChangeNewPwd = (event) => {
        event.preventDefault();
        setNewPwd(event.target.value);
        if (event.target.value.length < 8)
            setErrorsNewPwd("Please enter password more 8-charater");
        else
            setErrorsNewPwd("");
    }

    const handleChangeConfirmedPwd = (event) => {
        event.preventDefault();
        setConfirmedPwd(event.target.value);
        if (event.target.value !== newPwd)
            setErrorsConfirmedPwd("Please confirm your new password!");
        else
            setErrorsConfirmedPwd("");

    }

    const handleClickSave = () => {
        if (newPwd.length < 8) {
            setErrorsNewPwd("Please enter password more 8-charater");
            return;
        }
        else if (newPwd !== confirmedPwd) {
            setErrorsConfirmedPwd("Please confirm your new password!");
            return;
        }

        var data = {};
        data['username'] = props.username;
        data['old_password'] = currentPwd;
        data['new_password'] = newPwd;
        data['is_make'] = isMakeMe;

        AdminService.updatePassword(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                      toast.error(response.data.message);
                    } else {
                      toast.success("Password successfully changed. Please log-in with your new password.");
                    }
                },
                error => {
                  toast.error(Str.STR_NOT_CONNECT_SERVER);
                }
            );

        props.setIsShowResetPwd(false);
    }

    const handleClickCancel = () => {
        props.setIsShowResetPwd(false);
    }

    const handleMakeMe = () => {
        setIsMakeMe(prev => !prev)
    }

    return (
        <div className={classes.pwdContainer}>
            <Grid container direction="column" justify="flex-start" className={classes.root}>
                <Grid item container justify="center">
                    <Grid item>
                        <Grid item container justify="left">
                            <p className={classes.boxTitle}><b>Change your password</b></p>
                        </Grid>
                        <Grid item container justify="left">
                            <p className={classes.itemTitle}>A strong password helps prevent unauthorized access to your email account.</p>
                        </Grid>
                        <Grid item container className={classes.pwdInput}>
                            <Grid xs={12} item container direction="column">
                                <Grid item><p className={classes.itemTitle}>Current password</p></Grid>
                                <Grid item container direction='column'>
                                    <TextField
                                        className='drag_cancel'
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter your current password..."
                                        value={currentPwd}
                                        onChange={handleChangeCurrentPwd}
                                    />
                                </Grid>
                                <Grid item container>
                                    <Link href="/forgotpassword" variant="body2">
                                        <p className={classes.pwdForgot}>Forgot my password</p>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.pwdInput}>
                            <Grid xs={12} item container direction="column">
                                <Grid item><p className={classes.itemTitle}>New password</p></Grid>
                                <Grid item container direction='column'>
                                    <TextField
                                        className='drag_cancel'
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Enter your new password..."
                                        value={newPwd}
                                        onChange={handleChangeNewPwd}
                                    />
                                </Grid>
                                {errorsNewPwd.length > 0 &&
                                    <span className={classes.error}>{errorsNewPwd}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.pwdInput}>
                            <Grid xs={12} item container direction="column">
                                <Grid item><p className={classes.itemTitle}>Reenter password</p></Grid>
                                <Grid item container direction='column'>
                                    <TextField
                                        className='drag_cancel'
                                        type="password"
                                        variant="outlined"
                                        fullWidth
                                        placeholder="Confirm your new password..."
                                        value={confirmedPwd}
                                        onChange={handleChangeConfirmedPwd}
                                    />
                                </Grid>
                                {errorsConfirmedPwd.length > 0 &&
                                    <span className={classes.error}>{errorsConfirmedPwd}</span>}
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" style={{ alignItems: 'center', marginBottom: '15px' }}>
                            <Checkbox
                                className='drag_cancel'
                                checked={isMakeMe}
                                onChange={handleMakeMe}
                            />
                            <p className={classes.itemTitle}>Make me change my password every 72 days</p>
                        </Grid>
                    </Grid>
                    <Grid item container justify="center" style={{ gap: "20px" }}>
                        <Button className='ok_btn drag_cancel' onClick={handleClickSave} id='save'>
                            Save
                        </Button>
                        <Button className='cancel_btn drag_cancel' onClick={handleClickCancel} id='cancel'>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(ResetPassword);