import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from "react-redux";
import packageJson from '../package.json';

const useStyle = makeStyles(({palette,mode}) => ({
  version:{
    position:"absolute",
    bottom:0,
    left:0,
    margin:"5px",
    zIndex:"1",
    color:palette.color[mode]
  }
}))
const VersionComponent = (props) => {
  const classes = useStyle();
  return (
    <div className={classes.version}>
      <small>
      {packageJson.version || localStorage.lucas_therapies_version}w ({process.env.REACT_APP_COMMIT_HASH} {props.commitHash})
      </small>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    commitHash:state.apiCommitHash
  }
}

export default connect(mapStateToProps, {})(VersionComponent);