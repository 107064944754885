import React from "react";
import { useState, useRef, useContext, useEffect } from "react";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'; 
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import ButtonBase from "@material-ui/core/ButtonBase";
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import { Tooltip } from "@material-ui/core";
import {ThemeToggleContext} from '../../context/theme'
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BlurOnOutlinedIcon from '@material-ui/icons/BlurOnOutlined';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import redux from '../../redux/store';
import {setShowSecondaryLocations} from '../../redux/showSecondaryLocations';
import { useSelector } from 'react-redux';
import { Confirm } from "../Modal/ConfirmModal";
const useStyles = makeStyles((theme) => ({
  icon:{
      color:'white'
  },
  menuList:{
    backgroundColor:"#1C1D1E",
    color:"white",
    border: "1px solid rgb(100, 100, 100)",
    borderRadius:"5px",
  },
  menuItem:{
    paddingLeft: "12px",
    paddingRight: "12px",
    color:"white",
    '&:hover':{
      backgroundColor:"#404040"
    }
  },
  button:{
      padding:"5px",
      borderRadius:'5px',
      '&:hover':{
          backgroundColor:"#404040"
      },
  },
}));

const SecondarySwitch = withStyles({
  switchBase: {
    color: "#f3c200",
    '&$checked': {
      color: "#F4D03F",
    },
    '&$checked + $track': {
      backgroundColor: "#F4D03F",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default React.memo(function MoreActions (props){
    const classes = useStyles();
    const anchorRef = React.useRef(null);
    const [open, setOpen] = useState(false);
    const [showToggleSecondary, setShowToggleSecondary] = useState(false);
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    const {toggleMode, mode} = useContext(ThemeToggleContext)
    const confirmRef=useRef()
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
      prevOpen.current = open;
      setShowToggleSecondary(localStorage.getItem("showToggleSecondary"))
    }, [open]);

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event, index) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
    };
    const handleReload=()=>{
        window.location.reload()
        setOpen(false);
    }
    const logout=async()=>{
        setOpen(false);
        confirmRef.current.show('Logout',`Are you sure you want to log out? \n You will be returned to the login screen.`,
        [
          {
            close:true,
            text:"Yes",
            value:true,
            color:"secondary"

          },
          {
            close:true,
            text:"No",
            value:false,
            color:"secondary"

          }
        ]
      ).then(async (val)=>{
        if(!val)return 
        props.handleLogout()
      })
    }

    const handleToggleDarkMode=()=>{
        toggleMode()
        setOpen(false);
    }

    const toggleSecondary = () => {
      if(showSecondaryLocations) {
        localStorage.removeItem("showSecondaryLocations")
        redux.dispatch(setShowSecondaryLocations(false))
      } else {
        localStorage.setItem("showSecondaryLocations", "1")
        redux.dispatch(setShowSecondaryLocations(true))
      }
    }
    return (
        <div>
          <Confirm ref={confirmRef}/>
            <Tooltip title="More actions" placement="top">
                <ButtonBase  className={classes.button} onClick={handleToggle} aria-haspopup="true" ref={anchorRef}>
                    <MoreHorizOutlinedIcon className={classes.icon} fontSize="large"  />
                </ButtonBase>
            </Tooltip>
            <Popper open={open}  anchorEl={anchorRef.current} role={undefined} transition disablePortal placement='top-start'>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList className={classes.menuList}  autoFocusItem={open} id="menu-list-grow">
                            <MenuItem
                                className={classes.menuItem}
                                key={"Exit"}
                                onClick={ logout}>
                                <ExitToAppOutlinedIcon />
                                <span style={{paddingLeft:"1rem"}}>Log Out</span>
                            </MenuItem>

                            <MenuItem
                                className={classes.menuItem}
                                key={"Reload"}
                                onClick={handleReload}>
                                    <ReplayOutlinedIcon/>
                                    <span style={{paddingLeft:"1rem"}}>Reload</span>
                            </MenuItem>
                            <MenuItem
                                className={classes.menuItem}
                                key={(mode==='dark')?"Light":"Dark"}
                                onClick={handleToggleDarkMode}>
                                    <Brightness4Icon/>
                                    <span style={{paddingLeft:"1rem"}}>{(mode==='dark')?"Light Mode":"Dark Mode"}</span>
                            </MenuItem>
                            <MenuItem 
                              className={classes.menuItem} 
                              key="blur" 
                              onClick={props.toggleBlur}
                              title='Blur my camera background on / off'>
                                <BlurOnOutlinedIcon />
                                <span style={{paddingLeft:"1rem"}}>Blur background</span>
                            </MenuItem>
                            {showToggleSecondary && 
                              <MenuItem 
                                className={classes.menuItem} 
                                key="toggleSecondary" 
                                title='Show / hide secondary locations (alternates)'
                                onClick={toggleSecondary}
                                style={{paddingLeft: "6px"}}>
                                  <SecondarySwitch
                                    checked={showSecondaryLocations}
                                    size="small" 
                                  />
                                  <span style={{paddingLeft:"6px"}}>
                                    {showSecondaryLocations ? "Hide secondary" : "Show secondary"}
                                  </span>
                              </MenuItem>
                            } 
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
                    
        </div>
    );
})

