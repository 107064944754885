import React, { Component, createRef } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import Storages from './constants/storages';
import { connect } from "react-redux";
import { setLocations } from './redux/locations';
import apiService from './services/api'
import { SocketContext, socket } from './context/socket';
import { MyThemeProvider } from './context/theme';
import ErrorBoundary from './ErrorBoundary';
import constant from './constants/constant';
import * as $ from 'jquery';
import { Confirm } from "./Components/Modal/ConfirmModal"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { version } = require('../package.json');

const browserHistory = createBrowserHistory();

let reloadSnoozed = false;

class App extends Component {
  confirmRef = createRef();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    localStorage.setItem(Storages.LOCAL_APP_VERSION, version)
    this.socketListening()
    this.ping()    
  }

  
  ping(){
    var username;
    var prevLocations;
    var token
    setInterval(async()=>{
      try {
        token=localStorage.getItem(Storages.LOCAL_TOKEN_USER)
        if(!token) token=sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
        username=localStorage.getItem(Storages.LOCAL_LOGINED_USER);
        if(username && token){
          var result=await apiService.ping(username)
          var data=result.data && result.data.data
          if(data && data.user){
            let user=data.user
            if(user.type==constant.RECEPTION){
              // avoid rerendering if there is not any changes in locations
              var thisLoc=''
              for (let loc of data.locations){
                thisLoc+=loc.username+loc.isOffline+loc.isSleep+loc.isAway
              }
              if(prevLocations!=thisLoc){
                this.props.setLocations(data.locations)
                prevLocations=thisLoc
              }
            }
          }
        }

      } catch (error) {
        console.error(error)
      }
    },5000)
  }

  socketListening(){
    if(!socket) return
    socket.on('msg', ({from, event, msg})=>{
      if(event === 'toast'){
        if(msg.code == 0) toast.error(from + ': ' + msg.text, {autoClose: 5000})
        else if(msg.code < 0) toast.error(from + ': ' + msg.text, {autoClose: 10000})
        else toast.warning(from + ': ' + msg.text, {autoClose: 5000})
      }
    })
    socket.on('kick',()=>{ // emit from api
      localStorage.clear()
      sessionStorage.clear()
      // on clear data we need to relaunch the app for changes to take effect
      // call preload function sending relaunch to electron
      window.location.replace('/') // browser mode
    })
    socket.on('reloadRoom', (data) => {
      reloadSnoozed = false;
      this.reloadRooms(data.newRoom)
    })
  }

  reloadRooms(newRoom) {
    const isReloadLock = localStorage.getItem("reload_lock");
    if(isReloadLock) {
      setTimeout(() => {
        this.reloadRooms(newRoom)
      }, 3 * 1000);
    } else {
      toast.warning(
        <p className='mb-0 reloadRoomsWarning'>
          This page will reload in 15 seconds.&nbsp; 
          <a onClick={() => this.snoozeReloadRooms(newRoom)} href="javascript: void(0)">Snooze</a>
        </p>, {autoClose: 15 * 1000})

      setTimeout(() => {
        if(reloadSnoozed) {
          return
        }

        localStorage.setItem("roomID", newRoom);

        const oldRoomName = localStorage.getItem(Storages.LOCAL_ROOM_NAME);
        const newRoomName = oldRoomName.substring(0, oldRoomName.lastIndexOf("_")) + "_" + newRoom;
        localStorage.setItem(Storages.LOCAL_ROOM_NAME, newRoomName);
    
        window.location.reload()
      }, 15 * 1000);
    }
  }

  snoozeReloadRooms(newRoom) {
    $(".reloadRoomsWarning").parent().hide()
    reloadSnoozed = true;
    toast.success("Snoozed for 5 minutes.")
    setTimeout(() => {
      reloadSnoozed = false
      this.reloadRooms(newRoom)
    }, 5 * 60 * 1000);
  }

  render() {
    return (
      <ErrorBoundary>
        <MyThemeProvider>
          <SocketContext.Provider value={socket}>
            <Router history={browserHistory}>
              <Routes/>
            </Router>
            <ToastContainer newestOnTop={true} closeOnClick={false} draggable={false}/>
            <Confirm ref={this.confirmRef}/>
          </SocketContext.Provider>
        </MyThemeProvider>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = {
    setLocations,
};

export default connect(null, mapDispatchToProps)(App);
