import React, { useEffect } from 'react';
import ReactLoading from 'react-loading';
import LucasLogo from '../../assets/images/virtualsally_logo.png';
import VersionComponent from '../../Version'
import TopTapImage from '../../assets/images/top_tap.png';
import BottomTapImage from '../../assets/images/bottom_tap.png';
import AdminService from '../../services/api'

const Connection = (props) => {
  useEffect(()=>{
    checkHealth()
  },[])
  
  const checkHealth=async ()=>{
    try {
      var res=await AdminService.checkHealth()
      if(res.data.code!==0){
        setTimeout(checkHealth,3*1000)
      }
    } catch (error) {
      console.error(error)
      setTimeout(checkHealth,3*1000)
    }
  }
    const { msg } = props;

    return (
      <div className='root'>
        <img className='lucas_logo' src={LucasLogo} alt="lucas_logo"/>
        <img className='top_tap' src={TopTapImage} alt="top_tap" />
        <img className='bottom_tap' src={BottomTapImage} alt="bottom_tap" />
        <div className='center_container'>
          <div className='d-flex flex-column justify-content-center' style={{width:"65%"}}>
            <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} />
            <h3 className="mx-auto mt-4 text-center" >{msg}</h3>
          </div>
        </div>
        <VersionComponent/>
      </div>
    )
};

export default Connection;