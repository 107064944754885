import React, { useEffect, useCallback, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import LocalUserView from './LocalUserView';
import RemoteUserView from './RemoteUserView';
import { useSelector, useDispatch } from 'react-redux';
import { addMic, selectMic, deleteMic, deleteMics } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCamera, deleteCameras } from '../../redux/cameraSlice';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import { ButtonBase } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Messanger from '../Reception/Messanger';
import { useMemo } from 'react';
import { useContext } from 'react';
import { SocketContext } from '../../context/socket';
import { addMessage ,addUnReadMessage, setUnReadMessages} from '../../redux/messages';
import apiService from "../../services/api"
import { toast } from 'react-toastify';
import AudioMessage from '../../assets/sounds/tone.mp3'
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

const useStyle = makeStyles(({palette,mode}) => ({
    root: {
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0 1rem',
        backgroundColor:palette.background[mode],
        color:palette.color[mode]
    },
    close_area:{
        width:'100%',
        display:"flex",
        justifyContent: "space-between"
    },
    stage_area: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #00000042',


        
    },
    attendant_area: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        paddingTop:"10px",
        paddingBottom:"10px",
        overflowY:'auto'
    },
    video_area: {
        display: 'flex',
        width: '100%',
        height: '78%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    doctor_base_img: {
        maxWidth: '90%',
        maxHeight: '90%',
        display: 'block',
        border: '6px solid #007cbb'
    },
    main_video: {
        display: 'block',
        width: '100%',
        height: '200px',
        background: 'black',
        border: '1px solid black',
        borderRadius:"5px"
    },
    bottom_area: {
        display: 'flex',
        width: '100%',
        padding: '5px 0',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center'
    },
    info_area: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
    },
    feature_area: {
        display: 'flex',
        width: '30%'
    },
    user_name: {
        fontSize: 'max(1.14vw, 16px)',
        // fontFamily: 'Poppins',
    },
    time_area: {
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: '20px'
    },
    online_lab: {
        // fontFamily: 'poppins',
        fontSize: 'max(0.93vw, 14px)',
        marginRight: '5px'
    },
    title_area: {
        alignItems: 'center',
        display: 'flex',
        // height: '15.8%'
    },
    list_area: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop:"5px"
        // overflow: 'scroll',
    },
    attendant_lab: {
        // fontFamily: 'SemiBold',
        fontWeight: 'bold',
        fontSize: 'max(1.35vw, 18px)',
        margin: '0px'
    },
}))

const UserArea = React.memo((props) => {
    const { userName, userStatus, remoteUsers, joinState,handleToggleUserSideBar, localConnectionQuality } = props;
    const classes = useStyle();

    const dispatch = useDispatch();
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const [checkDevice, setCheckDevice] = useState(false);
    const [updateCameraDevice, setUpdateCameraDevice] = useState(false);
    const [updateMicDevice, setUpdateMicDevice] = useState(false);
    const [displayChatBox,setDisplayChatBox]=useState(false)
    const [connectionQualityColor, setConnectionQualityColor]=useState("")
    const [connectionQualityText, setConnectionQualityText]=useState("")
    const [chatBy,setChatBy]=useState('')
    const chatByRef=useRef(chatBy)
    const _setChatByRef=(data)=>{
        chatByRef.current=data
        setChatBy(data)
    }
    const socket = useContext(SocketContext)
    const [chatByFirstName,setChatByFirstName]=useState('')
    const MessangerElement=useMemo(()=>Messanger,[displayChatBox,chatBy,chatByFirstName])
    useEffect(() => {
        var audio=new Audio(AudioMessage)
        audio.load()
        // check devices timer
        let intervalDevices = setInterval(() => {
            setCheckDevice(prev => !prev);
        }, Constant.FIVE_SECONDS)
        socket.on('msg', async ({from,event, msg})=>{
            if(event==='chat'){
              try{
                var read_at=false
                if(from !==chatByRef.current) {
                    dispatch(addUnReadMessage({reception:from}))     
                    audio.play()
                }        
                else {
                    read_at=true
                    await apiService.readMessage({id:msg.id})
                }
                dispatch(addMessage({reception:from,message:{...msg,read_at}}))
              } catch (err) {
                console.error(err)
                toast.error(err.message, {autoClose: 10000})
              }
            }
        })
        return (() => {
            clearInterval(intervalDevices);
            socket.off('msg')
        })
    }, [])

    const handleCheckDevice = useCallback(() => {
        if (!joinState) {
            return;
        }
        const currentCameras = [...cameras];
        const currentMics = [...mics];

        if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
            window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);

                //add camera devices in redux when it is connected
                videoInputDevices.forEach((cell, i) => {
                    const index = currentCameras.findIndex((camera) => camera.deviceId === cell.deviceId)
                    if (index < 0) {
                        const item = { deviceId: cell.deviceId, label: cell.label, selected: false }
                        dispatch(addCamera(item));
                        if (currentCameras.length === 0 && i === 0) {
                            let cameraDeviceId = videoInputDevices[0].deviceId;
                            dispatch(selectCamera({ index: 0 }));

                            window.JitsiMeetJS.createLocalTracks({
                                devices: [Str.STR_VIDEO],
                                cameraDeviceId: cameraDeviceId
                            })
                            .then((tracks) => props.onLocalTracks(tracks))
                            .catch(error => {
                              console.error(1495, error)
                              props.setCamera(false)
                              toast.error(`There is an issue with video track. Please reload. 
                                If this issue persists, please contact customer support.
                              `)
                            });
                        }
                    }
                })

                //delect camera device in redux when it is disconnected
                let isSelectedCamera = true;
                if (videoInputDevices.length > 0) {
                    currentCameras.forEach((cell) => {
                        const index = videoInputDevices.findIndex((camera) => camera.deviceId === cell.deviceId)
                        if (index < 0) {
                            dispatch(deleteCamera(cell));
                            if (cell.selected) {
                                isSelectedCamera = false;
                            }
                        }
                    })
                } else {
                  props.setCamera(false)
                  toast.error(`No camera found. Please reload. 
                    If this issue persists, please contact customer support.
                  `)

                  if (currentCameras.length > 0) {
                    dispatch(deleteCameras());
                  }
                }

                if (!isSelectedCamera) {
                    setUpdateCameraDevice(prev => !prev);
                }

                audioInputDevices.forEach((cell, i) => {
                    if(cell.deviceId == "communications") {
                        return;
                    }
                    let label = cell.label;
                    if(label) {
                        if(label.startsWith("Microphone")) {
                            label = label.substring(11);
                        }
                        if(label.startsWith("Default - Microphone")) {
                            label = "Default - " + label.substring(20);
                        }
                        label = label.trim()
                        if(label.startsWith("(")) {
                            const indexOfClose = label.indexOf(")")
                            label = label.slice(1, indexOfClose) + label.slice(indexOfClose+1)
                        }
                    }

                    const index = currentMics.findIndex((mic) => mic.deviceId === cell.deviceId)
                    if (index < 0) {
                        cell.selected = false;
                        dispatch(addMic({...cell, label}));
                        if (currentMics.length === 0 && i === 0) {
                            let micDeviceId = audioInputDevices[0].deviceId;
                            dispatch(selectMic({ index: 0 }));

                            window.JitsiMeetJS.createLocalTracks({
                                devices: [Str.STR_AUDIO],
                                micDeviceId: micDeviceId
                            })
                            .then((tracks) => props.onLocalTracks(tracks))
                            .catch(error => {
                              console.error(1498, error)
                              props.setMic(false)
                              toast.error(`There is an issue with audio track. Please reload. 
                                If this issue persists, please contact customer support.
                              `)
                            });
                        }
                    }
                })


                let isSelectedMic = true;
                if (audioInputDevices.length > 0) {
                    currentMics.forEach((cell) => {
                        const index = audioInputDevices.findIndex((mic) => mic.deviceId === cell.deviceId)
                        if (index < 0) {
                            dispatch(deleteMic(cell));
                            if (cell.selected) {
                                isSelectedMic = false;
                            }
                        }
                    })
                } else {
                  props.setCamera(false)
                  toast.error(`No microphone found. Please reload. 
                    If this issue persists, please contact customer support.
                  `)

                  if (currentMics.length > 0) {
                    dispatch(deleteMics());
                  }
                }

                if (!isSelectedMic) {
                    setUpdateMicDevice(prev => !prev);
                }
            })
        }
    }, [checkDevice])

    useEffect(() => {
        handleCheckDevice();
    }, [checkDevice])


    const handleUpdateCameraDevice = useCallback(() => {
        if (cameras.length > 0 && joinState) {
            let cameraDeviceId = cameras[0].deviceId;
            dispatch(selectCamera({ index: 0 }));

            window.JitsiMeetJS.createLocalTracks({
                devices: [Str.STR_VIDEO],
                cameraDeviceId: cameraDeviceId
            })
            .then((tracks) => props.onLocalTracks(tracks))
            .catch(error => console.error(error));
        }
    }, [updateCameraDevice])

    useEffect(() => {
        handleUpdateCameraDevice();
    }, [updateCameraDevice])

    const handleUpdateMicDevice = useCallback(() => {
        if (mics.length > 0 && joinState) {
            let micDeviceId = mics[0].deviceId;
            dispatch(selectMic({ index: 0 }));

            window.JitsiMeetJS.createLocalTracks({
                devices: [Str.STR_AUDIO],
                micDeviceId: micDeviceId
            })
            .then((tracks) => props.onLocalTracks(tracks))
            .catch(error => console.error(error));
        }
    }, [updateMicDevice])

    useEffect(() => {
        handleUpdateMicDevice();
    }, [updateMicDevice])

    useEffect(() => {
      if(localConnectionQuality === 0) {
        setConnectionQualityColor("")
        setConnectionQualityText("Loading...")
      } else if (localConnectionQuality < 30) {
        setConnectionQualityColor("#D91E18")
        setConnectionQualityText("Very poor")
      } else if (localConnectionQuality < 60) {
        setConnectionQualityColor("#f3c200")
        setConnectionQualityText("Poor")
      } else if (localConnectionQuality < 90) {
        setConnectionQualityColor("#26C281")
        setConnectionQualityText("Good")
      } else {
        setConnectionQualityColor("#26C281")
        setConnectionQualityText("Excellent")
      }
    }, [localConnectionQuality])

    const handleCloseChat=()=>{
        setDisplayChatBox(false)
        _setChatByRef('')
    }
    const handleOpenChatBox=async(person)=>{
        _setChatByRef(person)
        setDisplayChatBox(true)
        dispatch(setUnReadMessages({reception:person,number:0}))
    }
    return (
        <div className={classes.root}>
            <div className={classes.close_area} >
                <span className='pl-0 py-1 d-flex' style={{color: connectionQualityColor}} 
                  title={`Connection quality ${localConnectionQuality}`}>
                  <SignalCellularAltIcon/>
                  {connectionQualityText}
                </span>
                <ButtonBase className='px-2 py-1'>
                  <CloseOutlinedIcon onClick={handleToggleUserSideBar}/>
                </ButtonBase>
            </div>
            <div className={classes.stage_area}>
                <video className={classes.main_video} autoPlay='1' id='mainRVideo' playsInline />
                <audio autoPlay='1' muted='1' id='mainRAudio' />
                <div className={classes.bottom_area}>
                    <div className={classes.info_area}>
                        <LocalUserView changeUserStatus={() => props.changeUserStatus()} user_name={userName} user_status={userStatus} />
                    </div>
                    {/* <div className={classes.feature_area}>
                        <DeviceControlArea
                            onClickCamera={() => props.handleLocalCamera()}
                            onClickMic={() => props.handleLocalMic()}
                            mic={mic}
                            camera={camera}
                        />
                    </div> */}
                </div>
            </div>
            <div className={classes.attendant_area}>
                <div className={classes.title_area}>
                    <label className={classes.attendant_lab}>Attendants</label>
                </div>
                <div className={classes.list_area}>
                {remoteUsers.map((remoteUser, index) => (
                        <RemoteUserView 
                          key={index} 
                          user_name={remoteUser.username} 
                          user_status={remoteUser.status} 
                          location_name={remoteUser.otherpartid} 
                          handleOpenChat={handleOpenChatBox}
                        />
                    ))}
                </div>
            </div>
            <MessangerElement display={displayChatBox} handleCloseChat={handleCloseChat} reception={chatBy}/>
        </div>
    )
});

export default UserArea;