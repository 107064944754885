
import React, { useEffect , useState, memo, useContext} from 'react';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { yellow, red } from '@material-ui/core/colors';
import Draggable from 'react-draggable';
import {SocketContext} from '../../context/socket'
import CloseIcon from '@material-ui/icons/Close';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import apiService from '../../services/api';
import {ThemeToggleContext} from '../../context/theme'
import { useSelector } from 'react-redux';
import {toast} from "react-toastify"

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#ffff00',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  },
}))(Button);

const CardDetectionRequest = memo(({allLocations, locationEngaged, open, handleClose})=>{
    const [showModal, setShowModal] = useState(false)
    const [location, setLocation] = useState("");
    const [cardDetectionLog, setCardDetectionLog] = useState("");
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const socket = useContext(SocketContext)
    const {mode}=useContext(ThemeToggleContext)

    useEffect(() => {
      socket.on('msg',({event, msg})=>{
        if(event === "card-detection-res") {
          setCardDetectionLog(msg.msg)
        }
      })

      return () => {
        socket.off("msg")
      }
    }, [])

    useEffect(() => {
      setCardDetectionLog("")
      setShowModal(open)
    }, [open])

    useEffect(() => {
      setLocation(locationEngaged)
    },[locationEngaged])

    function onLocationChanged(event) {
      setLocation(event.target.value);
    }

    async function submit() {
      try{
        if(!location) {
          toast.error(`'location' is required.`, {autoClose: 5000})
          return;
        }

        setCardDetectionLog("Please wait...")

        await apiService.sendMessage({
          to: location,
          event: "card-detection-on"
        })

        setCardDetectionLog("Card detection started. Waiting for location")
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    async function handleCancel() {
      apiService.sendMessage({
        to: location,
        event: "card-detection-off"
      }).catch((err) => {
        console.error(err);
      })

      setCardDetectionLog("")
      handleClose()
    }

    return (
      <Draggable cancel={'[class*="MuiDialogContent-root"]'} handle=".card-detection-drag-handle">
        <div style={{
          position: "absolute", 
          marginTop: "40vh",
          zIndex: 2, 
          backgroundClip: "padding-box",
          border: "1px solid rgba(0,0,0,.2)",
          borderRadius: ".3rem",
          backgroundColor: "#FFF",
          display: showModal ? "block" : "none",
          padding:0,
        }} className="col-8 col-md-6 col-xl-4">
          <DialogTitle className='card-detection-drag-handle' style={{
            cursor:"move",
            width:"100%",
            backgroundColor:(mode==='dark')?'#303030':'#fafafa',
            color:(mode==='dark')?'#fff':'rgba(0, 0, 0, 0.87)'}}
            disableTypography={true}>
            <h5 style={{color:(mode==='dark')?'#fff':'rgba(0, 0, 0, 0.87)'}}>Card Detection</h5>
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" className='mt-1'>
              <InputLabel id="location-label">To Location</InputLabel>
              <Select
                labelId="location-label"
                onChange={onLocationChanged}
                labelWidth={80}
                value={location}
              >
                <MenuItem value="">Please choose ...</MenuItem>
                {allLocations.map((item, index) => {
                  const isSec = !item.isPrimary;
                  if(isSec && !showSecondaryLocations) return;
                  return (
                    <MenuItem key={index} value={item.username}>
                      {item.locationname}
                      {isSec && 
                        <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                      }
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <div style={{marginTop: "10px", marginBottom: "5px"}}>{cardDetectionLog}</div>
          </DialogContent>
          <DialogActions className='px-4 pb-4'>
            <RedButton className="dialog_btn" variant="contained" color="secondary" 
              startIcon={<CloseIcon />} onClick={handleCancel}>Cancel</RedButton>
            <YellowButton className="dialog_btn" variant="contained" color="secondary" 
              startIcon={<PageviewOutlinedIcon />} onClick={submit}>Start</YellowButton>
          </DialogActions>
        </div>
      </Draggable>
    )
})
export default CardDetectionRequest