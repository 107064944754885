const userColor = {
    NON_USER: "#007cbb",
    LOCAL_USER: "white",
    PINK_COLOR: "#ff00ff",
    BROWN_COLOR: "#b82121",
    YELLOW_COLOR: "#ff7a01",
    GREEN_COLOR: "#00ff00",
    RED_COLOR: "#e00000",
    BLUE_COLOR: "#2130b8",
    GREEN_BTN_COLOR: "#21b830",
    YELLOW_BTN_COLOR: "#ff7a00",
    PURPLE_BTN_COLOR: "#2130b8",
    GRAY_BTN_COLOR: "#a1a1a1",
}

module.exports = userColor