import React, { useRef, useContext, useEffect ,useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import CallEndOutlinedIcon from '@material-ui/icons/CallEndOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ScannerOutlinedIcon from '@material-ui/icons/ScannerOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import PhonePausedOutlinedIcon from '@material-ui/icons/PhonePausedOutlined';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import MenuOpenOutlinedIcon from '@material-ui/icons/MenuOpenOutlined';
import { addMic, selectMic, deleteMic, deleteMics } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCamera, deleteCameras } from '../../redux/cameraSlice';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import MenuListComposition from '../Controls/MenuListComposition';
import MoreActions from './MoreActions'
import LocationVolume from './LocationVolume';
import { Tooltip } from "@material-ui/core";
import FilterOutlinedIcon from '@material-ui/icons/FilterOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import ScreenShareOutlinedIcon from '@material-ui/icons/ScreenShareOutlined';
import { SocketContext } from "../../context/socket";
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
const useStyles = makeStyles(({palette,mode})=>{
    return({
    main:{
        backgroundColor:"#1C1D1E",
        position:"absolute",
        bottom:0,
        borderRadius:"10px",
        padding:"5px",
        marginBottom:"1rem"

    },
    button:{
        padding:"5px",
        borderRadius:'5px',
        '&:hover':{
            backgroundColor:"#404040"
        },
    },
    btnCall:{
        backgroundColor:"#0000FF",
        padding:"5px",
        borderRadius:'5px',
        '&:hover':{
            backgroundColor:"#404040"
        },
    },
    icon:{
        color:"white"
    },
    gridDevices:{
        display: 'flex',
        position: 'relative',
    },
    btnDevices: {
        padding:"5px",
        borderRadius:'5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            backgroundColor:"#404040"
        }
    },
    optionDevices: {
        display: 'flex',
        position: 'absolute',
        right: '-5px',
        top: '-5px',
        borderRadius:'5px',
        color:"white",
        '&:hover': {
            cursor: 'pointer',
            backgroundColor:"white",
            color:"#1C1D1E"
        }
    },
})
});

const audioContext = new AudioContext();

export default function ToolBox ({callState, handleCall,locationEngaged,allLocations, handlePlay,
    playState,handleOpenModalPayReq,handleRequestForScan,handleModalPrintFile,joinState,onLocalTracks,
    handleLocalCamera, handleLocalMic, micro, camera,handleOpenGallery, startCardDetection,
    handleToggleUserSideBar, openUserSideBar, toggleShareScreen, toggleBlur , locationVolume,handleLogout,handleExitFullScreenEngaged
}){
    const classes = useStyles();
    const dispatch = useDispatch();
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const [showPayButton, setShowPayButton] = useState(true)
    const [showCardDetection, setShowCardDetection] = useState(true)
    const [checkDevice, setCheckDevice] = useState(false);
    const [updateCameraDevice, setUpdateCameraDevice] = useState(false);
    const [updateMicDevice, setUpdateMicDevice] = useState(false);
    const [audioDevicesMenu, setAudioDevicesMenu] = useState([]); // {selected, label}
    const micNodes = useRef([]);
    const socket = useContext(SocketContext);

    useEffect(() => {
        // check devices timer
        let intervalDevices = setInterval(() => {
            setCheckDevice(prev => !prev);
        }, Constant.FIVE_SECONDS)

        window.requestAnimationFrame(checkAudioLevelForAllMicNodes)
        return (() => {
            clearInterval(intervalDevices);
        })
    }, [])

    useEffect(() => {
      getAllMicsAnalyserNodes()
    }, [mics])

    const getAllMicsAnalyserNodes = async () => {
      try{
        const tmpMiceNodes = [];
        for(let mic of mics) {  
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: {
                exact: mic.deviceId,
              },
            },
          });

          const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
          const analyserNode = audioContext.createAnalyser();
          mediaStreamAudioSourceNode.connect(analyserNode);
          
          const array = new Uint8Array(analyserNode.frequencyBinCount);

          tmpMiceNodes.push({analyserNode, pcmData: array, mic})
        }
        micNodes.current = tmpMiceNodes;
      } catch(err) {
        console.error(err)
      }
    }

    const checkAudioLevelForAllMicNodes = async () => {
      try{
        let tmpAudioDevicesMenu = [];
        for(let micNode of micNodes.current) { 
          micNode.analyserNode.getByteTimeDomainData(micNode.pcmData);
          let level = timeDomainDataToAudioLevel(micNode.pcmData)

          let label = micNode.mic.label
          if(label.length > 30) {
            label = label.substr(0, 30) + "..."
          }
          tmpAudioDevicesMenu.push({label, selected: micNode.mic.selected, bar: level*100})
        }
        setAudioDevicesMenu(tmpAudioDevicesMenu)

        window.requestAnimationFrame(checkAudioLevelForAllMicNodes);
      } catch (err) {
        console.error(err)
      }
    }

    const timeDomainDataToAudioLevel = (samples) => {
      let maxVolume = 0;
      const length = samples.length;
      for (let i = 0; i < length; i++) {
        if (maxVolume < samples[i]) {
            maxVolume = samples[i];
        }
      }
      return parseFloat(((maxVolume - 127) / 128).toFixed(3));
    }

    const handleCheckDevice = useCallback(() => {
        if (!joinState) {
            return;
        }
        const currentCameras = [...cameras];
        const currentMics = [...mics];

        if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
            window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);

                //add camera devices in redux when it is connected
                videoInputDevices.forEach((cell, i) => {
                    const index = currentCameras.findIndex((camera) => camera.deviceId === cell.deviceId)
                    if (index < 0) {
                        const item = { deviceId: cell.deviceId, label: cell.label, selected: false }
                        dispatch(addCamera(item));
                        if (currentCameras.length === 0 && i === 0) {
                            let cameraDeviceId = videoInputDevices[0].deviceId;
                            dispatch(selectCamera({ index: 0 }));

                            window.JitsiMeetJS.createLocalTracks({
                                devices: [Str.STR_VIDEO],
                                cameraDeviceId: cameraDeviceId
                            })
                                .then((tracks) => onLocalTracks(tracks))
                                .catch(error => console.error(error));
                        }
                    }
                })

                //delect camera device in redux when it is disconnected
                let isSelectedCamera = true;
                if (videoInputDevices.length > 0) {
                    currentCameras.forEach((cell) => {
                        const index = videoInputDevices.findIndex((camera) => camera.deviceId === cell.deviceId)
                        if (index < 0) {
                            dispatch(deleteCamera(cell));
                            if (cell.selected) {
                                isSelectedCamera = false;
                            }
                        }
                    })
                }
                else {
                    if (currentCameras.length > 0)
                        dispatch(deleteCameras());
                }

                if (!isSelectedCamera) {
                    setUpdateCameraDevice(prev => !prev);
                }

                audioInputDevices.forEach((cell, i) => {
                    const index = currentMics.findIndex((mic) => mic.label === cell.label)
                    if (index < 0) {
                        cell.selected = false;
                        dispatch(addMic(cell));
                        if (currentMics.length === 0 && i === 0) {
                            let micDeviceId = audioInputDevices[0].deviceId;
                            dispatch(selectMic({ index: 0 }));

                            window.JitsiMeetJS.createLocalTracks({
                                devices: [Str.STR_AUDIO],
                                micDeviceId: micDeviceId
                            })
                                .then((tracks) =>onLocalTracks(tracks))
                                .catch(error => console.error(error));
                        }
                    }
                })


                let isSelectedMic = true;
                if (audioInputDevices.length > 0) {
                    currentMics.forEach((cell) => {
                        const index = audioInputDevices.findIndex((mic) => mic.label === cell.label)
                        if (index < 0) {
                            dispatch(deleteMic(cell));
                            if (cell.selected) {
                                isSelectedMic = false;
                            }
                        }
                    })
                }
                else {
                    if (currentMics.length > 0)
                        dispatch(deleteMics());
                }

                if (!isSelectedMic) {
                    setUpdateMicDevice(prev => !prev);
                }
            })
        }
    }, [checkDevice])

    useEffect(() => {
        // handleCheckDevice();
    }, [checkDevice])


    const handleUpdateCameraDevice = useCallback(() => {
        if (cameras.length > 0 && joinState) {
            let cameraDeviceId = cameras[0].deviceId;
            dispatch(selectCamera({ index: 0 }));

            window.JitsiMeetJS.createLocalTracks({
                devices: [Str.STR_VIDEO],
                cameraDeviceId: cameraDeviceId
            })
                .then((tracks) => onLocalTracks(tracks))
                .catch(error => console.error(error));
        }
    }, [updateCameraDevice])

    useEffect(() => {
        // handleUpdateCameraDevice();
    }, [updateCameraDevice])

    const handleUpdateMicDevice = useCallback(() => {
        if (mics.length > 0 && joinState) {
            let micDeviceId = mics[0].deviceId;
            dispatch(selectMic({ index: 0 }));

            window.JitsiMeetJS.createLocalTracks({
                devices: [Str.STR_AUDIO],
                micDeviceId: micDeviceId
            })
                .then((tracks) =>onLocalTracks(tracks))
                .catch(error => console.error(error));
        }
    }, [updateMicDevice])

    useEffect(() => {
        // handleUpdateMicDevice();
    }, [updateMicDevice])

    useEffect(() => {
        if (!locationEngaged) {
            setShowPayButton(false)
        } else {
            const engagedObj = allLocations.find((item) => item.username === locationEngaged);
            if (engagedObj && engagedObj.deviceId) {
              setShowPayButton(true)
            } else {
              setShowPayButton(false)
            }
            if(engagedObj?.cardDetectionEnabled) {
              setShowCardDetection(true)
            } else {
              setShowCardDetection(false)
            }
        }
    }, [locationEngaged])

    const onClickCameraSelector = (index) => {
        dispatch(selectCamera({ index }));
    }
    const onClickMicSelector = (index) => {
        dispatch(selectMic({ index }));
    }


    return (
        <div className={classes.main}>
            <Grid color='secondary' container spacing={1} direction='row-reverse' justifyContent="center" alignItems="center">
                {callState?
                    <>
                    <Grid item >
                        <Tooltip title='End Call'>
                            <ButtonBase className={classes.btnCall}  onClick={handleCall}>
                                <CallEndOutlinedIcon className={classes.icon}  fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <LocationVolume locationEngaged={locationEngaged} locationVolume={locationVolume} />
                    </Grid>
                    <Grid item >
                        <Tooltip title={playState?"Hold Call":"Release Call"}>
                            <ButtonBase className={classes.button}  onClick={handlePlay}>
                                {
                                    playState?
                                    <PhonePausedOutlinedIcon className={classes.icon}  fontSize="large" />
                                    :
                                    <PhoneInTalkOutlinedIcon className={classes.icon}  fontSize="large" />
                                }
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    </>
                    :
                    locationEngaged &&
                    <>
                        <Grid item >
                            <Tooltip title='Call'>
                                <ButtonBase className={classes.btnCall} onClick={handleCall}>
                                    <CallOutlinedIcon className={classes.icon}  fontSize="large" />
                                </ButtonBase>
                            </Tooltip>
                        </Grid>
                        <Grid item >
                            <Tooltip title='Exit Full Screen'>
                                <ButtonBase className={classes.button} onClick={handleExitFullScreenEngaged}>
                                    <FullscreenExitIcon className={classes.icon}  fontSize="large" />
                                </ButtonBase>
                            </Tooltip>
                        </Grid>
                    </>
                }
                <Grid item>
                    <MoreActions toggleBlur={toggleBlur}  handleLogout={handleLogout}/>
                </Grid>
                {/* <Grid item>
                    <ButtonBase className={classes.button}>
                        <AppsOutlinedIcon className={classes.icon}  fontSize="large"/>
                    </ButtonBase>
                </Grid> */}
                <Grid item >
                    <Tooltip title='Gallery'>
                        <ButtonBase className={classes.button} onClick={handleOpenGallery}>
                            <FilterOutlinedIcon className={classes.icon} fontSize="large" />
                        </ButtonBase>
                    </Tooltip>
                </Grid>
                <Grid item >
                    <Tooltip title='Chat'>
                        <ButtonBase className={classes.button} onClick={handleToggleUserSideBar}>
                            <MenuOpenOutlinedIcon className={classes.icon} fontSize="large"
                            style={openUserSideBar ? {}: {transform: "rotateY(180deg)"}} />
                        </ButtonBase>
                    </Tooltip>
                </Grid>
                {showPayButton &&  callState &&
                    <Grid item>
                        <Tooltip title='Payment'>
                            <ButtonBase className={classes.button} onClick={handleOpenModalPayReq}>
                                <PaymentOutlinedIcon className={classes.icon} fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                }
                {locationEngaged && callState &&
                    <Grid item>
                        <Tooltip title='Print'>
                            <ButtonBase className={classes.button} onClick={handleModalPrintFile}>
                                <PrintOutlinedIcon className={classes.icon} fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                }
                {locationEngaged && callState &&
                <Grid item>
                    <Tooltip title='Scanner'>
                        <ButtonBase className={classes.button} onClick={handleRequestForScan}>
                            <ScannerOutlinedIcon className={classes.icon} fontSize="large" />
                        </ButtonBase>
                    </Tooltip>
                </Grid>
                }
                {locationEngaged && showCardDetection && 
                <Grid item>
                  <Tooltip title='Card Detection'>
                    <ButtonBase className={classes.button} onClick={startCardDetection}>
                      <PageviewOutlinedIcon className={classes.icon} fontSize="large" />
                    </ButtonBase>
                  </Tooltip>
                </Grid>
                }
                {locationEngaged && 
                    <Grid item>
                        <Tooltip title='Start / Stop location share screen'>
                            <ButtonBase className={classes.button} onClick={toggleShareScreen}>
                                <ScreenShareOutlinedIcon className={classes.icon} fontSize="large" />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                }
                <Grid item  className={classes.gridDevices}>
                    <Tooltip title='Start / Stop camera'>
                        <ButtonBase className={classes.btnDevices} onClick={handleLocalCamera}>
                            {camera?<VideocamOutlinedIcon className={classes.icon} fontSize="large"/>
                                :<VideocamOffOutlinedIcon className={classes.icon} fontSize="large"/>
                            }
                        </ButtonBase>
                    </Tooltip>
                    <MenuListComposition onClickItemSelector={(index) => onClickCameraSelector(index)} menus={cameras} title='Manage camera devices'/>
                    {/* <ButtonBase className={classes.optionDevices}>
                        <ArrowDropUpOutlinedIcon/>
                    </ButtonBase> */}
                </Grid>
                <Grid item  className={classes.gridDevices}>
                    <Tooltip title='Mute /Unmute'>
                        <ButtonBase className={classes.btnDevices} onClick={handleLocalMic}>
                            {micro? <MicNoneOutlinedIcon className={classes.icon} fontSize="large"/>
                            :<MicOffOutlinedIcon className={classes.icon} fontSize="large" /> }
                        </ButtonBase>
                    </Tooltip>
                    <MenuListComposition onClickItemSelector={(index) => onClickMicSelector(index)} 
                      menus={audioDevicesMenu} title='Manage audio devices'/>
                </Grid>
            </Grid>
        </div>
    );
}

