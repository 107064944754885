import socketio from "socket.io-client";
import React from 'react';
import Storages from "../constants/storages";
import * as url from "url"
var token = localStorage.getItem(Storages.LOCAL_TOKEN_USER);
if(!token)token= sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
var options={ 
  auth:{}
}
var myURL=url.parse(process.env.REACT_APP_SOCKET_URL)
if(myURL.pathname) options.path=myURL.pathname
if(token) options.auth.token=token
var urlAddress=`${myURL.protocol}//${myURL.hostname}`
if(myURL.port) urlAddress+=':'+myURL.port

export const socket = socketio.io(urlAddress,options);

socket.on("connect", () => {
  // revert to classic upgrade
  console.info("Socket connected")
});
socket.on("error", (err) => {
  // revert to classic upgrade
  console.error(err)
});
socket.on("disconnect", () => {
  // revert to classic upgrade
  console.info("Socket disconnected")
});

function auth() {
  token = localStorage.getItem(Storages.LOCAL_TOKEN_USER)
  if(!token) token = sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
  if(token) {
    socket.auth.token=token
    socket.disconnect().connect()
  }
}
function reloadLocation(username) {
  let token = localStorage.getItem(Storages.LOCAL_TOKEN_USER)
  if(!token) token = sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
  if(token) {
    socket.emit("reload", {username})
  }
}

function scanningRequest(from, to, paperSource,batchID){
  let token = localStorage.getItem(Storages.LOCAL_TOKEN_USER)
  if(!token) token = sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
  if(token) {
    socket.emit("request-for-scan", {from, to, paperSource,batchID})
  }
}
export const authenticate = auth
export const sendReloadLocationToSocket=reloadLocation
export const sendRequestForScanToSocket=scanningRequest
export const SocketContext = React.createContext();

