import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Draggable from 'react-draggable';
import useStyles from './useStyles';
import { Button, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import AdminService from '../../services/api';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import Storages from '../../constants/storages';
import ResetPassword from './ResetPassword';
import { AlertModal } from '../../Components/Modal/AlertModal';
import LucasLogo from '../../assets/images/virtualsally_logo.png';
import TopTapImage from '../../assets/images/top_tap.png';
import BottomTapImage from '../../assets/images/bottom_tap.png';
import { authenticate } from '../../context/socket';
import VersionComponent from '../../Version'
import { useSelector } from 'react-redux';
import Connection from '../../Components/User/Connection';
import ReactLoading from 'react-loading';
import './style.css';
import { Confirm } from '../../Components/Modal/ConfirmModal';
const Login = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorsUsername, setErrorsUsername] = useState('');
  const [errorsPassword, setErrorsPassword] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const [clickedLogin, setClickedLogin] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const connections = useSelector((state) => state.connections)
  const [isShowResetPwd, setIsShowResetPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const nodeRef = useRef(null);
  const confirmRef=useRef()
  useEffect(() => {
    //get information from localStorage.
    let started, remember;
    let userId, userPwd;

    userId = localStorage.getItem(Storages.LOCAL_USER_NAME);
    setUsername(userId);

    userPwd = localStorage.getItem(Storages.LOCAL_PWD);
    setPassword(userPwd);

    remember = localStorage.getItem(Storages.LOCAL_IS_REMEMBER) === 'true' ? true : false;
    setIsRemember(remember);

    started = sessionStorage.getItem(Storages.SESSION_IS_STARTED) === 'true' ? true : false;
    if (started === false) {
      sessionStorage.setItem(Storages.SESSION_IS_STARTED, true);
      // clickLogin(userId, userPwd, remember);
    }

    checkConnection();
  }, []);

  useEffect(() => {
    if(connections.internet && connections.server) {
      checkConnection();
    }
  }, [connections])

  const checkConnection = async () => {
    setIsLoading(true)
    try {
      var res = await AdminService.checkHealth()
      if(res.data.code === 0){        
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  document.onkeydown = function (e) {
    if (e.keyCode === 13) {
      if (document.getElementById('login')) {
        handleClickButton();
      }
    }
  }

  const changeName = (event) => {
    event.preventDefault();
    setUsername(event.target.value);
    if (event.target.value.length > 0) {
      setErrorsUsername('');
    }
  }

  const changePassword = (event) => {
    event.preventDefault();
    setPassword(event.target.value);
    if (event.target.value.length > 0) {
      setErrorsPassword('');
    }
  }

  const handleRememberMe = (event) => {
    setIsRemember(event.target.checked);
    localStorage.setItem(Storages.LOCAL_IS_REMEMBER, event.target.checked);
  }

  const handleClickButton = () => {
    let cnt = 0;
    if (clickedLogin) { cnt++ }
    if (isShowResetPwd) { cnt++ }
    if (username.length === 0) { setErrorsUsername(Str.STR_INPUT_USERNAME); cnt++; }
    if (password.length === 0) { setErrorsPassword(Str.STR_INPUT_PASSWORD); cnt++; }
    if (cnt === 0) {
      clickLogin(username, password, isRemember);
    }
  }

  const clickLogin = async (userId, userPwd, remember) => {
    try{
      if (remember === true) {
        localStorage.setItem(Storages.LOCAL_USER_NAME, userId);
        localStorage.setItem(Storages.LOCAL_PWD, userPwd);
      }
      else {
        localStorage.setItem(Storages.LOCAL_USER_NAME, "");
        localStorage.setItem(Storages.LOCAL_PWD, "");
      }

      var data = {};
      data['username'] = userId;
      data['password'] = userPwd;
      setClickedLogin(true);

      const response = await AdminService.login(data)
      if (response.data.code !== 200) {
        if (response.data.code === 601){
            confirmRef.current.show('Login',response.data.message+' If you login, another user will be logged out.',
            [
              {
                close:true,
                color:"secondary",
                text:"Ok",
                value:true
              },
              {
                close:true,
                color:"secondary",
                text:"Cancel",
                value:false
              }
            ]
          ).then(async(val)=>{
            if(!val) return
            const res = await AdminService.login({...data, force:true})
            if (res.data.code !== 200) {
              toast.error(res.data.message);
            } else {
              successLogin(res)
            }
          })
        }else {
          toast.error(response.data.message);
        }
      } else {
        successLogin(response)
      }
      setClickedLogin(false);
    } catch (err) {
      setClickedLogin(false);
      toast.error(Str.STR_SERVER_ACCESS_ERROR);
    }
  }

  const successLogin=(response)=>{
    var token=response.data.data.token
    if(!token) return setAlertContent("Something is wrong, Please try again.")
    if(isRemember === true) localStorage.setItem(Storages.LOCAL_TOKEN_USER,token)
    else sessionStorage.setItem(Storages.LOCAL_TOKEN_USER,token)
    authenticate();
    let data = response.data.data.data;
    let logo, title;
    if (data.title === "" || data.title === null) {
      title = Str.STR_APP_TITLE;
    }
    else {
      title = data.title;
    }

    if (data.roomname) {
      
      data.roomname = data.roomname.toLowerCase() + `_${data.groupnum}`;
      localStorage.setItem(Storages.LOCAL_ROOM_NAME, data.roomname);
      localStorage.setItem(Storages.LOCAL_LOGINED_USER, data.username);
      // localStorage.setItem(Storages.LOCAL_LOCATION_NAME, data.locationname);
      localStorage.setItem(Storages.LOCAL_COMPANY_NAME, data.company);
      localStorage.setItem(Storages.LOCAL_CLIENT_ID, data.clientid);
      localStorage.setItem("groupnum", data.groupnum);
      localStorage.setItem("showToggleSecondary", data.showToggleSecondary);

      if (data.type === Constant.RECEPTION) {
        localStorage.setItem("logo", data.logor)
        history.push('/' + data.username);
      }
    } else {
      if (data.pwdstatus === null || data.pwdstatus === Constant.LOGIN_FIRST)
        setAlertContent("You are required to change your password at first login.")
      else
        setAlertContent("You are required to change your password as it was expired in 72 days.")

      setShowAlertModal(true);
    }
  }

  const closeAlertModal = () => {
    setShowAlertModal(false);
    setIsShowResetPwd(true);
  }

  return (
    <div className="root">
      <Confirm ref={confirmRef}/>
      {
        !connections.internet ?
          <Connection msg="It seems like there is no internet connection. Retrying…"/>
        : !connections.server ?
          <Connection msg="We are currently upgrading and updating our service. 
            We apologize for any inconvenience this may cause. 
            Please be patient while we apply these necessary changes, 
            your system will be back up shortly."/>
        : isLoading ? <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} />
        :
        <>
          <img className='lucas_logo' src={LucasLogo} alt="lucas_logo"/>
          <img className='top_tap' src={TopTapImage} alt="top_tap" />
          <img className='bottom_tap' src={BottomTapImage} alt="bottom_tap" />
          <div className='center_container'>
            <div className='w-100 d-flex justify-content-center mb-3'>
              <div className='col-10 col-md-7 col-xl-4 p-0 '>
                <h3>Welcome to Virtual Sally!</h3>
                <h6>Please login</h6>
              </div>
            </div>
            <div className='w-100 d-flex justify-content-center mb-3'>
              <TextField className='col-10 col-md-7 col-xl-4' label="Username or Email Address" error={!!errorsUsername} helperText={errorsUsername || ""}
                value={username} onChange={changeName} />
            </div>
            <div className='w-100 d-flex justify-content-center mb-3'>
              <TextField className='col-10 col-md-7 col-xl-4' label="Password" error={!!errorsPassword} helperText={errorsPassword || ""}
                value={password} type='password' onChange={changePassword} />
            </div>
            <div className='w-100 d-flex justify-content-center mb-1'>
              <div className='col-10 col-md-7 col-xl-4 p-0'>
                <Link href="/forgotpassword">
                  <small className={classes.forgot}>Forgot password?</small>
                </Link>
              </div>
            </div>
            <div className='w-100 d-flex justify-content-center'>
              <div className='col-10 col-md-7 col-xl-4 p-0'>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={isRemember} onChange={handleRememberMe} />}
                    label="Remember me"
                  />
                </FormGroup>
              </div>
            </div>
            <div className='w-100 d-flex justify-content-center mb-1'>
              <Button className="col-10 col-md-7 col-xl-4" variant="contained" style={{ backgroundColor: "#0085d2", color: "#FFFFFF" }}
                onClick={handleClickButton} id="login">
                Log in
              </Button>
            </div>
          </div>
          <Draggable bounds="parent" nodeRef={nodeRef} cancel=".drag_cancel" autoFocus >
            <div ref={nodeRef} className={isShowResetPwd ? "box draggable-pwd-background show" : "box draggable-pwd-background hide"} style={{ position: 'absolute', top: '20px', right: '20px' }}>
              <ResetPassword username={username} setIsShowResetPwd={setIsShowResetPwd} />
            </div>
          </Draggable>
          <AlertModal
            showModal={showAlertModal}
            handleClose={closeAlertModal}
            title={"Alert"}
            content={alertContent}
          />
          <VersionComponent/>
        </>
      }
    </div>
  );
};
export default withRouter(Login);
