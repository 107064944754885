
import React, { useEffect, useState, memo } from 'react';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import apiService from '../../services/api';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import { yellow, red } from '@material-ui/core/colors';
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import Storages from '../../constants/storages';
import axios from 'axios';
import { toast } from "react-toastify"

const API_URL = process.env.REACT_APP_API_URL;

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#ffff00',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  },
}))(Button);

const useStyle = makeStyles(({palette,mode}) => ({
  dialogTitle: {
    cursor:"move",
    backgroundColor:palette.background[mode],
    color:palette.color[mode],
    '& .MuiTypography-h6':{
      color:palette.color[mode],
    }
  }
}))

const PrintFileRequestModal = memo(({
  allLocations, 
  locationEngaged, 
  externalShowModal, 
  externalClose
})=>{
  const [showModal, setShowModal] = useState(false)
  const [chosenFile, setChosenFile] = useState("")
  const [location, setLocation] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [file, setFile] = useState("")
  const [pdfObjectUrl, setPdfObjectUrl] = useState("")
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const classes=useStyle()
  const [shareFiles, setShareFiles]=useState(null)
  useEffect(() => {
    setShowModal(externalShowModal)
  }, [externalShowModal])

  useEffect(() => {
    if (showModal) {
      setDisableInputs(false);
      getFilesList()
    }
  }, [showModal])

  useEffect(() => {
    setLocation(locationEngaged)
  }, [locationEngaged])


  function onLocationChanged(event) {
    setLocation(event.target.value);
  }

  const getFilesList=async()=>{
    var res = await apiService.checkSharedFiles();
    res = res.data
    if(res.code === 0) {
      res=res.data
      setShareFiles(res.newArr)
    }
    else {
      console.error("4120", res.msg)
      toast.error(res.message, {autoClose: 5000})
      setShareFiles([])
    }
  }

  function onChosenFileChange(event) {
    setChosenFile(event.target.value);
  }

  function close() {
    setShowModal(false)
    externalClose();
  }

  async function submit() {
    if (!location || !chosenFile) {
      toast.error(`'location' and 'file' are required.`, {autoClose: 5000})
      return;
    }
    else if(chosenFile==='upload' && !file) {
      toast.error(`'location' and 'file' are required.`, {autoClose: 5000})
      return;
    }
    setDisableInputs(true);
    try {
      if(chosenFile==='upload'){
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = async (e) => {
          try {
            var data=e.target.result
            await sendFile(data)
          } catch (err) {
            console.error(err);
            toast.error(`Error while printing file: ${err.message}`, {autoClose: 5000})
          } finally {
            setDisableInputs(false)
          }
        };
      }else {
        let token = sessionStorage.getItem(Storages.LOCAL_TOKEN_USER)
        if(!token) token= localStorage.getItem(Storages.LOCAL_TOKEN_USER)
        const url = API_URL + `desktop/shared-files/${chosenFile}?token=${token}`
        let data = await axios.get(url, { responseType: 'arraybuffer' }) 
        await sendFile(data)
      }
    } catch (err) {
      console.log(err)
      toast.error(`Error while printing file: ${err.message}`, {autoClose: 5000})
    }
    finally {
      setDisableInputs(false)
    }
  }

  const sendFile=async(data)=>{
    try {
      if(!data) return
      const axiosRes = await apiService.sendMessage({ to: location, event: "print", msg: { file: data } });
      const printRes = axiosRes.data
      if (printRes.code === 0) {
        close();
        toast.success("Print request has been sent.");
      } else {
        toast.error(`Error while printing file: ${printRes.msg}`, {autoClose: 5000})
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error while printing file: ${err.message}`, {autoClose: 5000})
    }
  }

  const handleChooseFile=({target})=>{
    setFile(target.files[0])
  }

  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Dialog
        open={showModal}
        onClose={close}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disableBackdropClick="false"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className={classes.dialogTitle} disableTypography={true}>
          <h5>Send file for print</h5>
        </DialogTitle>
        <DialogContent>
          {pdfObjectUrl ? 
            <FormControl fullWidth style={{height: "400px"}} className="mb-2">
              <object data={pdfObjectUrl} type="application/pdf" width="100%" height="100%"></object>
            </FormControl> 
          : ""}
          <FormControl  variant="outlined" className='my-1' fullWidth>
          <InputLabel id="file-label">Choose File</InputLabel>
            <Select
              labelId="file-label"
              labelWidth={80}
              onChange={onChosenFileChange}
              value={chosenFile}
              disabled={disableInputs}
              onOpen={getFilesList}
            >
              {
                shareFiles === null ? <MenuItem value="">Loading files...</MenuItem> :
                Array.isArray(shareFiles) && !shareFiles[0] ? <MenuItem value={-1}>No pdf file found.</MenuItem> :
                shareFiles.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
              }
              <MenuItem key={-2} value={'upload'}>Upload File</MenuItem>
            </Select>

          </FormControl>
          {chosenFile==='upload' && 
            <FormControl variant="outlined" className='my-2' fullWidth>
              <input
                accept="application/pdf"
                id="icon-button-pdf"
                onChange={handleChooseFile}
                type="file"
              />
            </FormControl>
          }
          <FormControl fullWidth variant="outlined" className='my-3'>
            <InputLabel id="location-label">To Location</InputLabel>
            <Select
              labelId="location-label"
              onChange={onLocationChanged}
              labelWidth={80}
              value={location}
              disabled={disableInputs}
            >
              <MenuItem value="">Please choose ...</MenuItem>
              {allLocations.map((item, index) => {
                const isSec = !item.isPrimary;
                if(isSec && !showSecondaryLocations) return;
                return (
                  <MenuItem key={index} value={item.username}>
                    {item.locationname}
                    {isSec && 
                      <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </MenuItem>
                )
              })}
              </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className='px-4 pb-4'>
          <RedButton className="dialog_btn" variant="contained" color="secondary" startIcon={<CloseIcon />} onClick={close} disabled={disableInputs}>Cancel</RedButton>
          <YellowButton className="dialog_btn" variant="contained" color="secondary" startIcon={<PrintIcon />} onClick={submit} disabled={disableInputs}>Submit</YellowButton>
        </DialogActions>
      </Dialog>
    </Draggable>
  )
})
export default PrintFileRequestModal