import React, { useEffect, useState ,useMemo, useRef} from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import * as $ from 'jquery';
import LocationArea from '../../Components/Location/LocationArea';
import UserArea from '../../Components/User/UserArea';
import AdminService from '../../services/api';
import { toast } from 'react-toastify';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import Storages from '../../constants/storages';
import './style.css';
import './bootstrap.min.css';
import { useSelector, useDispatch } from 'react-redux';
import { addMic, selectMic, deleteMics } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCameras } from '../../redux/cameraSlice';
import {setShowSecondaryLocations} from '../../redux/showSecondaryLocations';
import { useContext } from 'react';
import { SocketContext } from '../../context/socket';
import {ThemeToggleContext} from '../../context/theme'
import RequestPaymentModal from '../../Components/Modal/RequestPayment';
import PrintFileRequestModal from '../../Components/Modal/PrintFileRequest';
import ScanRequestModal from '../../Components/Modal/ScanRequest';
import ReactLoading from 'react-loading';
import ToolBox from '../../Components/Reception/ToolBox';
import ReceptionInfo from '../../Components/Reception/ReceptionInfo';
import LocationNameBox from'../../Components/Location/LocationNameBox'
import LucasLogo from '../../assets/images/virtualsally_logo.png';
import {GalleryModal} from '../../Components/Modal/Gallery'
import CardDetectionRequest from '../../Components/Modal/CardDetectionRequest';
import {setUnReadMessages} from '../../redux/messages'
import { createVirtualBackgroundEffect } from '../../services/virtual-background';
import Connection from '../../Components/User/Connection';
import {trackMuteChange} from '../../redux/tracksMuted';
import { setRemoteStates,clearRemotesID } from '../../redux/remoteStates';
import LocationCallTag from '../../Components/Location/LocationCallTag';
const useStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        height: '100%',
        // background: '#1d1d1d',
        flexDirection: 'column'
    },
    menu_btn: {
        color: 'white',
        fontSize: 'max(1.85vh, 15px)',
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
        outline: 'none!important'
    },
    LucasLogoDiv:{
        top:0,
        left:0,
        zIndex:"2",
        // position:"absolute"
    },
    lucasLogoImg:{
        maxWidth:"70px",
        maxHeight:"140px",
        position:"fixed",
        visibility:"visible",
        padding:"5px",
    }
}))
var intervalUpdateActivity
const Doctor = (props) => {
    const dispatch = useDispatch();
    const classes = useStyle();
    const [logo, setLogo] = useState('');
    const [userStatus, setUserStatus] = useState(Constant.LOGOUT);
    const [localUserData, setLocalUserData] = useState([]);
    const [receptionData, setReceptionData] = useState([]);
    const [locationData, setLocationData] = useState([]);
    // const [allLocationData, setAllLocationData] = useState([]);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [locationName, setLocationName] = useState("");
    const [locationVideoTrack, setLocationVideoTrack] = useState(null);
    const [locationAudioTrack, setLocationAudioTrack] = useState(null);
    const [callState, setCallState] = useState(false);
    const [playState, setPlayState] = useState(false);
    const [glowState, setGlowState] = useState(false);
    const [camera, setCamera] = useState(true); // we have to start camera unmute, beacuse of jitsi bug.
    const [mic, setMic] = useState(true);
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const connections = useSelector((state) => state.connections)
    const [joinState, setJoinState] = useState(false);
    const [allLocations, setAllLocations]=useState([])
    const [isNowBlur, setIsNowBlur] = useState(false);
    const [localConnectionQuality, setLocalConnectionQuality] = useState(0)
    const allLocationsRef=React.useRef(allLocations)
    const {mode}=useContext(ThemeToggleContext)
    const [locationID,setLocationID]=useState(0)
    const _setAllLocations = data => {
        allLocationsRef.current = data;
        setAllLocations(data);
    };
    // const allLocations = useSelector((state) => state.locations);
    const [printFileModalShow, setPrintFileModalShow] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const isSignalAccept = React.useRef(false);
    const isLogout = React.useRef(false);
    const room = React.useRef(null);
    const locationNum = React.useRef(-1);
    /** joined users */
    let listReceptionData = [];
    let listLocationData = [];
    let listRemoteUsers = [];
    /** unjoined location users */
    let listAllLocationUsers = [];
    let localTracks = [];
    const connection = React.useRef(null);
    const volumeWarningShowed = React.useRef(false);
    let lastTime;
    let isJoined = false;

    const [locationEngaged, _setLocationEngaged] = useState(null)
    const [payReqModal, setPayReqModal] = useState(false);
    /* Scan */
    const [openScanRequestModal,setOpenScanRequestModal]=useState(false)
    
    /*sleep mode */
    const locationEngagedRef = React.useRef(locationEngaged); // to access in event listener
    const socket = useContext(SocketContext)
    const setLocationEngaged = data => {
        locationEngagedRef.current = data;
        _setLocationEngaged(data);
    };
    /* Scan images editor*/
    const [openUserSideBar,SetOpenUserSideBar]=useState(true)
    const refUserSideBar=React.useRef()
    const [showGalleryModal,setShowGalleryModal]=useState(false)
    const [defaultBatch,setDefaultBatch]=useState()
    const [openCardDetection,setOpenCardDetection]=useState(false)
    const nodeRef = React.useRef(null);
    const [timeOutForCamera,setTimeOutForCamera]=useState()
    const [locationVolume, setLocationVolume] = useState(0)
    const localVideoTrackRef= useRef(null)
    const localAudioTrackRef = useRef(null);

    const ToolBoxElement=useMemo(() => ToolBox, [locationEngaged,callState ,playState,joinState,mic,camera,allLocations,openUserSideBar,locationVolume])
    const LocationCallTagMemo=useMemo(()=>LocationCallTag,[callState,locationData,locationID])
    /**
     * Function that check sleep and refresh on waking up.
     * 
     */
    function checkSleeping() {
        let currentTime = (new Date()).getTime();
        if (currentTime > (lastTime + Constant.SLEEP_INTERVAL * 2)) {  // ignore small delays
            setTimeout(function () {
                //enter code here, it will run after wake up
                window.location.reload(true);
            }, Constant.TEN_SEC_INTERVAL);
        }
        lastTime = currentTime;
    }

    const options = {
        hosts: {
            domain: process.env.REACT_APP_JITSI_DNS_NAME,
            muc: 'conference.' + process.env.REACT_APP_JITSI_DNS_NAME, // FIXME: use XEP-0030
        },
        bosh: 'https://' + process.env.REACT_APP_JITSI_DNS_NAME + '/http-bind', // FIXME: use xep-0156 for that
        clientNode: "https://jitsi.org/jitsimeet",
        useStunTurn: true
    };

    const confOptions = {
        openBridgeChannel: true,
    };

    const initOptions = {
        disableAudioLevels: true,
        enableAnalyticsLogging: false
    } 
    const checkValidationUsername=async()=>{
      try {
        var username=props.match.params.username;
      var token=sessionStorage.getItem(Storages.LOCAL_TOKEN_USER) || localStorage.getItem(Storages.LOCAL_TOKEN_USER)
      var clientId=localStorage.getItem(Storages.LOCAL_CLIENT_ID);
      if(! username || !token || !clientId) {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href='/login'
          return
      }
      var res=await AdminService.checkValidationUsername({username,token,clientId})
      if(res.data.code!==0) {
        sessionStorage.clear()
        localStorage.clear()
        toast.error(res.data.msg, {autoClose: 5000})
        window.location.href='/login'
        return
      }
      } catch (error) {
        console.error(1490,error)
        toast.error(error.message, {autoClose: 5000})
        window.location.href='/login'
      }
    }
    
    useEffect(() => {
      try{
        checkValidationUsername()
        localStorage.removeItem("reload_lock")
        dispatch(setShowSecondaryLocations(Boolean(localStorage.getItem("showSecondaryLocations"))))

        updateUserStatus(Constant.LOGIN);
        setLogo(localStorage.getItem("logo"));
        socketOn()
        if (window.JitsiMeetJS) {
            if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
              console.alert(1390, "!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices");
              setCamera(false)
              setMic(false)
              toast.error(`No media device found. Please reload. 
                If this issue persists, please contact customer support.
              `, {autoClose: 10000})
              return;
            }

            getAllReceptionList();

            window.$ = $
            window.jQuery = $
            window.JitsiMeetJS.init(initOptions);
            connection.current = new window.JitsiMeetJS.JitsiConnection(null, null, options);

            connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
            connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
            connection.current.addEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, unload);
            connection.current.connect();

            dispatch(deleteMics());
            dispatch(deleteCameras());
            if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
                window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                    let cameraIndex = -1, micIndex = -1;
                    const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                    if(!videoInputDevices[0]) {
                      setCamera(false)
                      toast.error(`No camera found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
                    }
                    const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);
                    if(!audioInputDevices[0]) {
                      setMic(false)
                      toast.error(`No microphone found. Please reload. 
                        If this issue persists, please contact customer support.
                      `)
                    }

                    let cameraDeviceId = localStorage.getItem(Storages.LOCAL_CAMERA_ID);
                    let micDeviceId = localStorage.getItem(Storages.LOCAL_MIC_ID);

                    audioInputDevices.forEach((item, index) => {
                        if(item.deviceId == "communications") {
                            return;
                        }
                        let label = item.label;
                        if(label) {
                            if(label.startsWith("Microphone")) {
                                label = label.substring(11);
                            }
                            if(label.startsWith("Default - Microphone")) {
                                label = "Default - " + label.substring(20);
                            }
                            label = label.trim()
                            if(label.startsWith("(")) {
                                const indexOfClose = label.indexOf(")")
                                label = label.slice(1, indexOfClose) + label.slice(indexOfClose+1)
                            }
                        }

                        let mic = { id: index, deviceId: item.deviceId, label, selected: false }
                        if (item.deviceId === micDeviceId) {
                            micIndex = index;
                            mic.selected = true;
                        }
                        dispatch(addMic(mic));
                    })

                    if (micIndex === -1 && audioInputDevices.length) {
                        micDeviceId = audioInputDevices[0].deviceId;
                        dispatch(selectMic({ index: 0 }));
                    }

                    window.JitsiMeetJS.createLocalTracks({
                        devices: [Str.STR_AUDIO],
                        micDeviceId: micDeviceId
                    })
                    .then(onLocalTracks)
                    .catch(error => {
                        console.error(1498, error)
                        setMic(false)
                        toast.error(`There is an issue with audio track. Please reload. 
                          If this issue persists, please contact customer support.
                        `)
                    });

                    videoInputDevices.forEach((item, index) => {
                        let camera = { id: index, deviceId: item.deviceId, label: item.label, selected: false }
                        if (item.deviceId === cameraDeviceId) {
                            cameraIndex = index;
                            camera.selected = true;
                        }
                        dispatch(addCamera(camera));
                    })

                    if (cameraIndex === -1 && videoInputDevices.length) {
                        cameraDeviceId = videoInputDevices[0].deviceId;
                        dispatch(selectCamera({ index: 0 }));
                    }

                    window.JitsiMeetJS.createLocalTracks({
                        devices: [Str.STR_VIDEO],
                        cameraDeviceId: cameraDeviceId
                    })
                    .then(onLocalTracks)
                    .catch(error =>{
                        console.error(1495, error)
                        setCamera(false)
                        toast.error(`There is an issue with video track. Please reload. 
                          If this issue persists, please contact customer support.
                        `)
                    });
                });
            }
            // A hack for jitsi mute and unmute bug,
            // we have to start camera unmute, beacuse of jitsi bug, and then mute it after 10 sec to reduce BW.
            // https://community.jitsi.org/t/lib-jitsi-meet-mute-then-unmute-leads-to-frozen-remote-video-track/78713
            var timeout=setTimeout(() => {
                setCallStateAndLocalVideo(false)
            }, 10000)
            setTimeOutForCamera(timeout)
        }
      }catch(err){
          console.error(1566,err)
      }
      return (() => {
          // clearInterval(intervalId);
          if(intervalUpdateActivity)clearInterval(intervalUpdateActivity)
          if (!localStorage.getItem(Storages.LOCAL_TOKEN_USER)) logout()
          else if(socket){
              socket.off('setLocationLabel')
              socket.off('msg')
              socket.off('join')
              socket.off('leave')
              socket.off("unSleep")
              socket.off("sleep")
              socket.off("locations")
              socket.off("reloadSharedFiles")
          }
      })
    
    }, []);

    const logout=async()=>{
      try{
        setShowLoading(true)
        isLogout.current=true
        await unload(true)
        sessionStorage.clear()
        localStorage.removeItem(Storages.LOCAL_TOKEN_USER);
        localStorage.removeItem(Storages.LOCAL_ROOM_NAME);
        localStorage.removeItem(Storages.LOCAL_LOGINED_USER);
        localStorage.removeItem(Storages.LOCAL_COMPANY_NAME);
        localStorage.removeItem(Storages.LOCAL_CLIENT_ID);
        localStorage.removeItem("groupnum");
        localStorage.removeItem("showToggleSecondary");
        localStorage.removeItem("logo")
        if(socket){
          socket.off('setLocationLabel')
          socket.off('msg')
          socket.off('join')
          socket.off('leave')
          socket.off("unSleep")
          socket.off("sleep")
          socket.off("locations")
          socket.off("reloadSharedFiles")
        }
        window.location.href="/login"
      } catch (err) {
        setShowLoading(false)
        toast.error("Error on logout: " + err.message, {autoClose: 10000})
        console.error(1494, err)
        window.location.href="/login"
      }
    }

    function socketOn() {
        if (socket) {
            socket.on('setLocationLabel', ({ username, label }) => {
                var data = [...listLocationData]
                if (data) {
                    let locations = data.map((loc) => {
                        if (loc.username == username) {
                            loc.calltag = label
                        }
                        return loc
                    })
                    setLocationData(locations)
                }
            })
            socket.on('msg', ({event, msg})=>{
              if(event ==='scan-result-is-ready'){
                const batchID=msg.batchID
                setDefaultBatch(batchID)
                // handleOpenGallery()
                // handleCloseScanRequestModal()
                closeCardDetection()
              }
              if (event === "sendVolumeToReception") {
                setLocationVolume(msg.volume || 100);
                if(msg.volume < 20 && !volumeWarningShowed.current) {
                  toast.warning("Location's audio volume is too low. You may consider increasing it.", {autoClose: 10000})
                  volumeWarningShowed.current = true;
                }
              }

            })
            
            socket.on('locations',({locations})=>{
              console.alert(1391, "socket.on('locations', locations:", locations.length)
              _setAllLocations(locations)
            })
            
            socket.on('join', (username)=>{ // when location connect to socket api
              console.alert(1392, "socket.on('join', username: ", username)
                let locations=[...allLocationsRef.current]
                locations=locations.map((loc)=>{
                  if(loc.username==username){
                    return {...loc,isOnline:1}
                  }
                  return loc
                })
              _setAllLocations(locations)
            })

            socket.on('leave', (username)=>{ // when location leave from socket api
              console.alert(1393, "socket.on('leave', username: ", username)
              let locations=[...allLocationsRef.current]
              locations=locations.map((loc)=>{
                if(loc.username==username){
                    return {...loc,isOnline:0}
                }
                return loc
              })
              _setAllLocations(locations)
            })

            socket.on('sleep',({username})=>{
              let locations=[...allLocationsRef.current]
              var isChange=false
              locations=locations.map((loc)=>{
                  if(loc.username==username){
                      if(loc.isSleep == 0) isChange=true
                      return {...loc,isSleep:1}
                  }
                  return loc
              })
              if(isChange) _setAllLocations(locations)
            })
            socket.on('unSleep',({username})=>{
              let locations=[...allLocationsRef.current]
              var isChange=false
              locations=locations.map((loc)=>{
                  if(loc.username==username){
                      if(loc.isSleep ==1) isChange=true
                      return {...loc,isSleep:0}
                  }
                  return loc
              })
              if(isChange) _setAllLocations(locations)
            })
            socket.on('reloadSharedFiles',()=>{
              console.log(1554,"socket get reloadSharedFiles")
            })
        }
    }

    async function getAllReceptionList() {
      try{
        const res = await AdminService.getAllReceptionList();
        if(res.data.code !== 0) {
          console.error(1499, res.data)
          toast.error(res.data.msg, {autoClose: 10000})
          return;
        }

        listReceptionData = res.data.data;
        setReceptionData(res.data.data);
        dispatch(setUnReadMessages(res.data.data))
      } catch (err) {
        console.error(1491, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const onConnectionSuccess = () => {
      try {
        
        const roomName = localStorage.getItem(Storages.LOCAL_ROOM_NAME)
        console.alert(1394, "onConnectionSuccess, roomName:", roomName)
        room.current = connection.current.initJitsiConference(roomName, confOptions);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_ADDED, onTrackAdded);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_REMOVED, onRemoveTrack);
        room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_FAILED, (e)=>{
          console.error(1490, "events.conference.CONNECTION_FAILED", e)
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED, (e)=>{
          console.error(1489, "events.conference.CONFERENCE_DESTROYED", e)
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_ERROR, (e)=>{
          console.error(1488, "events.conference.CONNECTION_ERROR", e)
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED, (e)=>{
          console.error(1487, "window.JitsiMeetJS.events.conference.CONFERENCE_DESTROYED",e )
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONNECTION_ERROR, (e)=>{
          console.error(1486, "window.JitsiMeetJS.events.conference.CONNECTION_ERROR,",e );
          reload()
        });
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);
        room.current.on(window.JitsiMeetJS.events.conference.USER_JOINED, onUserJoined);
        room.current.on(window.JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, handleParticipantPropertyChange);
        room.current.on(window.JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
        room.current.on(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
        room.current.on(window.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
          console.info(`12014 ${track.getParticipantId()}  ${track.getType()} - ${track.isMuted()}  -   ${track.isLocal()}`);
          let type=track.getType()
          let isMuted=track.isMuted()
          let id=track.getParticipantId()
            dispatch(trackMuteChange({id,type,isMuted}))
        });
         
        room.current.on(
          window.JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
          (updatedLocalStats) => {         
            const newQuality = parseInt(updatedLocalStats.connectionQuality)
            if(newQuality < 30) {
              console.alert(1356, "localStats,", updatedLocalStats);
            } 

            setLocalConnectionQuality(newQuality)
          }
        );
        room.current.on(
          window.JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED,
          (id, remoteStats) => {
            const remotesID=[]
            for(let u of listRemoteUsers){
              remotesID.push(u.id)
            }
            dispatch(clearRemotesID(remotesID))
            dispatch(setRemoteStates({id, remoteStats}))
            const foundLocationData = listLocationData.find(item => item.id == id);
            if(!foundLocationData) {
              console.alert(1345, "remoteStats no user found with id", id);
              return;
            }
            if(remoteStats.connectionQuality < 30) {
              console.alert(
                1346, 
                `remoteStats, id: ${id}, locationname: ${foundLocationData.locationname}, stats:`, 
                remoteStats
              );
            } 

          }
        );
        localTracks.map((localTrack) => {
            room.current.addTrack(localTrack);
        });
        room.current.setDisplayName(props.match.params.username);
        room.current.join();
      } catch (error) {
        console.error(1567,error)
      }
    }

    function reload(){
      console.alert(1395, "Reload called")
        unload().then(() => {
          setTimeout(()=>{
            window.location.reload(true)
          }, Constant.TEN_SEC_INTERVAL)
        })
    }

    const onUserJoined = (id, user) => {
      console.alert(1396, "onUserJoined, id:", id, "user:", user.getDisplayName())
        let isFind = false;
        listRemoteUsers.forEach((cell, index) => {
            if (cell.id === id) {
                cell.user = user;
                listRemoteUsers[index] = cell;
                isFind = true;
            }
        });
        if (isFind) {
            return;
        }
        let new_user = { id: id, user: user };
        listRemoteUsers.push(new_user);
    }

    const onConnectionFailed = (error) => {
      // ToastsStore.error("Failed to connect to Jitsi. Retrying in " + Constant.TEN_SEC_INTERVAL/1000 + " seconds.", 9000);
      console.alert(1397, "onConnectionFailed", error)
      setTimeout(function () {
        //enter code here, it will run after wake up
        window.location.reload(true);
        }, Constant.TEN_SEC_INTERVAL);
    }

    async function unload(sendUserLeave) {
      console.alert(1398, "unload, sendUserLeave:", sendUserLeave)

      try {
        if (localVideoTrack !== null) {
          localVideoTrack.detach($(`#mainRVideo`)[0]);
        }

        if (localAudioTrack !== null) {
          localAudioTrack.detach($(`#mainRAudio`)[0]);
        }

        for (let i = 0; i < localTracks.length; i++) {
          localTracks[i].dispose();
        }
      } catch (error) {
        console.error(1483, error)
      }
      try {
        if (room.current !== null) {
          room.current.removeEventListener(window.JitsiMeetJS.events.conference.CONFERENCE_LEFT, onConferenceLeft);
          await room.current.leave();
          room.current = null;
        }

        if (connection.current !== null) {
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, unload);
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, onConnectionSuccess);
          connection.current.removeEventListener(window.JitsiMeetJS.events.connection.CONNECTION_FAILED, onConnectionFailed);
          await connection.current.disconnect();
          connection.current = null;
        }

        if(sendUserLeave) {
          await userLeaved(props.match.params.username);
        }
      } catch (error) {
        console.error(1484,error) 
      }
    }

    const onLocalTracks = (tracks) => {
      tracks.forEach((localTrack, index) => {
          console.alert(1399, "localTrack ", localTrack.getType())
            if (localTrack.getType() === Str.STR_VIDEO) {
                localStorage.setItem(Storages.LOCAL_CAMERA_ID, localTrack.deviceId);
                localTrack.attach($(`#mainRVideo`)[0]);
                localVideoTrackRef.current=localTrack
                setLocalVideoTrack(localTrack);
                if (!camera) {
                    localTrack.mute();
                }
            } else if (localTrack.getType() === Str.STR_AUDIO) {
                localStorage.setItem(Storages.LOCAL_MIC_ID, localTrack.deviceId);
                localTrack.attach($(`#mainRAudio`)[0]);
                localAudioTrackRef.current=localTrack
                setLocalAudioTrack(localTrack);
                if (!mic) {
                    localTrack.mute();
                }
            }

            loadTrack(localTrack)
        });
    }

    function loadTrack(localTrack) {
      try{
        if (joinState || isJoined) {
          let tempTrack = null;
          room.current.getLocalTracks().forEach((track) => {
              if (track.getType() === localTrack.getType()) {
                  tempTrack = track;
              }
          });
          if (tempTrack) {
              room.current.replaceTrack(tempTrack, localTrack);
          }
          else {
              room.current.addTrack(localTrack);
          }
          localTracks.push(localTrack);
        } else {
          setTimeout(() => loadTrack(localTrack), 1000)
        }              
      } catch (err) {
        console.error(1455, err)
        toast.error("Error while loading media tracks. Please reload", {autoClose: 5000});
      }
    }

    function getTrackFromUserName(username) {
        let ret = null;
        if (username === props.match.params.username) {
            localTracks.forEach((localTrack) => {
                if (localTrack.getType() === Str.STR_VIDEO) {
                    ret = localTrack;
                }
            });
        }
        else {
            listRemoteUsers.forEach((cell) => {
                if (cell.user.getDisplayName() === username) {
                    let tracks = cell.user._tracks;
                    tracks.forEach((track) => {
                        if (track.type === Str.STR_VIDEO) {
                            ret = track;
                        }
                    })
                }
            });
        }

        return ret;
    }

    const onTrackAdded = (track) => {
      if (track.isLocal()) {// regist video particant id on db
          if (track.getType() === Str.STR_VIDEO) {
              let localData = { username: props.match.params.username, videotrack: track };
              setLocalUserData(localData);

              let data = {};
              data['username'] = props.match.params.username;
              data['selfpartid'] = track?.getParticipantId();

              AdminService.updateSelfPartId(data);
          }
          return;
      }
      else {
            // if remote track is muted. reception show it.
            let type=track.getType()
            let isMuted=track.isMuted()
            let id=track.getParticipantId()
            dispatch(trackMuteChange({id,type,isMuted}))
      }

    //   isSignalAccept.current = true;//sign to start remote track
      //Is it remote camera?
      let data = {};
      let username = "";
      const participant = track?.getParticipantId();
      const type = track.getType();
      let user_val = { 
          id: participant, 
          user: null, 
          videotrack: null, 
          audiotrack: null, 
          othertrack: null, 
          calltag: null, 
          status: Constant.JOIN, 
          locationname: null, 
          vpnip: null, 
          remoteusername: null, 
          remoteuserpwd: null, 
          username: null,
          desktopTrack: null,
      };
      listRemoteUsers.forEach((cell) => {
          if (cell.id === participant) {
              user_val.user = cell.user;
              username = cell.user.getDisplayName();
              return;
          }
      });

      console.alert(1400, "onTrackAdded after isLocal, id", participant, "type", type, "username", username)

      if (!username || username === "") {
          return;
      }
      //get type from username
      data['username'] = username
      AdminService.getInfoFromUsername(data).then(response => {
        if (response.data.code !== 200) {
          console.error(1481, response.data)
          toast.error(response.data.message);
          return;
        }

        let result = response.data.data.data;
        if (result.type === Constant.RECEPTION) {
          return;
        }
        
        let fround = false;
        user_val.calltag = result.calltag;
        user_val.vpnip = result.vpnip;
        user_val.remoteusername = result.remoteusername;
        user_val.remoteuserpwd = result.remoteuserpwd;
        user_val.locationname = result.locationname;
        user_val.username = result.username;

        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.id === participant) {
                if (type === Str.STR_VIDEO) {
                    if(remoteUser.videotrack) {
                      remoteUser.videotrack.detach($(`#${remoteUser.videotrack.getParticipantId() + 'location' + remoteUser.videotrack.getType()}`)[0]);
                      track.attach($(`#${remoteUser.videotrack.getParticipantId() + 'location' + remoteUser.videotrack.getType()}`)[0]);
                    }

                    if(result.username === locationEngagedRef.current) {
                        if(remoteUser.videotrack) {
                            remoteUser.videotrack.detach($(`#locationVideo`)[0]);
                        }
                        track.attach($(`#locationVideo`)[0]);
                        setLocationVideoTrack(track);
                        stageTrack(track)
                    }
                    remoteUser.videotrack = track;
                    remoteUser.othertrack = getTrackFromUserName(result.otherpartid);
                    remoteUser.status = result.status;
                    listData[index] = remoteUser;
                } else if (type === Str.STR_AUDIO) {
                    remoteUser.audiotrack = track;
                    listData[index] = remoteUser;

                    if (result.otherpartid === props.match.params.username) {
                        track.attach($(`#locationAudio`)[0]);
                        setLocationAudioTrack(track);
                    }
                }
                fround = true;
            }
        });

        if (fround === true) {
          listLocationData = [...listData];
          setLocationData(listData);
          return;
        }

        if (type === Str.STR_VIDEO) {
          user_val.videotrack = track;
          user_val.othertrack = getTrackFromUserName(result.otherpartid);
          user_val.status = result.status;
        } else if (type === Str.STR_AUDIO) {
          user_val.audiotrack = track;
          if (result.otherpartid === props.match.params.username) {
            track.attach($(`#locationAudio`)[0]);
            setLocationAudioTrack(track);
          }
        }

        /**remove old location user video feed on sleeping */
        let removeIndex = -1;
        for (let i = 0; i < listData.length; i++) {
          if (listData[i].user.getDisplayName() === username && listData[i].id !== participant) {
            removeIndex = i;
            break;
          }
        }

        if (removeIndex !== -1) {
          listData.splice(removeIndex, 1);
        }
        listData.push(user_val);
        listLocationData = [...listData];
        setLocationData(listData);
      }, error => {
        console.error(1480, error)
        toast.error(Str.STR_SERVER_ACCESS_ERROR);
        return;
      });
    }

    const onRemoveTrack = (track) => {
        const participant = track?.getParticipantId();
        const type = track.getType();

        console.alert(1401, "onRemoveTrack, id", participant, "type", type)

        listLocationData.forEach((user, index) => {
            if (user.id === participant) {
                if (type === "video") {
                    track.detach($(`#${participant}locationvideo`)[0]);
                }
                else if (type === "audio") {
                    track.detach($(`#${participant}locationaudio`)[0]);
                }
            }
        });
    }

    function onConferenceJoined() {
        if (isLogout.current === true || window.location.hash === "/login") {
            unload(true).then(() => {
              return;
            });
        }
        isJoined = true;
        setJoinState(true)
        // localTracks.forEach((localTrack) => {
        //     room.current.addTrack(localTrack);
        //     room.current.setDisplayName(props.match.params.username);
        // });

        handleUserStatus(Constant.JOIN);
        stageTrack(null)
    }

    function updateUserStatus(status) {
        let data = {};
        data['username'] = props.match.params.username;
        data['status'] = status;

        console.alert(1301, "updateUserStatus, status:", status)

        AdminService.updateUserStatus(data)
            .then(
                response => {
                    if (response.data.code !== 200) {
                      console.error(1479, response.data)
                        toast.error(response.data.message);
                    }
                },
                error => {
                  console.error(1478, error)
                    toast.error(Str.STR_SERVER_ACCESS_ERROR);
                }
            );

        insertLog(status)
    }

    const onUserLeft = (id, user) => {
      try {
        console.alert(1405, "onUserLeft, id:", id, "user:", user.getDisplayName(), "locationEngagedRef", locationEngagedRef.current)
        if (user.getDisplayName() == locationEngagedRef.current) {
            setLocationEngaged(null)
            clearInterval(intervalUpdateActivity)
        }
        let listData;
        //locations list
        listData = [...listLocationData];
        listData.forEach((cell, index) => {
            if (cell.id === id) {
                if (locationNum.current > -1) {
                    //remove current call track
                    let locationName = listData[locationNum.current].user.getDisplayName();
                    if (locationName === user.getDisplayName()) {
                        currentCallEnd();
                    }
                }
                if (index < locationNum.current) {
                    locationNum.current = locationNum.current - 1;
                }

                listLocationData.splice(index, 1);
                return;
            }
        });
        setLocationData(listLocationData);

        let remove = -1;
        listRemoteUsers.forEach((cell, index) => {
            if (cell.id === id) {
                remove = index;
            }
        });

        if (remove !== -1) {
            listRemoteUsers.splice(remove, 1);
        }

        if (isLogout.current === false) {
          userLeaved(user.getDisplayName());
        }
        
        let listReceptions = [...listReceptionData];
        let isFindReception = false;
        listReceptions.forEach((rec, index) => {
            if (rec.username === user.getDisplayName()) {
                listReceptions[index].status = 0;
                listReceptions[index].otherpartid = Str.STR_EMPUTY;
                isFindReception = true;
                return;
            }
        });

        if (isFindReception) {
            listReceptionData = [...listReceptions];
            sortByUserStatus();
        }
        else {
            getAllReceptionList();
        }
      } catch (error) {
       console.error(1568,error) 
      }
    }

    function onConferenceLeft() {
      if (isLogout.current === false) {
        //   room.current.join()
        window.location.reload();
      }
    }
		
    const handleParticipantPropertyChange = (participant, propertyName, oldValue, newValue) => {
      console.alert(
        1408,
        "handleParticipantPropertyChange",
        participant.getId(),
        participant.getDisplayName(),
        propertyName,
        oldValue,
        newValue
      );
        if (/*isSignalAccept.current === false || */newValue === Constant.NONE_VALUE) {
            return;
        }

        /*update call tag that come from location.*/
        if (propertyName === Str.STR_CALL_TYPE) {
            let participantId = participant.getId();
            let listData = [...listLocationData];
            listData.forEach((remoteUser, index) => {
                if (remoteUser.id === participantId) {
                    remoteUser.calltag = newValue;
                    return;
                }
            });

            listLocationData = [...listData];
            setLocationData(listData);
            return;
        }

        /*handle call from receptions.*/
        let receptionName = participant.getDisplayName();

        /*update call status that come from reception.*/
        if (propertyName === Str.STR_RECEPTION_CALL_STATUS) {
            let userStatusValue = parseInt(newValue, 10);
            let listData = [...listReceptionData];
            let isFindReception = false;
            listData.forEach((user, index) => {
                if (user.username === receptionName) {
                    listData[index].status = userStatusValue;
                    isFindReception = true;
                    return;
                }
            });

            if (isFindReception) {
                listReceptionData = [...listData];
                sortByUserStatus();
            }
            else {
                getAllReceptionList();
            }
            return;
        }

        let locationName = propertyName;
        switch (newValue) {
            case Constant.CALL_END_VALUE:
                callEnd(receptionName, locationName);
                break;
            case Constant.CALL_INCOMING_VALUE:
                callIncoming(receptionName, locationName);
                break;
            case Constant.CALL_ACCEPT_VALUE:
                callJoined(receptionName, locationName);
                break;
            case Constant.CALL_PAUSE_VALUE:
                callHoldOn(receptionName, locationName);
                break;
            case Constant.CALL_PLAY_VALUE:
                callHoldOff(receptionName, locationName);
                break;
            default:
                break;
        }
    }

    function callEnd(receptionName, locationName) {
      console.alert(1410, "callEnd", receptionName, locationName , callState)

        if (locationNum.current > -1) {
            if (listLocationData[locationNum.current].user.getDisplayName() === locationName) {
                currentCallEnd();
            }
        }

        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.status = Constant.JOIN;
                remoteUser.othertrack = null;
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);

        /**update location information on call with receptionist */
        let listData1 = [...listReceptionData];
        listData1.forEach((user, index) => {
            if (user.username === receptionName) {
                listData1[index].otherpartid = Str.STR_EMPUTY;
                return;
            }
        });

        listReceptionData = [...listData1];
        setReceptionData(listData1);
    }

    function callIncoming(receptionName, locationName) {
      console.alert(1412, "callIncoming", receptionName, locationName)

        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.status = Constant.INCOMING;
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);
    }

    function callJoined(receptionName, locationName) {
      console.alert(1415, "callJoined", receptionName, locationName)

        if (locationNum.current > -1) {
            if (listLocationData[locationNum.current].user.getDisplayName() === locationName) {
                if(receptionName !=localStorage.getItem(Storages.LOCAL_LOGINED_USER)){
                    handleCancelEngagedLocation(locationNum.current) 
                }
                setLocationName(Str.STR_EMPUTY);
                setLocationID(null)
                setPlayState(false);
                locationNum.current = -1;
                $(`#locationVideo`)[0].srcObject = null;
                setGlowState(false);
                if (locationVideoTrack !== null) {
                    locationVideoTrack.detach($(`#locationVideo`)[0]);
                    setLocationVideoTrack(null);
                    stageTrack(null)
                }

                if (locationAudioTrack !== null) {
                    locationAudioTrack.detach($(`#locationAudio`)[0]);
                    setLocationAudioTrack(null);
                }
            }
        }

        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.othertrack = getTrackFromUserName(receptionName);
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);

        /**update location information on call with receptionist */
        let listData1 = [...listReceptionData];
        listData1.forEach((user, index) => {
            if (user.username === receptionName) {
                listData1[index].otherpartid = locationName;
                return;
            }
        });

        listReceptionData = [...listData1];
        setReceptionData(listData1);
    }

    function callHoldOn(receptionName, locationName) {
      console.alert(1417, "callHoldOn", receptionName, locationName)

        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.status = Constant.HOLD;
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);
    }

    function callHoldOff(receptionName, locationName) {
      console.alert(1418, "callHoldOff", receptionName, locationName)

        let listData = [...listLocationData];
        listData.forEach((remoteUser, index) => {
            if (remoteUser.user.getDisplayName() === locationName) {
                remoteUser.status = Constant.CALL;
                listData[index] = remoteUser;
                return;
            }
        });
        listLocationData = [...listData];
        setLocationData(listData);

        /**update location information on call with receptionist */
        let listData1 = [...listReceptionData];
        listData1.forEach((user, index) => {
            if (user.username === receptionName) {
                listData1[index].otherpartid = locationName;
                return;
            }
        });

        listReceptionData = [...listData1];
        setReceptionData(listData1);
    }

    const handleLocalCamera = () => {
        if (localVideoTrack === null)
            return;

        setCamera(localVideoTrack.isMuted());
        if (localVideoTrack.isMuted()) {
            localVideoTrack.unmute();
        } else {
            localVideoTrack.mute();
            clearTimeout(timeOutForCamera)
        }
        if(callState ===false){
          var timeout=setTimeout(() => {
              setCallStateAndLocalVideo(false)
          }, 5000)
          setTimeOutForCamera(timeout)
      }
    }
    
    useEffect(()=>{
        if(callState){
            clearTimeout(timeOutForCamera)
        }
    },[callState])

    useEffect(() => {
        if (!joinState || room.current === null) {
            return;
        }
        cameras.forEach((cell) => {
            if (cell.selected === true) {
                if (localVideoTrack) {
                    localVideoTrack.dispose();
                }

                window.JitsiMeetJS.createLocalTracks({
                    devices: [Str.STR_VIDEO],
                    cameraDeviceId: cell.deviceId
                })
                    .then(onLocalTracks)
                    .catch(error => console.error(1477, error));
            }
        })
    }, [cameras])

    const handleLocalMic = () => {
        if (!localAudioTrack)
            return;

        setMic(localAudioTrack.isMuted());
        if (localAudioTrack.isMuted()) {
            localAudioTrack.unmute();
        } else {
            localAudioTrack.mute();
        }
    }

    useEffect(() => {
        if (!joinState || room.current === null) {
            return;
        }

        mics.forEach((cell) => {
            if (cell.selected === true) {
                if (localAudioTrack) {
                    localAudioTrack.dispose();
                }

                window.JitsiMeetJS.createLocalTracks({
                    devices: [Str.STR_AUDIO],
                    cameraDeviceId: cell.deviceId
                })
                    .then(onLocalTracks)
                    .catch(error => console.error(1475, error));
            }
        })
    }, [mics])


    function currentCallEnd() {
      console.alert(1420, "currentCallEnd", locationName ,locationNum.current)
        if (isLogout.current === false) {
            handleUserStatus(Constant.JOIN);
        }

        if (locationNum.current > -1 && listLocationData.length > locationNum.current) {
            let listData = [...listLocationData];
            listData[locationNum.current].othertrack = null;
            listData[locationNum.current].status = Constant.JOIN;
            listLocationData = [...listData];
            setLocationData(listData);
            listData[locationNum.current].videotrack.detach($(`#locationVideo`)[0]);
            if (listData[locationNum.current].audiotrack != null) {
                listData[locationNum.current].audiotrack.detach($(`#locationAudio`)[0]);
            }
            setGlowState(false);
        }

        setLocationName(Str.STR_EMPUTY);
        setLocationID(null)
        setCallStateAndLocalVideo(false);
        setPlayState(false);
        volumeWarningShowed.current = false;
        handleCancelEngagedLocation(locationNum.current)
        locationNum.current = -1;

        if (locationVideoTrack !== null) {
            locationVideoTrack.detach($(`#locationVideo`)[0]);
            setLocationVideoTrack(null);
            stageTrack(null)
            setGlowState(false);
        }

        if (locationAudioTrack !== null) {
            locationAudioTrack.detach($(`#locationAudio`)[0]);
            setLocationAudioTrack(null);
        }

        /**update call status unless current user logout */
        if (isLogout.current === false) {
            let data = {};
            data['username'] = props.match.params.username;
            data['type'] = Constant.RECEPTION;
            AdminService.currentCallEnd(data)
                .then(
                    response => {
                        if (response.data.code !== 200) {
                          console.error(1473, response.data)
                            toast.error(response.data.message);
                        }
                    },
                    error => {
                      console.error(1472, error)
                        toast.error(Str.STR_SERVER_ACCESS_ERROR);
                    }
                );
        }
        localStorage.removeItem("reload_lock")
    }

    const handleLocationCall =async () => {
      try {
        
      console.alert(1425, "handleLocationCall")

        if (locationNum.current < 0) {
            return;
        }

        let listData = [...locationData];
        let locationName = listData[locationNum.current].user.getDisplayName();
        if (locationName === null || locationName === "") {
            return;
        }

        console.alert(1426, "handleLocationCall, locationName:", locationName)
        
        if (callState === false) {
            var add=await AdminService.locationCallAdd({locationName})
            if(add.data.code==1) return handleCancelEngagedLocation(locationNum.current) 
            localStorage.setItem("reload_lock", 1)

            room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
            room.current.setLocalParticipantProperty(locationName, Constant.CALL_ACCEPT_VALUE);

            setCallStateAndLocalVideo(true);
            setPlayState(true);
            if (locationData[locationNum.current]?.audiotrack !== null) {
                locationData[locationNum.current].audiotrack.attach($(`#locationAudio`)[0]);
                setLocationAudioTrack(locationData[locationNum.current].audiotrack)
            }
            //set other small video
            listData[locationNum.current].othertrack = localVideoTrack;
            listData[locationNum.current].status = Constant.CALL;
            listLocationData = [...listData];
            setLocationData(listData);
            handleUserStatus(Constant.CALL);
        }
        else {
            room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
            room.current.setLocalParticipantProperty(locationName, Constant.CALL_END_VALUE);
            currentCallEnd();
            if (intervalUpdateActivity) clearInterval(intervalUpdateActivity)
            setLocationEngaged(null)

        }
      } catch (error) {
        console.log(1659,error)
      }
    }

    const handleLocationPlay = () => {
      try {
        if (callState === false || locationNum.current < 0) {
            return;
        }

        let listData = [...locationData];
        let locationName = listData[locationNum.current].user.getDisplayName();

        if (listData[locationNum.current].status === Constant.CALL) {
            listData[locationNum.current].status = Constant.HOLD;
            room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
            room.current.setLocalParticipantProperty(locationName, Constant.CALL_PAUSE_VALUE);
            setPlayState(false);
        }
        else if (listData[locationNum.current].status === Constant.HOLD) {
            listData[locationNum.current].status = Constant.CALL;
            room.current.setLocalParticipantProperty(locationName, Constant.NONE_VALUE);
            room.current.setLocalParticipantProperty(locationName, Constant.CALL_PLAY_VALUE);
            setPlayState(true);

            if (locationData[locationNum.current].audiotrack !== null) {
                locationData[locationNum.current].audiotrack.attach($(`#locationAudio`)[0]);
                setLocationAudioTrack(locationData[locationNum.current].audiotrack);
            }
        }
      } catch (error) {
        console.log(1453,error)
      }
    }

    const handleClickLocationItem = async (index) => {
      try{
        if (callState === true && playState === true) {
            return;
        }
        const username = locationData[index]?.user.getDisplayName()
        if (username === locationEngaged) return handleCancelEngagedLocation(index)

        console.alert(1427, "handleClickLocationItem, username:", username)

        if (locationData[index] && locationData[index].status > Constant.INCOMING) {
            setCallStateAndLocalVideo(true);
            if (locationData[index].status === Constant.HOLD) {
                setPlayState(false);
            }
            else {
                setPlayState(true);
            }
        }
        else {
            setCallStateAndLocalVideo(false);
            setPlayState(false);
        }

        locationNum.current = index;
        if (locationVideoTrack !== null) {
            locationVideoTrack.detach($(`#locationVideo`)[0]);
        }

        if (locationAudioTrack !== null) {
            locationAudioTrack.detach($(`#locationAudio`)[0]);
        }

        setLocationName(locationData[index] && locationData[index].locationname);
        setLocationID(locationData[index] && locationData[index].id)
        if (locationData[index]?.videotrack !== null) {
            locationData[index].videotrack.attach($(`#locationVideo`)[0]);
            setLocationVideoTrack(locationData[index].videotrack);
            stageTrack(locationData[index].videotrack)
            setGlowState(true);
        }

        if (locationData[index]?.audiotrack !== null) {
            setLocationAudioTrack(locationData[index].audiotrack);
        }
        setLocationEngaged(username)
        
        await AdminService.updateLastActivity(username)
        if (intervalUpdateActivity) clearInterval(intervalUpdateActivity)
        intervalUpdateActivity = setInterval(async () => {
            await AdminService.updateLastActivity(username)
        }, 5000)
      } catch (err) {
        console.error(1470, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }
    const handleCancelEngagedLocation = async (index) => {
        if (callState === true && playState === true) return;
        const username =locationData[index] &&  locationData[index].user.getDisplayName()

        console.alert(1428, "handleCancelEngagedLocation, username:", username)

        if (locationEngaged != username) return
        if (intervalUpdateActivity) clearInterval(intervalUpdateActivity)
        setPlayState(false);
        setCallStateAndLocalVideo(false);
        setGlowState(false)
        if (locationVideoTrack !== null) {
            locationVideoTrack.detach($(`#locationVideo`)[0]);
            setLocationVideoTrack(null);
            stageTrack(null)
        }

        if (locationAudioTrack !== null) {
            locationAudioTrack.detach($(`#locationAudio`)[0]);
            setLocationAudioTrack(null);
        }
        setLocationEngaged(null)
        setLocationName(null)
        setLocationID(null)
    }
    const handleItemSleep = async (index) => {
      try{
        await AdminService.updateLastActivity(allLocations[index].username)
        await AdminService.sendMessage({to: allLocations[index].username,event: 'unSleep', msg:'wake up'})
      } catch (err) {
        console.error(1469, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    const handleItemCall = () => {
        setTimeout(function () {
            handleLocationCall()
        }, 1000);
    }

    async function userLeaved(username) {
        try {
            let data = {};
            data['username'] = username;
            data['selfpartid'] = '00000000';
            await AdminService.userLeave(data);
        } catch (error) {
          console.error(1468, error)
        }
    }

    const insertLog = async (status) => {
        try {
            let log = {};
            log['reception'] = props.match.params.username;
            log['status'] = status;
            log['clientid'] = localStorage.getItem(Storages.LOCAL_CLIENT_ID);
            await AdminService.insertReceptionLog(log);
        } catch (error) {
          console.error(1465, error)
        }
    }

    /**
    * Function that handle user status when user status is changed.
    */
    const changeUserStatus = () => {
      console.alert(1430, "changeUserStatus, newStatus:", userStatus)
        switch (userStatus) {
            case Constant.AWAY:
                handleUserStatus(Constant.JOIN);
                break;
            case Constant.JOIN:
                handleUserStatus(Constant.AWAY);
                break;
            default:
                break;
        }
    }

    function handleUserStatus(status) {
      try {
        setUserStatus(status);
        updateUserStatus(status);
        room.current.setLocalParticipantProperty(Str.STR_RECEPTION_CALL_STATUS, Constant.NONE_VALUE);
        room.current.setLocalParticipantProperty(Str.STR_RECEPTION_CALL_STATUS, status.toString());
      } catch (error) {
        console.log(1452,error)
      }
    }

    /*Sort reception list by call status*/
    function sortByUserStatus() {
        let list = [...listReceptionData];

        for (let i = 0; i < list.length - 1; i++) {
            for (let j = i + 1; j < list.length; j++) {
                if (list[i].status < list[j].status) {
                    let item1 = { ...list[i] };
                    let item2 = { ...list[j] };
                    list[i] = item2;
                    list[j] = item1;
                }
                else if (list[i].status === list[j].status && list[i].username.localeCompare(list[j].username) === 1) {
                    let item1 = { ...list[i] };
                    let item2 = { ...list[j] };
                    list[i] = item2;
                    list[j] = item1;
                }
            }
        }

        listReceptionData = [...list];
        setReceptionData(list);

    }

    function setCallStateAndLocalVideo(flag) {
      var localVideo=localVideoTrackRef.current
      var localAudio=localAudioTrackRef.current
      if (localVideo !== null) {          
          if (flag) {
            localVideo.unmute();
          }
          else {
              localVideo.mute();
          }
          setCamera(flag);
      }
      else {
          /**if location call is ended itself */
          localTracks.forEach((cell) => {
              if (cell.getType() === Str.STR_VIDEO) {
                  if (flag === true) {
                      cell.unmute();
                  }
                  else {
                      cell.mute();
                  }
                  setCamera(flag);
                  return;
              }
          });
      }
      if (localAudio !== null) {
          if (flag) {
            localAudio.unmute();
          }
          else {
            localAudio.mute();
          }
          setMic(flag);
      }
      else {
          /**if location call is ended itself */
          localTracks.forEach((cell) => {
              if (cell.getType() === Str.STR_AUDIO) {
                  if (flag === true) {
                      cell.unmute();
                  }
                  else {
                      cell.mute();
                  }
                  setMic(flag);
                  return;
              }
          });
      }
      setCallState(flag);
    }

    const handleCloseModalPayReq = () => {
        setPayReqModal(false)
    }
    const handleOpenModalPayReq = () => {
        setPayReqModal(true)
    }

    const stageTrack = (track) => {
      //{"constraints":{"4942f96f-v0":{"maxHeight":720},"ffbe59bb-v0":{"maxHeight":180}},"defaultConstraints":{"maxHeight":0},"lastN":-1,"onStageSources":["4942f96f-v0"],"selectedSources":[]}
      // setTimeout(() => {
        let conf = {
          defaultConstraints: { maxHeight: 180 },
          constraints: {},
          lastN: -1,
          // selectedSources: [],
          onStageEndpoints: [],
        }

        // make all videos small
        for(let location of locationData) {
          if(location.videotrack && location.videotrack.ownerEndpointId) {
            conf.constraints[location.videotrack.ownerEndpointId] = { maxHeight: 180 };
          }
        }

        // make specific engaged video large
        if(track && track.ownerEndpointId){
          console.alert(1378, `stageTrack ${track.ownerEndpointId}`)
          conf.constraints[track.ownerEndpointId] = { maxHeight: 1080 }
          conf.onStageEndpoints = [track.ownerEndpointId]
        }

        console.alert(1379, `stageTrack, final config:`, conf)
        room.current.setReceiverConstraints(conf)
      // }, 500);
    }

    const showPrintFileModal = () => {
        setPrintFileModalShow(true)
    }
    const closePrintFileModal = () => {
      setPrintFileModalShow(false)
    }
    const handleRequestForScan=()=>{
        setDefaultBatch('')
        setOpenScanRequestModal(true)
    }
    const startCardDetection= () => {
      setOpenCardDetection(true)
    }
    const closeCardDetection = () => {
      setOpenCardDetection(false)
    }
    const handleCloseScanRequestModal=()=>{
        if(defaultBatch && defaultBatch !='')  handleOpenGallery()
        setOpenScanRequestModal(false)
    }
    const handleToggleUserSideBar=()=>{
        SetOpenUserSideBar(prev=>!prev)
    }
    const handleOpenGallery=()=>{
      localStorage.setItem("reload_lock", 1)
      setShowGalleryModal(true)
    }
    const handleCloseGallery=()=>{
      setShowGalleryModal(false)
      localStorage.removeItem("reload_lock")
    }
    const toggleShareScreen = async () => {
      try{
        console.alert(1440, "toggleShareScreen, locationEngaged:", locationEngaged)
        await AdminService.sendMessage({to: locationEngaged, event: 'toggle-share-screen'})
      } catch (err) {
        console.error(1464, err)
        toast.error(err.message, {autoClose: 10000})
      }
    }
    const toggleBlur = async () => {
      if(localVideoTrack) {
        if(isNowBlur) {
          localVideoTrack.setEffect(undefined)
          setIsNowBlur(false)
        } else {
          localVideoTrack.setEffect(await createVirtualBackgroundEffect())
          setIsNowBlur(true)
        }
      }

    }
    const handleExitFullScreenEngaged=()=>{
      if(!locationData) return
      for (let index in locationData){
        if(locationData[index] && locationData[index].user && locationData[index].user.getDisplayName() === locationEngaged){
          handleCancelEngagedLocation(index)
          break;
        }
      }
    }
    return (
        <div className={classes.root}>
            {showLoading ? 
              <div>
                <div style={{
                  position: "fixed",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: "#000",
                  opacity: 0.75,
                  zIndex: 2000,
                }}></div>
                <div style={{
                  position: "absolute",
                  width: "50px",
                  height: "50px",
                  zIndex: 2001,
                  top: "50%",
                  left: "50%",
                  margin: "-25px 0 0 -25px",
                }}>
                  <ReactLoading type={"spin"} color={"#0085d2"} />
                </div>
              </div>
            : ""}
            {
              !connections.internet ?
                <Connection msg="It seems like there is no internet connection. Retrying…"/>
              : !connections.server ?
                <Connection msg="We are currently upgrading and updating our service. 
                  We apologize for any inconvenience this may cause. 
                  Please be patient while we apply these necessary changes, 
                  your system will be back up shortly."/>
              :
              <div className='main_body_area'>
                <div className='main_body_user_area' ref={refUserSideBar} style={{marginLeft:(openUserSideBar)?0:'-400px'}}>
                    <UserArea
                        changeUserStatus={changeUserStatus}
                        handleLocalCamera={handleLocalCamera}
                        handleLocalMic={handleLocalMic}
                        onLocalTracks={onLocalTracks}
                        userName={props.match.params.username}
                        userStatus={userStatus}
                        remoteUsers={receptionData}
                        mic={mic}
                        camera={camera}
                        joinState={joinState}
                        handleToggleUserSideBar={handleToggleUserSideBar}
                        localConnectionQuality={localConnectionQuality}
                        setMic={setMic}
                        setCamera={setCamera}
                    />
                    {/* <div>
                        <ButtonBase style={{padding:0, margin:'5px'}} >
                            <CloseOutlinedIcon style={{color:"black"}} onClick={handleToggleUserSideBar}/>
                        </ButtonBase>
                        
                    </div> */}
                </div>
                <div className='main_body_location_area'>
                    <div className={classes.LucasLogoDiv}>
                        <img className={classes.lucasLogoImg} src={LucasLogo} />
                    </div>
                    <LocationArea
                        handleClickLocationItem={handleClickLocationItem}
                        handleItemCall={handleItemCall}
                        handleLocationPlay={handleLocationPlay}
                        // handleLocationCall={handleLocationCall}
                        handleItemSleep={handleItemSleep}
                        playState={playState}
                        callState={callState}
                        glowState={glowState}
                        locationName={locationName}
                        remoteLocations={locationData}
                        allLocationData={allLocations}
                        localUserData={localUserData}
                        locationEngaged={locationEngaged}
                        handleOpenModalPayReq={handleOpenModalPayReq}
                        showPrintFileModal={showPrintFileModal}
                        handleRequestForScan={handleRequestForScan}
                        toggleLeftSideBar={openUserSideBar}
                        micro={mic}
                        camera={camera}
                    />
                    <ReceptionInfo/>
                    <LocationNameBox name={locationName} id={locationID}/>
                    <LocationCallTagMemo locations={locationData} callState={callState} locationID={locationID}  />
                    <ToolBoxElement 
                        handleOpenModalPayReq={handleOpenModalPayReq}
                        handleRequestForScan={handleRequestForScan}
                        startCardDetection={startCardDetection}
                        handleModalPrintFile={showPrintFileModal}
                        handleLocalCamera={handleLocalCamera}
                        handleLocalMic={handleLocalMic}
                        handleCall={handleLocationCall} 
                        handlePlay={handleLocationPlay} 
                        onLocalTracks={onLocalTracks}
                        handleToggleUserSideBar={handleToggleUserSideBar}
                        handleOpenGallery={handleOpenGallery}
                        toggleShareScreen={toggleShareScreen}
                        callState={callState} 
                        locationEngaged={locationEngaged} 
                        allLocations={allLocations} 
                        playState={playState} 
                        joinState={joinState}
                        micro={mic}
                        camera={camera}
                        openUserSideBar={openUserSideBar}
                        toggleBlur={toggleBlur}
                        locationVolume={locationVolume}
                        handleLogout={logout}
                        handleExitFullScreenEngaged={handleExitFullScreenEngaged}
                    />
                </div>
                <RequestPaymentModal
                    showModal={payReqModal}
                    handleClose={handleCloseModalPayReq}
                    locationUsername={locationEngaged}
                    allLocations={allLocations}
                />
                <ScanRequestModal 
                  allLocations={allLocations}
                  locationEngaged={locationEngaged}
                  open={openScanRequestModal}
                  handleClose={handleCloseScanRequestModal}
                />
                <PrintFileRequestModal
                  allLocations={allLocations}
                  locationEngaged={locationEngaged}
                  externalShowModal={printFileModalShow}
                  externalClose={closePrintFileModal}
                />
                <CardDetectionRequest
                  allLocations={allLocations}
                  locationEngaged={locationEngaged}
                  open={openCardDetection}
                  handleClose={closeCardDetection}
                />
                <GalleryModal showModal={showGalleryModal} handleClose={handleCloseGallery} defaultBatch={defaultBatch}/>
              </div>
            }
        </div >
    )
}

Doctor.prototype = {

}

export default withRouter(Doctor);
