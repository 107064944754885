import React, { useEffect, useState, useRef,useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import './style.css';
import { useSelector } from 'react-redux';
import ListLocations from './ListLocations';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import { ButtonBase ,Grid} from '@material-ui/core';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import VersionComponent from '../../Version'
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';

const useStyle = makeStyles(({palette,mode}) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    position:"relative",
    justifyContent:"center",
    backgroundColor:palette.background[mode],
    overflowX:'hidden'
  },
  locations:{
    width:"250px",
    height:"100vh",
    position:"absolute",
    top:0,
    backgroundColor: 'rgba(200,200,200,0.5)',
    overflowY:'auto',
    boxShadow:"0 0 25px #8c8c8c45;",
    transition: 'all 0.5s ease-out',
    '&:hover':{
      backgroundColor: '#eeeeee',
    },
    '&:hover $toggleBtn':{
      display:"block"
    }
  },
  toggleBtn:{
    color:"black",
    position:"fixed",
    display:"none",
    zIndex:"2",
    borderRadius:"5px 0px 0px 5px",
    marginTop:"48vh",
    backgroundColor:"#eeeeee",
    // transition: 'all 0.9s ease-out',
    marginLeft:"-35px",
    '&:focus':{
      outline:"none"
    }
  },
  list:{
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column'
  },
  icon:{
    borderRadius:'5px',
    backgroundColor:"white",
    fontSize:"7rem",
    opacity:"0.5",
    margin:"5px",
    border:"1px solid"
  },
}))

const LocationArea = React.memo((props) => {
  const {
    callState,
    remoteLocations,
    allLocationData,
    localUserData,
    handleItemSleep,
    locationEngaged,
    toggleLeftSideBar,
    micro,
    camera
  } = props;
  const classes = useStyle();
  const [locationsBar, setLocationsBar] = useState(true);
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const [sizeLocationItem, setSizethLocationItem] = useState(["230px", "130px"]); // width, height
  const refLocationArea = useRef();
  const ListLocationsElement = useMemo(
    () => ListLocations,
    [
      sizeLocationItem[0],
      sizeLocationItem[1],
      remoteLocations,
      allLocationData,
      localUserData,
      locationEngaged,
      showSecondaryLocations, 
    ]
  );
// calculate width and height of location item. resize screen
  useEffect(() => {    
    window.addEventListener('resize', setDimension);
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [sizeLocationItem,refLocationArea])
  // calculate width and height of location item.
  useEffect(()=>{
    setDimension()
  },[allLocationData,locationEngaged])
    // calculate width and height of location item after completely open/close sidebar.

    useEffect(()=>{
      const timeout=setTimeout(()=>setDimension(),600)
      return()=>{
        clearTimeout(timeout)
      }
    },[toggleLeftSideBar])

  useEffect(() => {
    if (locationEngaged) {
      setLocationsBar(false)
    } 
  }, [locationEngaged])

  const toggleLocationsBar=()=>{
    setLocationsBar((prevState)=>!prevState)
  }
  const setDimension=()=>{
    var length=allLocationData?.length
    if(length==0) return
    const windowSize=      [
      refLocationArea?.current?.clientWidth,
      refLocationArea?.current?.clientHeight,
    ]
    let sqrt=Math.sqrt(length)
    let round=Math.round(sqrt)
    var width=windowSize[0]-50
    var height=windowSize[1]-50
    if(round<sqrt) round++;
    if(length>3){
      width=width/round
      if(length> round*(round-1))
        height=height/round
      else height=height/(round-1)
    }
    else {
      width=width/length
    }
    setSizethLocationItem([
      width,height
    ])
  }
  const ShowDevicesMuted=()=>{
    var content=[]
    if(callState && locationEngaged && !micro){
      content.push(<MicOffOutlinedIcon className={classes.icon} />)
    }
    if(callState && locationEngaged && !camera) {
      content.push(<VideocamOffOutlinedIcon className={classes.icon} />)
    }
    return(
      <div className='my-auto py-auto text-center' style={{position:"absolute"}}>
        {content}
    </div>
    )
  }

  return (
    <div className={classes.root}>
      <video className='location_video' autoPlay='1' id='locationVideo' playsInline style={{display:(locationEngaged)?'flex':"none"}} />
      <audio autoPlay='1' id='locationAudio' />
      <ShowDevicesMuted/>
      {(locationEngaged)?
        <div className={classes.locations} style={{ right: (locationsBar)? '0' : '-250px' }} >
          <ButtonBase className={classes.toggleBtn} style={{display:(locationsBar)?"":"block"}} 
            onClick={toggleLocationsBar}>
              {
                locationsBar?
                <KeyboardArrowRightOutlinedIcon fontSize='large'/>
                :<KeyboardArrowLeftOutlinedIcon fontSize='large'/>
              }
          </ButtonBase>
          <div className={classes.list}>
            <ListLocationsElement
              handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
              handleItemCall={() => props.handleItemCall()}
              remoteLocations={remoteLocations}
              allLocationData={allLocationData}
              localUserData={localUserData}
              handleItemSleep={handleItemSleep}
              width='230px'
              height="130px"
            />
          </div>
        </div>
        :
        <div style={{width:"95%",height:"80%"}} ref={refLocationArea}>
          <Grid   container spacing={1} direction='row' justifyContent="center" alignItems="center">
            <ListLocationsElement
                handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
                handleItemCall={() => props.handleItemCall()}
                remoteLocations={remoteLocations}
                allLocationData={allLocationData}
                localUserData={localUserData}
                handleItemSleep={handleItemSleep}
                width={sizeLocationItem[0]}
                height={sizeLocationItem[1]}
              />
          </Grid>
        </div>
      }
        <VersionComponent/>
    </div>
  )
});

export default LocationArea;
