import React, {forwardRef, useImperativeHandle, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const Confirm = forwardRef((props, ref) => {
  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [buttons, setButtons] = useState([]);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    show(inputTitle, inputMsg, inputButtons) {
      setTitle(inputTitle)
      setMsg(inputMsg)
      setOpen(true)
      let buttons = [];
      const done = new Promise((resolve, reject) => {
        for(let inputButton of inputButtons) {
          buttons.push(
            <Button onClick={() => {
              resolve(inputButton.value);
              if(inputButton.close) {
                setOpen(false)
              }
            }} color={inputButton.color}>
              {inputButton.text}
            </Button>
          )
        }
      })
      setButtons(buttons)
      return done;
    },
    hide() {
      setOpen(false)
    }
  }));

  const handleClose = (reason) => {
    if(reason === "backdropClick") {
      return;
    }

    setOpen(false);
  };
  
  return (
    <div>
      <Dialog open={open}  maxWidth="sm" fullWidth={true} >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{msg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons.map((button) => button)}
        </DialogActions>
      </Dialog>
    </div>
  );
})