import { createSlice } from '@reduxjs/toolkit';

export const micSlice = createSlice({
	name: 'mics',
	initialState: [],
	reducers: {
		addMic: (state, action) => {
			const mic = {
				id: state.length,
				deviceId: action.payload.deviceId,
				label: action.payload.label,
				selected: action.payload.selected,
			};
			state.push(mic);
		},
		selectMic: (state, action) => {
			const selectedIndex = state.findIndex((mic) => mic.id === action.payload.index);

			state.forEach((element, index) => {
				state[index].selected = false;
			});
			state[selectedIndex].selected = true;
		},
		deleteMic: (state, action) => {
			const updated = state.filter((mic) => mic.label != action.payload.label);

			const ret = updated.map((mic, index) => {
				let item = {
					id: index,
					deviceId: mic.deviceId,
					label: mic.label,
					selected: mic.selected,
				}
				return item;
			});
			return ret;
		},
		deleteMics: () => {
			return [];
		},
	},
});

export const { addMic, selectMic, deleteMic, deleteMics } = micSlice.actions;

export default micSlice.reducer;
