import { createSlice } from '@reduxjs/toolkit';


export const Locations=createSlice({
    name:"locations",
    initialState:[],
    reducers:{
        setLocations:(state, action)=>{
            state=action.payload
            return state
        }
    }
})

export const { setLocations} = Locations.actions

export default Locations.reducer