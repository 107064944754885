import axios from 'axios';
import authHeader from './authHeader';
import redux from '../redux/store';
import {setCommitHash} from '../redux/apiCommitHash';
import {setConnectionInternet, setConnectionServer} from '../redux/connections';
import storages from '../constants/storages';
const API_URL = process.env.REACT_APP_API_URL;
let disconnectTimeout = null;

class AdminService {
  //update db when User Leave
  userLeave(data) {
    return callApi('desktop/userLeave', data, { headers: authHeader() });
  }
  //Login
  login(data) {
    return callApi('auth/login', data, {});
  }
  //Logout
  logout(data) {
    return callApi('auth/logout', data, { headers: authHeader() });
  }
  //get information of all receptionlist by user authification
  authenticate(data) {
    return callApi('auth/authenticate', data, {});
  }
  //forgotPassword
  forgotPassword(data) {
    return callApi('auth/forgotPassword', data, {});
  }
  //updatePassword
  updatePassword(data) {
    return callApi('auth/updatePassword', data, {headers: authHeader()});
  }
  //requireCode
  requireCode(data) {
    return callApi('auth/requireCode', data, {});
  }
  //verifyCode
  verifyCode(data) {
    return callApi('auth/verifyCode', data, {});
  }
  //get type info from username
  getInfoFromUsername(data) {
    return callApi('desktop/getInfoFromUsername', data, {headers: authHeader() });
  }

  //update user status
  updateUserStatus(data) {
    return callApi('desktop/updateUserStatus', data, {headers: authHeader() });
  }

  //update own camera id
  updateSelfPartId(data) {
    return callApi('desktop/updateSelfPartId', data, {headers: authHeader() });
  }

  //update Call Tag
  updateCallTag(data) {
    return callApi('desktop/updateCallTag', data, {headers: authHeader() });
  }

  //end current call.
  currentCallEnd(data) {
    return callApi('desktop/currentCallEnd', data, {headers: authHeader() });
  }

  //join User And Location
  joinUserAndLocation(data) {
    return callApi('desktop/joinUserAndLocation', data, { headers: authHeader() });
  }
  
  //get all reception user list
  getAllReceptionList() {
    return callApi('desktop/getAllReceptionList', {}, { headers: authHeader() });
  }

  //insert log with calltag on location
  insertLocationLog(data) {
    return callApi('desktop/locationLog', data, {headers: authHeader() });
  }

  //update log for emoji on location
  updateLocationLog(data) {
    return axios.put(API_URL + 'desktop/locationLog', data, {headers: authHeader() });
  }

  //add log for status like call on receptionist
  insertReceptionLog(data) {
    return callApi('desktop/receptionLog', data, {headers: authHeader() });
  }

  getAllLocations() {
    return callApi('auth/allLocations');
  }

  getUserAndClientInfo(userID) {
    return callApi(`desktop/getUserAndClientInfo?userID=${userID}`,{}, {headers: authHeader() });
  }
  updateLastActivity(username) {
    return callApi(`desktop/updateLastActivity`,{username:username},{headers: authHeader()});
  }
  ping(username) {
    return callApi(`desktop/ping`,{username},{headers: authHeader()});
  }
  createPaymentRequest(data) {
    return callApi(`desktop/payment`, data, {headers: authHeader()});
  }
  createPaymentApi(data) {
    return callApi(`desktop/payment/api/sale`, data, {headers: authHeader()});
  }
  updatePayment(trID, data) {
    return callApi(`desktop/payment/update/${trID}`, data, {headers: authHeader()});
  }
  printReceipt(trID) {
    return callApi(`desktop/payment/print-receipt/${trID}`, {}, {headers: authHeader()});
  }
  checkStatus(data) {
    return callApi(`desktop/checkStatus`,data,{headers: authHeader()});
  }
  sendMessage(data ,onUploadProgress) {
    var options={ headers: authHeader() }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/sendMessage`, data, options);
  }
  getFiles(data ,onUploadProgress) {
    var options={ headers: authHeader() }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/getFiles`, data, options);
  }
  removeFiles(data ,onUploadProgress) {
    var options={ headers: authHeader() }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/removeFiles`, data, options);
  }
  uploadScanResult(data, onUploadProgress) {
    var formData = new FormData();
    if(data.file) {
      formData.append("file", new Blob([data.file], {type: "image/jpg"}))
    }
    for(let i in data) {
      if(i !== "file") {
        formData.append(i, data[i])
      }
    }
    var options = { headers: {...authHeader(), "Content-Type": "multipart/form-data"} }
    if(onUploadProgress) options.onUploadProgress=onUploadProgress
    return callApi(`desktop/upload-scan-result`, formData, options);
  }
  getBatchesList(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/batches`, data, options);
  }
  galleryList(data) {
    var options={ headers: authHeader() }
    return callApi(`desktop/gallery`, data, options);
  }
  galleryRemove(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/gallery/remove`, data, options);
  }
  checkSharedFiles(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/shared-files/check`, data, options);
  }

  getMessages(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/messages`, data, options);
  }
  readMessage(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/messages/read`, data, options);
  }
  locationCallAdd(data){
    var options={ headers: authHeader() }
    return callApi(`desktop/location-call/add`, data, options);
  }
  checkHealth(){
    return callApi(`check-health`);
  }
  checkValidationUsername(data){
    var options={ headers: authHeader() }
    return callApi('desktop/reception/validation',data,options)
  }
  sendScanRequest(data) {
    var options={ headers: authHeader() }
    return callApi(`desktop/scan-request`, data, options);
  }
}
async function callApi (url, data, options){
  return new Promise (async (resolve,reject)=>{
    try{
      if(
        console.logLevel && (console.logLevel == "debug" || console.logLevel == "silly")
      ) {
        let sanitizedData, sanitizedOptions
        if(data) {
          sanitizedData = JSON.parse(JSON.stringify(data));
          delete sanitizedData.password;
          delete sanitizedData.msg?.file;
          delete sanitizedData.msg?.files;
        }
        if(options) {
          sanitizedOptions = JSON.parse(JSON.stringify(options));
          delete sanitizedOptions.headers?.Authorization;
        }
        console.debug(`callApi ${API_URL + url} ${JSON.stringify(sanitizedData)} ${JSON.stringify(sanitizedOptions)}`)
      }
      if(!data) {
        data = {}
      }
      const appVersion = localStorage.getItem("lucas_therapies_version")
      if(appVersion) {
        data.appVersion = appVersion;
      }
      var res = await axios.post(API_URL + url, data,options);
      if(res.status >= 200 && res.status < 300) {
        if(res.data?.code == 503) {
          if(!disconnectTimeout) {
            console.log("Disconnected with server reason")
            disconnectTimeout = setTimeout(() => {
              disconnected("server")
            }, 11 * 1000);
          }
        }else{
          clearTimeout(disconnectTimeout);
          disconnectTimeout=null
          redux.dispatch(setConnectionServer(true))
          redux.dispatch(setConnectionInternet(true))
        }
      } 
      if(options && options.headers && options.headers.Authorization){
        if(res && res.data && res.data.code === 401 && url!='auth/logout'){
          localStorage.removeItem(storages.LOCAL_TOKEN_USER)
          sessionStorage.removeItem(storages.LOCAL_TOKEN_USER)
          window.location.href='/login'
          console.debug("0000   Authorization failed. User will be logged out")
          return reject(new Error("Authorization failed. User will be logged out"))
        }
      }
      if(res && res.data && res.data._API && redux.getState().apiCommitHash!= res.data._API){
        redux.dispatch(setCommitHash(res.data._API))
      }
      return resolve(res)
    }catch(err){
      if(err.toJSON().message === 'Network Error') {
        if(window.navigator.onLine) {
          if(!disconnectTimeout) {
            console.log("Disconnected with server reason")
            disconnectTimeout = setTimeout(() => {
              disconnected("server")
            }, 11 * 1000);
          }
        } else {
          if(!disconnectTimeout) {
            console.log("Disconnected with internet reason")
            disconnectTimeout = setTimeout(() => {
              disconnected("internet")
            }, 11 * 1000);
          }
        }
      } else if (err.response?.status === 502 || err.response?.status === 503) {
        if(!disconnectTimeout) {
          console.log("Disconnected with server reason")
          disconnectTimeout = setTimeout(() => {
            disconnected("server")
          }, 11 * 1000);
        }
      }

      console.error(err)
      return reject(err)
    }
  })
}

function disconnected(reason) {
  console.alert("Disconnected fot 11 seconds with " + reason + " reason")
  if(reason === "server") {
    redux.dispatch(setConnectionServer(false))
  } else {
    redux.dispatch(setConnectionInternet(false))
  }
}

export default new AdminService();

